import UIkit from 'uikit'

$(function () {
  $('.copy-to-clipboard-button').on('click', function () {
    var $targetElement = $($(this).attr('data-target'))
    if ($targetElement.length === 0) return

    var text = $targetElement.val() || $targetElement.text()
    // 仮のtextareaを生成し、選択してコピーコマンドを実行する
    var clipboard = $('<textarea></textarea>')
    clipboard.text(text)
    $('body').append(clipboard)
    clipboard.select()
    document.execCommand('copy')
    clipboard.remove()
    UIkit.notification({
      message:
        "<span uk-icon='icon: check'></span> クリップボードにコピーしました",
      status: 'success',
    })
  })
})
