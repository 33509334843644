import React from 'react'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { updateTrigger } from '../../stores/cartAbandonmentTrigger'
import { RootState } from '../../stores'

const ButtonColorSelector = () => {
  const dispatch = useAppDispatch()
  const { trigger } = useAppSelector(
    (state: RootState) => state.cartAbandonmentTrigger
  )
  const buttonThemes = useAppSelector(
    (state: RootState) => state.flexMessageButtonTheme.list
  )

  if (!trigger) return <div />

  const handleSelect = (buttonColorCode: string) => {
    dispatch(
      updateTrigger({
        ...trigger,
        cartAbandonmentMessage: {
          ...trigger.cartAbandonmentMessage,
          buttonColorCode,
        },
      })
    )
  }

  const { buttonColorCode } = trigger.cartAbandonmentMessage
  return (
    <div className="uk-flex uk-flex-wrap">
      {buttonThemes.map(theme => {
        const { colorCode, style } = theme
        const isSelected = buttonColorCode === colorCode
        const textColorCode = {
          link: '42659a',
          primary: 'ffffff',
          secondary: '111111',
        }[style]
        return (
          <div
            key={colorCode}
            onClick={() => handleSelect(colorCode)}
            style={{
              color: `#${textColorCode}`,
              background: `#${colorCode}`,
              border: colorCode === 'ffffff' ? '1px solid #ddd' : 'none',
              width: 32,
              height: 32,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              cursor: 'pointer',
              marginLeft: 5,
              marginBottom: 5,
              boxSizing: 'border-box',
            }}
          >
            {isSelected ? (
              <span style={{ width: 16 }} uk-icon="icon: check" />
            ) : (
              'A'
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ButtonColorSelector
