import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import {
  PvRetargetingTrigger,
  PushMessageCardObject,
  Company,
} from '../../types'
import { useAppDispatch, useResponsiveWindowSize } from '../../hooks'
import { updateListTrigger } from '../../stores/pvRetargetingTrigger'
import CardPreview from './CardPrevirew'
import ColorSelector from './ColorSelector'
import SendingTestModal from './SendingTestModal'
import { ResponsiveBreakpoint } from 'components/Dashboard/const'
import LimitedStockForm from './LimitedStockForm'

interface Props {
  trigger: PvRetargetingTrigger
  index: number
  company: Company
}

const CardObjectForm = ({ trigger, index, company }: Props) => {
  const dispatch = useAppDispatch()
  const { pushMessageCardObject } = trigger
  const responsiveWindowSize = useResponsiveWindowSize()

  const setCardObject = (newCardObject: PushMessageCardObject) => {
    dispatch(
      updateListTrigger({ ...trigger, pushMessageCardObject: newCardObject })
    )
  }

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value
    setCardObject({ ...pushMessageCardObject, title })
  }

  const handleChangeCardType = (e: ChangeEvent<HTMLInputElement>) => {
    const cardType = e.target.value as 'item' | 'item_with_coupon'
    setCardObject({ ...pushMessageCardObject, cardType })
  }

  const handleChangeCouponCode = (e: ChangeEvent<HTMLInputElement>) => {
    const mainText = e.target.value
    setCardObject({ ...pushMessageCardObject, mainText })
  }

  const handleChangeCouponLabel = (e: ChangeEvent<HTMLInputElement>) => {
    const imageLabel = e.target.value
    setCardObject({ ...pushMessageCardObject, imageLabel })
  }

  const handleChangeButtonLabel = (e: ChangeEvent<HTMLSelectElement>) => {
    const buttonLabel = e.target.value
    setCardObject({ ...pushMessageCardObject, buttonLabel })
  }

  return (
    <div
      id={`form-root-${trigger.id}`}
      className="uk-margin-large-bottom"
      style={
        index === 0 ? {} : { paddingTop: 32, borderTop: '1px solid #e5e5e5' }
      }
    >
      <div
        className="uk-label uk-margin-bottom"
        style={{ padding: '3px 10px' }}
      >
        {trigger.waitMinutes / (24 * 60)}日後メッセージ
      </div>
      {trigger.status === 'active' ? (
        <Container className="uk-flex">
          <PreviewWrapper>
            {responsiveWindowSize === 'small' && (
              <div className="uk-text-small uk-margin-small-bottom">
                メッセージプレビュー
              </div>
            )}
            <CardPreview trigger={trigger} />
            {responsiveWindowSize === 'small' && (
              <div className="uk-margin-top">
                <SendingTestModal
                  rootId={`form-root-${trigger.id}`}
                  trigger={trigger}
                  company={company}
                />
              </div>
            )}
          </PreviewWrapper>
          <div className="uk-flex-1">
            <FormRow className="uk-flex uk-margin-bottom">
              <FormLabel className="uk-width-1-6">ラベル</FormLabel>
              <div>
                <input
                  type="text"
                  value={pushMessageCardObject.title || ''}
                  onChange={handleChangeTitle}
                  className="uk-input"
                  placeholder="ラベルを入力"
                />
                <div className="uk-margin-small-top">
                  <ColorSelector trigger={trigger} name="title" />
                </div>
                <div className="uk-margin-small-top">
                  <LimitedStockForm trigger={trigger} />
                </div>
              </div>
            </FormRow>
            <FormRow className="uk-flex  uk-margin-bottom">
              <FormLabel className="uk-width-1-6">テキスト</FormLabel>
              <div className="uk-width-1-1">
                <TextRadioLabel className="uk-flex uk-flex-middle">
                  <input
                    type="radio"
                    value="item"
                    name={`card_type--${pushMessageCardObject.id}`}
                    checked={pushMessageCardObject.cardType === 'item'}
                    onChange={handleChangeCardType}
                    className="uk-radio uk-margin-small-right"
                  />
                  <div>商品名</div>
                </TextRadioLabel>
                <TextRadioLabel className="uk-flex uk-flex-between uk-flex-middle">
                  <div className="uk-flex uk-flex-middle">
                    <input
                      type="radio"
                      value="item_with_coupon"
                      name={`card_type--${pushMessageCardObject.id}`}
                      checked={
                        pushMessageCardObject.cardType === 'item_with_coupon'
                      }
                      onChange={handleChangeCardType}
                      className="uk-radio uk-margin-small-right"
                    />
                    <div>クーポン情報</div>
                  </div>
                  {pushMessageCardObject.cardType === 'item_with_coupon' && (
                    <a
                      href={`/usage_guide_redirects/pv_retargeting_coupon?shop_account_type=${company.shopAccountType}`}
                      target="_blank"
                      className="uk-text-xsmall"
                    >
                      クーポンに関する注意点
                      <div
                        className="material-icons"
                        style={{
                          fontSize: 16,
                          verticalAlign: 'text-bottom',
                          marginLeft: 2,
                        }}
                      >
                        open_in_new
                      </div>
                    </a>
                  )}
                </TextRadioLabel>
                {pushMessageCardObject.cardType === 'item_with_coupon' && (
                  <CouponDetail>
                    <div className="uk-flex uk-margin-small-bottom">
                      <CouponDetailLabel>クーポンコード</CouponDetailLabel>
                      <input
                        type="text"
                        value={pushMessageCardObject.mainText || ''}
                        onChange={handleChangeCouponCode}
                        className="uk-input uk-flex-1"
                      />
                    </div>
                    <div className="uk-flex">
                      <CouponDetailLabel>説明ラベル</CouponDetailLabel>
                      <div className="uk-flex-1">
                        <input
                          type="text"
                          value={pushMessageCardObject.imageLabel || ''}
                          onChange={handleChangeCouponLabel}
                          className="uk-input"
                        />
                        <div className="uk-text-xsmall uk-text-muted">
                          例: ￥1,000クーポン、5%OFF、など
                        </div>
                      </div>
                    </div>
                    <div className="uk-margin-small-top">
                      <ColorSelector trigger={trigger} name="imageLabel" />
                    </div>
                  </CouponDetail>
                )}
              </div>
            </FormRow>
            <FormRow className="uk-flex uk-margin-bottom">
              <FormLabel className="uk-width-1-6">ボタン</FormLabel>
              <div>
                <select
                  className="uk-select"
                  value={pushMessageCardObject.buttonLabel || '詳細を見る'}
                  onChange={handleChangeButtonLabel}
                >
                  {[
                    '詳細を見る',
                    '商品を見に行く',
                    '商品の詳細',
                    '商品をチェック',
                    'CHECK',
                    '今すぐ購入',
                    '購入はこちら',
                  ].map(text => (
                    <option key={text} value={text}>
                      {text}
                    </option>
                  ))}
                </select>
                <div className="uk-margin-small-top">
                  <ColorSelector trigger={trigger} name="button" />
                </div>
              </div>
            </FormRow>
            {trigger.errorMessages.length > 0 && (
              <div className="uk-alert-danger uk-text-small uk-margin-medium-top uk-padding-small">
                {trigger.errorMessages.map(message => (
                  <div key={message}>・{message}</div>
                ))}
              </div>
            )}
            {responsiveWindowSize !== 'small' && (
              <div className="uk-margin-medium-top">
                <SendingTestModal
                  rootId={`form-root-${trigger.id}`}
                  trigger={trigger}
                  company={company}
                />
              </div>
            )}
          </div>
        </Container>
      ) : (
        <div className="uk-margin">メッセージは配信されません</div>
      )}
    </div>
  )
}

export default CardObjectForm

const Container = styled.div`
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    flex-direction: column-reverse;
  }
`
const PreviewWrapper = styled.div`
  flex: none;
  margin-right: 40px;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    margin-right: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const FormRow = styled.div`
  @media screen and (max-width: 1099px) {
    flex-direction: column;
  }
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    flex-direction: row;
  }
`
const FormLabel = styled.div`
  width: 90px;
  flex: none;
  line-height: 40px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    width: 76px;
  }
`
const TextRadioLabel = styled.label`
  line-height: 40px;
  cursor: pointer;
`
const CouponDetail = styled.div`
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    padding-left: 0;
  }
`
const CouponDetailLabel = styled.div`
  line-height: 40px;
  width: 120px;
  flex: none;
  font-size: 14px;
`
