import React, { useCallback, useEffect, useState } from 'react'
import { useModal } from 'react-hooks-use-modal'
import styled from 'styled-components'
import {
  IndexTable,
  LegacyCard,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  EmptySearchResult,
  Thumbnail,
} from '@shopify/polaris'

import type { IndexFiltersProps } from '@shopify/polaris'
import { Company, Variant } from 'components/Dashboard/types'
import axios from 'axios'
import { ShopItemDefaultImage } from 'components/Dashboard/const'
import SettingsSwitcher from './SettingsSwitcher'

type Props = {
  rootId: string
  company: Company
}

const VariantListModal = ({ rootId, company }: Props) => {
  const [initialized, setInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [variants, setVariants] = useState<Array<Variant>>([])
  const [Modal, openModal] = useModal(rootId, {
    closeOnOverlayClick: true,
  })

  const sortOptions: IndexFiltersProps['sortOptions'] = [
    { label: '商品名', value: 'FULL_TITLE asc', directionLabel: 'A-Z' },
    { label: '商品名', value: 'FULL_TITLE desc', directionLabel: 'Z-A' },
    { label: 'SKU', value: 'SKU asc', directionLabel: '昇順' },
    { label: 'SKU', value: 'SKU desc', directionLabel: '降順' },
    {
      label: '在庫数',
      value: 'INVENTORY_QUANTITY asc',
      directionLabel: '低い順',
    },
    {
      label: '在庫数',
      value: 'INVENTORY_QUANTITY desc',
      directionLabel: '高い順',
    },
  ]
  const [sortSelected, setSortSelected] = useState(['FULL_TITLE asc'])

  type FetchVariantsParams = {
    sortKey?: string
    sortDirection?: string
    query?: string
  }

  const fetchVariants: (params: FetchVariantsParams) => void = async params => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        `/api/companies/${company.slug}/variants`,
        {
          params: {
            order: params.sortKey,
            order_direction: params.sortDirection,
            query: params.query,
          },
        }
      )
      setVariants(response.data.variants)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const parseSortKey = (sortKey: string) => {
    const [key, direction] = sortKey.split(' ')
    return {
      key,
      direction,
    }
  }

  useEffect(() => {
    if (!initialized) {
      const sortKeyObj = parseSortKey(sortSelected[0])
      fetchVariants({
        sortKey: sortKeyObj.key,
        sortDirection: sortKeyObj.direction,
      })
      setInitialized(true)
    }
  }, [initialized, variants])

  const resourceName = {
    singular: 'variant',
    plural: 'variants',
  }

  const sortSelectedHandler = (value: string[]) => {
    const sortKeyObj = parseSortKey(value[0])
    fetchVariants({
      sortKey: sortKeyObj.key,
      sortDirection: sortKeyObj.direction,
    })
    setSortSelected(value)
  }
  const { mode, setMode } = useSetIndexFiltersMode()
  const onHandleCancel = () => {
    handleFiltersQueryClear()
  }

  const [queryValue, setQueryValue] = useState('')

  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value)
  }, [])

  const handleFiltersQueryClear = useCallback(() => {
    setQueryValue('')
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchVariants({
        sortKey: parseSortKey(sortSelected[0]).key,
        sortDirection: parseSortKey(sortSelected[0]).direction,
        query: queryValue,
      })
    }, 500)

    return () => clearTimeout(timer)
  }, [queryValue])

  const handleQueryValueRemove = useCallback(() => setQueryValue(''), [])

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove()
  }, [handleQueryValueRemove])

  const rowMarkup = variants.map(
    ({ id, title, sku, inventoryQuantity, product }, index) => (
      <IndexTable.Row id={id.toString()} key={id} position={index}>
        <IndexTable.Cell>
          <Thumbnail
            source={product.image || ShopItemDefaultImage}
            alt={product.title}
            size="small"
          />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ItemTitle>
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {product.title}
            </Text>
            <Text as="span">{title}</Text>
          </ItemTitle>
        </IndexTable.Cell>
        <IndexTable.Cell>{sku || 'SKUなし'}</IndexTable.Cell>
        <IndexTable.Cell>{inventoryQuantity}</IndexTable.Cell>
        <IndexTable.Cell>
          <SettingsSwitcher
            variantId={id}
            company={company}
            type="subscription"
          />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <SettingsSwitcher
            variantId={id}
            company={company}
            type="notification"
          />
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  )

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'該当するバリエーションが見つかりませんでした'}
      description={'検索条件を変えて再度お試しください'}
      withIllustration
    />
  )

  return (
    <>
      <button
        onClick={openModal}
        className={'uk-button uk-button-default'}
        style={{
          cursor: 'pointer',
        }}
      >
        商品個別の設定を行う
      </button>
      <Modal>
        <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
          <div className="uk-h4">商品別設定</div>
          <LegacyCard>
            <>
              <IndexFilters
                sortOptions={sortOptions}
                sortSelected={sortSelected}
                queryValue={queryValue}
                queryPlaceholder="すべてのバリエーションを検索中"
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={handleFiltersQueryClear}
                onSort={sortSelectedHandler}
                cancelAction={{
                  onAction: onHandleCancel,
                  disabled: false,
                  loading: false,
                }}
                tabs={[]}
                selected={0}
                filters={[]}
                onClearAll={handleFiltersClearAll}
                mode={mode}
                setMode={setMode}
                hideFilters
              />
              <IndexTable
                resourceName={resourceName}
                itemCount={variants.length}
                emptyState={emptyStateMarkup}
                loading={isLoading || !initialized}
                selectable={false}
                headings={[
                  { title: '' },
                  { title: '商品' },
                  { title: 'SKU' },
                  { title: '在庫数' },
                  { title: '通知登録受付' },
                  { title: '通知送信' },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </>
          </LegacyCard>
        </ModalInner>
      </Modal>
    </>
  )
}

export default VariantListModal

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  box-sizing: border-box;
  height: 90vh;
  overflow-y: scroll;
`

const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
`
