import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import ReactLoading from 'react-loading'
import { useModal } from 'react-hooks-use-modal'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import BannerDescription from './ShopPopup/BannerDescription'
import LineDisplayTextArea from './ShopPopup/LineDisplayTextArea'
import DisplayTextArea from './ShopPopup/DisplayTextArea'

const ShopPopupForm = ({ company, shopAccount, shopPopup, themes }) => {
  const [displayText, setDisplayText] = useState('')
  const [themeId, setThemeId] = useState(1)
  const [sending, setSending] = useState(false)
  const [bannerStyle, setBannseStyle] = useState('line_icon_banner')
  const [lineBannerStyle, setLineBannerStyle] = useState('no_text')
  const [lineDisplayText, setLineDisplayText] = useState('')

  const [Modal, openModal, closeModal] = useModal('form-root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  useEffect(() => {
    setDisplayText(shopPopup.displayText)
    setThemeId(Number(shopPopup.popupThemeId))
    setLineDisplayText(shopPopup.lineDisplayText || '')
    setBannseStyle(shopPopup.bannerStyle)
    if (shopPopup.lineDisplayText) {
      setLineBannerStyle('with_text')
    }
  }, [])

  const bannerStyles =
    company.shopAccountType === 'shopify'
      ? ['line_icon_banner', 'rich_design_banner']
      : ['line_icon_banner', 'rich_design_banner', 'no_banner']

  const isValidLineIconDisplayText =
    lineDisplayText !== '' && lineDisplayText.length <= 32

  const isValid =
    bannerStyle === 'no_banner' ||
    (bannerStyle === 'line_icon_banner' && lineBannerStyle === 'no_text') ||
    (bannerStyle === 'rich_design_banner' && displayText !== '') ||
    (lineBannerStyle === 'with_text' && isValidLineIconDisplayText)

  const handleClickSubmitButton = () => {
    if (!isValid) return

    setSending(true)
    openModal()
    const url = `/api/companies/${company.slug}/shop_popups`
    let params = { banner_style: bannerStyle }
    if (bannerStyle === 'rich_design_banner') {
      params.display_text = displayText
      params.popup_theme_id = themeId
    } else if (bannerStyle === 'line_icon_banner') {
      if (lineBannerStyle === 'no_text') {
        params.line_display_text = ''
      } else {
        params.line_display_text = lineDisplayText
      }
    }
    const data = {
      shop_popup: params,
      authenticity_token: $('#authenticity_token').val(),
    }
    axios.put(url, data).then(response => {
      setSending(false)
      if (response.data.push_event)
        window.dataLayer.push({ event: 'user_popup_msg_registered' })
    })
  }

  const SubmitModal = (
    <Modal>
      <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
        {sending ? (
          <>
            <h4 className="uk-text-center">友だち追加バナーを登録中です...</h4>
            <div className="uk-section">
              <ReactLoading
                className="uk-margin-auto"
                type={'spin'}
                color={'#00888d'}
                height={100}
                width={100}
              />
            </div>
          </>
        ) : (
          <div className="uk-text-center">
            <h4>友だち追加バナーの設定を保存しました</h4>
            <div>
              {company.shopAccountType === 'shopify' ? (
                <>
                  <p className="uk-text-small">
                    ショップで表示するにはShopifyの管理画面からテーマを編集する必要があります。
                    <br />
                    詳しくは
                    <a
                      className="text-underline"
                      href={`/usage_guide_redirects/how_to_set_popup?shop_account_type=${company.shopAccountType}`}
                      target="_blank"
                      rel="noopener"
                    >
                      友だち追加バナーの設定方法
                      <OpenInNewIcon
                        style={{
                          fontSize: 16,
                          verticalAlign: 'text-bottom',
                          marginLeft: 4,
                        }}
                      />
                    </a>
                    をご確認ください。
                  </p>
                  <p className="uk-text-small">
                    テーマ設定がお済みの方は、以下より自分のショップの表示を確認してみましょう。
                    <br />※ 反映まで時間がかかることがあります
                  </p>
                  <a
                    className="uk-button uk-button-primary uk-margin-bottom"
                    href={`${shopAccount.shopUrl}?oc_popup=1`}
                    target="_blank"
                    rel="noopener"
                  >
                    ショップページを開く
                    <OpenInNewIcon
                      style={{
                        fontSize: 16,
                        verticalAlign: 'text-bottom',
                        marginLeft: 4,
                      }}
                    />
                  </a>
                </>
              ) : bannerStyle === 'rich_design_banner' &&
                shopAccount.tagInstalled ? (
                <>
                  <p className="uk-text-small">
                    自分のショップで表示を確認してみましょう
                    <br />※ 反映まで時間がかかることがあります
                  </p>
                  <a
                    className="uk-button uk-button-primary uk-margin-bottom"
                    href={`${shopAccount.shopUrl}?oc_popup=1`}
                    target="_blank"
                    rel="noopener"
                  >
                    ショップページを開く
                    <OpenInNewIcon
                      style={{
                        fontSize: 16,
                        verticalAlign: 'text-bottom',
                        marginLeft: 4,
                      }}
                    />
                  </a>
                  <br />
                </>
              ) : (
                <a
                  className="uk-button uk-button-default uk-margin-bottom"
                  href={`/companies/${company.slug}/dashboard`}
                >
                  ダッシュボードトップへ
                </a>
              )}
              <div
                className="uk-text-small uk-text-primary"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={closeModal}
              >
                閉じる
              </div>
            </div>
          </div>
        )}
      </ModalInner>
    </Modal>
  )

  return (
    <div id="form-root">
      <div className="uk-margin-small-bottom">
        <label className="uk-form-label uk-text-bold">表示形式</label>
      </div>
      <div className="uk-text-small uk-margin-medium-bottom">
        <div className="uk-child-width-expand@s uk-text-center uk-grid uk-margin-bottom">
          {bannerStyles.map(key => (
            <div key={key}>
              <BannerStyleButton
                active={key === bannerStyle}
                className="uk-card uk-card-default uk-card-body uk-margin-small-bottom"
                onClick={() => setBannseStyle(key)}
              >
                {
                  {
                    line_icon_banner: 'LINEアイコンバナー',
                    rich_design_banner: 'リッチデザインバナー',
                    no_banner: 'バナーを表示しない',
                  }[key]
                }
              </BannerStyleButton>
            </div>
          ))}
        </div>
        <BannerDescription
          bannerStyle={bannerStyle}
          shopAccountType={company.shopAccountType}
        />
        {company.shopAccountType !== 'stores' && (
          <div className="uk-margin-xsmall-top">
            <a
              className="text-underline"
              href={`/usage_guide_redirects/how_to_adjust_line_popup?shop_account_type=${company.shopAccountType}`}
              target="_blank"
              rel="noopener"
            >
              バナーの表示位置を調整するには？
              <OpenInNewIcon
                style={{
                  fontSize: 16,
                  verticalAlign: 'text-bottom',
                  marginLeft: 4,
                }}
              />
            </a>
          </div>
        )}
      </div>
      {bannerStyle === 'line_icon_banner' && (
        <>
          <div className="uk-margin">
            <div className="uk-margin-small-bottom">
              <label
                className="uk-form-label uk-text-bold"
                htmlFor="shop_popup_display_text"
              >
                表示パーツ
                <span className="required-icon">必須</span>
              </label>
            </div>
            <label>
              <div
                className="uk-flex uk-flex-middle"
                style={{ cursor: 'pointer' }}
              >
                <input
                  type="radio"
                  value="no_text"
                  name="shop_popup[line_banner_style]"
                  checked={lineBannerStyle === 'no_text'}
                  onChange={e => setLineBannerStyle(e.target.value)}
                  className="uk-radio"
                  style={{ margin: '0 6px 0 0' }}
                />
                <div>LINEアイコンのみ</div>
              </div>
            </label>
            <label>
              <div
                className="uk-flex uk-flex-middle uk-margin-small-top"
                style={{ cursor: 'pointer' }}
              >
                <input
                  type="radio"
                  value="with_text"
                  name="shop_popup[line_banner_style]"
                  checked={lineBannerStyle === 'with_text'}
                  onChange={e => setLineBannerStyle(e.target.value)}
                  className="uk-radio"
                  style={{ margin: '0 6px 0 0' }}
                />
                <div>LINEアイコン + テキスト</div>
              </div>
            </label>
          </div>
        </>
      )}
      {bannerStyle === 'line_icon_banner' &&
        lineBannerStyle === 'with_text' && (
          <LineDisplayTextArea
            value={lineDisplayText}
            onChange={setLineDisplayText}
          />
        )}
      {bannerStyle === 'rich_design_banner' && (
        <DisplayTextArea value={displayText} onChange={setDisplayText} />
      )}
      {bannerStyle === 'rich_design_banner' && (
        <div className="uk-margin">
          <div className="uk-form-label uk-text-bold uk-margin-bottom">
            カラーテーマ
            <span className="required-icon">必須</span>
          </div>
          <div className="uk-grid uk-grid-small">
            {themes.map(theme => {
              const { id, title, mainColor, subColor } = theme
              const selected = Number(id) === themeId
              return (
                <ThemeSelectWrapper className="uk-width-1-2" key={id}>
                  <label>
                    <ThemeSelect>
                      <ThemeSelectBox
                        className="uk-card uk-card-default"
                        selected={selected}
                      >
                        <div className="uk-flex uk-flex-center">
                          <ThemeSelectColor
                            bgColor={mainColor}
                          ></ThemeSelectColor>
                          <ThemeSelectColor
                            bgColor={subColor}
                          ></ThemeSelectColor>
                        </div>
                      </ThemeSelectBox>
                      <ThemeSelectText className="uk-flex uk-flex-middle uk-flex-between uk-text-small uk-text-secondary">
                        <div
                          className="uk-flex uk-flex-middle"
                          style={{ cursor: 'pointer' }}
                        >
                          <input
                            type="radio"
                            value={id}
                            name="shop_popup[popup_theme_id]"
                            checked={selected}
                            onChange={e => setThemeId(Number(e.target.value))}
                            className="uk-radio"
                            style={{ margin: '0 5px 0 0' }}
                          />
                          <div>{title}</div>
                        </div>
                        {selected && (
                          <ThemeSelectedIcon>選択中</ThemeSelectedIcon>
                        )}
                      </ThemeSelectText>
                    </ThemeSelect>
                  </label>
                </ThemeSelectWrapper>
              )
            })}
          </div>
        </div>
      )}
      <div className="uk-margin-top uk-flex uk-flex-center uk-flex-column uk-flex-middle">
        <input
          type="submit"
          value="保存する"
          className="uk-button uk-button-primary uk-width-small"
          data-disable-with="保存する"
          disabled={!isValid}
          onClick={handleClickSubmitButton}
        />
      </div>
      {SubmitModal}
    </div>
  )
}

export default ShopPopupForm

const ThemeSelectWrapper = styled.div`
  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`
const ThemeSelect = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const ThemeSelectBox = styled.div`
  outline: ${props =>
    props.selected ? 'solid 2px rgba(0, 136, 141, .8)' : 'none'};
  cursor: pointer;
  display: inline-block;
  width: 108px;
  padding: 12px 0;

  /* @media screen and (max-width: 600px) {
    width: 90%;
  } */
`
const ThemeSelectColor = styled.div`
  background-color: #${props => props.bgColor};
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 0 4px;

  /* @media screen and (max-width: 600px) {
    margin: 0 6px;
  } */
`
const ThemeSelectText = styled.div`
  padding: 15px;

  @media screen and (max-width: 600px) {
    padding: 15px 0;
  }
`
const ThemeSelectedIcon = styled.div`
  font-size: 12px;
  background-color: #00888d;
  color: #fff;
  padding: 1px 8px;
  border-radius: 2px;
  opacity: 0.8;
  margin-left: 10px;

  @media screen and (max-width: 600px) {
    font-size: 10px;
    padding: 1px 4px;
  }
`
const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`

const BannerStyleButton = styled.div`
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ active }) => (active ? '#fff' : '#222')};
  background-color: ${({ active }) => (active ? '#00888d' : '#fff')};
`
