import React, { useState } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import { useForm, SubmitHandler } from 'react-hook-form'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { useAppDispatch } from 'components/Dashboard/hooks'
import { createAddLineFriendTracking } from 'components/Dashboard/stores/addLineFriendTrackings'
import { Company } from 'components/Dashboard/types'
import ReactLoading from 'react-loading'

interface Props {
  rootId: string
  company: Company
  createAddLineFriendTrackingCallback: () => void
  existingParameters: string[]
  existingTitles: string[]
}

type Inputs = {
  title: string
  parameter: string | null
}

const AddLineFriendTrackingFormModal = ({
  rootId,
  company,
  createAddLineFriendTrackingCallback,
  existingParameters,
  existingTitles,
}: Props) => {
  const dispatch = useAppDispatch()
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [isParameterInputOpen, setIsParameterInputOpen] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [Modal, openModal, closeModal] = useModal(rootId, {
    closeOnOverlayClick: true,
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setIsCreating(true)
    try {
      await dispatch(createAddLineFriendTracking(company, data))
      createAddLineFriendTrackingCallback()
      closeModal()
      reset()
    } catch (e) {
      console.error(e)
      window.alert(
        '作成に失敗しました。ページを再読み込みしていただくか、時間をあけてから再度お試しください。'
      )
    } finally {
      setIsCreating(false)
    }
  }

  const parameterUniqueValidator = (value: string | null): string | true => {
    if (!value) {
      return true
    }
    if (value.length > 10) {
      return '10文字以内で入力してください'
    }
    if (!/^[a-zA-Z0-9_-]*$/.test(value)) {
      return '英数字とアンダースコア(_)、ハイフン(-)のみ使用できます'
    }

    if (existingParameters.includes(value)) {
      return 'このパラメーターはすでに使われています'
    }
    return true
  }

  const titleUniqueValidator = (value: string): string | true => {
    if (value.length > 40) {
      return '40文字以内で入力してください'
    }
    if (existingTitles.includes(value)) {
      return 'この経路名はすでに使われています'
    }
    return true
  }

  return (
    <>
      <button className="uk-button uk-button-primary" onClick={openModal}>
        経路を追加する
      </button>
      <Modal>
        <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
          <div className="uk-h4">友だち追加経路の作成</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="title">
                経路名
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  {...register('title', {
                    required: true,
                    validate: titleUniqueValidator,
                  })}
                />
                {errors.title?.type === 'required' && (
                  <ErrorMessage message="入力してください" />
                )}
                {errors.title?.type === 'validate' && (
                  <ErrorMessage message={errors.title.message || ''} />
                )}
              </div>
            </div>
            <div className="uk-margin">
              <div
                className="uk-h6 uk-text-muted"
                onClick={() =>
                  setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)
                }
              >
                <span style={{ cursor: 'pointer' }}>
                  その他・高度な設定
                  {isAdvancedSettingsOpen ? <ExpandLess /> : <ExpandMore />}
                </span>
              </div>
              {isAdvancedSettingsOpen && (
                <>
                  <label
                    className="uk-flex uk-flex-middle uk-margin-small-bottom"
                    style={{
                      width: 'fit-content',
                      color: isParameterInputOpen ? '#333' : '#aaa',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={isParameterInputOpen}
                      onChange={() =>
                        setIsParameterInputOpen(!isParameterInputOpen)
                      }
                      className="uk-checkbox"
                      style={{ margin: '0 10px 0 0' }}
                    />
                    <div className="uk-text-small uk-flex-1">
                      パラメーター (ocsrc=XXXX)を設定する
                    </div>
                  </label>
                  {isParameterInputOpen && (
                    <>
                      <label className="uk-flex uk-flex-middle">
                        <input
                          className="uk-input"
                          {...register('parameter', {
                            required: isParameterInputOpen,
                            validate: parameterUniqueValidator,
                          })}
                        />
                      </label>
                      {errors.parameter?.type === 'required' && (
                        <ErrorMessage message="入力してください" />
                      )}
                      {errors.parameter?.type === 'validate' && (
                        <ErrorMessage
                          message={errors.parameter.message || ''}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="uk-margin uk-text-center">
              {isCreating ? (
                <ReactLoading
                  type={'bubbles'}
                  color={'#00888dbf'}
                  height={80}
                  width={80}
                  className="uk-margin-auto"
                />
              ) : (
                <button className="uk-button uk-button-primary" type="submit">
                  作成
                </button>
              )}
            </div>
          </form>
        </ModalInner>
      </Modal>
    </>
  )
}

export default AddLineFriendTrackingFormModal

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`

const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <span className="uk-text-danger uk-text-small uk-margin-xsmall-top">
      {message}
    </span>
  )
}
