import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ShopItem, PvRetargetingTrigger } from '../../types'
import { useAppSelector } from '../../hooks'
import { RootState } from '../../stores'

interface Props {
  trigger: PvRetargetingTrigger
}

const CardPreview = ({ trigger }: Props) => {
  const shopItems = useAppSelector((state: RootState) => state.shopItem.list)
  const [activeItem, setActiveItem] = useState<ShopItem | null>(null)
  const [displayLimitedStock, setDisplayLimitedStock] = useState<boolean>(false)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!activeItem && shopItems.length > 0) {
      setActiveItem(shopItems[0])
    }
  }, [shopItems])

  useEffect(() => {
    if (initialLoading) {
      setInitialLoading(false)
      return
    }
    setDisplayLimitedStock(trigger.pushMessageCardObject.limitedStockSetting)
  }, [trigger.pushMessageCardObject.limitedStockSetting])

  const {
    title,
    imageLabel,
    cardType,
    buttonLabel,
    mainText,
    limitedStockLabel,
    colorSettings,
  } = trigger.pushMessageCardObject
  return (
    <div style={{ width: 290 }}>
      <Card>
        <CardHero
          style={
            activeItem?.img1Origin
              ? { backgroundImage: `url(${activeItem?.img1Origin})` }
              : {}
          }
        >
          {displayLimitedStock ? (
            <HeroLabel
              style={{
                color: colorSettings.limitedStock.text,
                backgroundColor: colorSettings.limitedStock.background + 'b3',
              }}
            >
              {limitedStockLabel || 'ラベルを入力'}
            </HeroLabel>
          ) : (
            <HeroLabel
              style={{
                color: colorSettings.title.text,
                backgroundColor: colorSettings.title.background + 'b3',
              }}
            >
              {title || 'ラベルを入力'}
            </HeroLabel>
          )}
          {cardType === 'item_with_coupon' && (
            <HeroCoupon
              style={{
                color: colorSettings.imageLabel.text,
                backgroundColor: colorSettings.imageLabel.background + 'b3',
              }}
            >
              {imageLabel || '説明ラベル'}
            </HeroCoupon>
          )}
        </CardHero>
        <CardBody>
          <BodyTitle>
            {cardType === 'item' && (activeItem?.title || '商品名')}
            {cardType === 'item_with_coupon' && (
              <>
                割引クーポンをご利用いただけます。
                <br />
                {`クーポンコード： ${mainText || ''}`}
              </>
            )}
          </BodyTitle>
          <BodyPrice>
            <span>¥</span>
            {(activeItem?.price || 1234).toLocaleString()}
            {activeItem?.multiPrice && <span> ~</span>}
          </BodyPrice>
        </CardBody>
        <CardFooter>
          <FooterButton
            style={{
              color: colorSettings.button.text,
              backgroundColor: colorSettings.button.background,
            }}
          >
            {buttonLabel || '詳細を見る'}
          </FooterButton>
        </CardFooter>
      </Card>
      {shopItems.length > 0 && (
        <div className="uk-margin-top uk-flex uk-flex-column uk-flex-middle">
          {shopItems.length > 1 && (
            <a
              className="uk-text-small uk-flex uk-flex-middle"
              onClick={() => {
                const items = shopItems.filter(
                  i => i.itemId !== activeItem?.itemId
                )
                const item = items[Math.floor(Math.random() * items.length)]
                setActiveItem(item)
              }}
            >
              <span uk-icon="refresh" className="uk-margin-small-right" />
              表示商品をランダムに切り替える
            </a>
          )}
          {trigger.pushMessageCardObject.limitedStockSetting && (
            <a
              className="uk-text-small uk-flex uk-flex-middle uk-margin-small"
              onClick={() => {
                setDisplayLimitedStock(!displayLimitedStock)
              }}
            >
              <span uk-icon="refresh" className="uk-margin-small-right" />
              {`${
                displayLimitedStock ? '通常' : '在庫数が少ない'
              }場合の表示に切り替える`}
            </a>
          )}
          <div
            className="uk-text-small uk-text-muted"
            style={{ marginTop: 16 }}
          >
            ※ 実際にはお客さまが閲覧した商品が送信されます。
          </div>
        </div>
      )}
    </div>
  )
}

export default CardPreview

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
`
const CardHero = styled.div`
  position: relative;
  height: 290px;
  background-color: #ccc;
  padding: 16px 16px 0;
  background-image: url('https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/shop/shop_item_default.png');
  background-size: cover;
  background-position: center;
`
const CardBody = styled.div`
  padding: 20px 20px 12px;
`
const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
`
// Hero子要素
const HeroLabel = styled.div`
  background-color: #777777b3;
  color: #ffffffcc;
  padding: 2px 12px;
  font-size: 15px;
  border-radius: 13px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const HeroCoupon = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2px 10px;
  background-color: #777777b3;
  color: #ffffffcc;
  font-size: 13px;
  max-width: 185px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
// Body子要素
const BodyTitle = styled.div`
  font-size: 15px;
  color: #222;
`
const BodyPrice = styled.div`
  font-size: 16px;
  color: #999;
  margin-top: 6px;
  span {
    margin-right: 2px;
  }
`
// Footer子要素
const FooterButton = styled.div`
  width: 100%;
  text-align: center;
  background-color: #39c94f;
  color: #fff;
  height: 44px;
  line-height: 44px;
  font-size: 15px;
  border-radius: 8px;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
