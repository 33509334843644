import React, { useEffect } from 'react'
import { Company } from '../../types'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import { fetchLineFollowTriggersConfig } from 'components/Dashboard/stores/lineFollowTriggers'
import TriggersStatusSwitch from './TriggersStatusSwitch'
import ActiveFollowTriggersTable from './ActiveFollowTriggersTable'
import RouteSelectModal from './RouteSelectModal'

interface Props {
  company: Company
  defaultPushMessageTriggerActive: boolean
  webhookReady: boolean
  liffReady: boolean
}

const App = ({
  company,
  defaultPushMessageTriggerActive,
  webhookReady,
  liffReady,
}: Props) => {
  const dispatch = useAppDispatch()
  const { lineFollowTriggersActive } = useAppSelector(
    (state: RootState) => state.lineFollowTriggers
  )

  useEffect(() => {
    dispatch(fetchLineFollowTriggersConfig(company))
  }, [])

  return (
    <div id="app-root">
      <div className="uk-section-small">
        <h1 className="uk-h4">デフォルトメッセージ</h1>
        <table className="uk-table uk-table-striped uk-table-middle uk-margin-xsmall-top">
          <thead>
            <tr>
              <th>対象</th>
              <th>ステータス</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>全員</td>
              <td className="uk-table-shrink" style={{ minWidth: 120 }}>
                {defaultPushMessageTriggerActive ? '有効' : '無効'}
              </td>
              <td className="uk-table-shrink" style={{ minWidth: 60 }}>
                <a
                  href={`/companies/${company.slug}/company_line_account/welcome_message`}
                  className="uk-button uk-button-default uk-button-small"
                >
                  編集
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-section-small">
        <h1 className="uk-h4">友だち追加経路別のメッセージ</h1>
        {!webhookReady && (
          <div className="uk-alert-warning uk-alert uk-text-small">
            <p>
              <i
                className="material-icons"
                style={{ fontSize: 18, verticalAlign: 'top' }}
              >
                warning_amber
              </i>
              経路別のあいさつメッセージを利用するためには、LINE公式アカウントのWebhook
              URLの設定が必要です
            </p>
          </div>
        )}
        {!liffReady && (
          <div className="uk-alert-warning uk-alert uk-text-small">
            <p>
              <i
                className="material-icons"
                style={{ fontSize: 18, verticalAlign: 'top' }}
              >
                warning_amber
              </i>
              経路別のあいさつメッセージを利用するためには、「LINE公式アカウント設定」メニューの「LINEログイン機能設定」の登録が必要です
            </p>
          </div>
        )}
        <div className="uk-alert-danger uk-alert uk-text-small">
          <p>
            経路別のメッセージを有効にした場合
            <br />
            ・あいさつメッセージの送信でLINEの枠数を消費するようになります。メッセージ残数が不足している場合はあいさつメッセージが送られなくなります。
            <br />
            ・LINE標準の友だち追加URLから友だち追加があった場合はメッセージの送信が1分ほど遅れます。
            <br />
            ・経路別のメッセージが設定されていない経路はデフォルトのあいさつメッセージが送信されます。
          </p>
        </div>
        <TriggersStatusSwitch
          company={company}
          available={webhookReady && liffReady}
        />
        {lineFollowTriggersActive && (
          <>
            <h1 className="uk-h5">新しく経路別メッセージを作成する</h1>
            <RouteSelectModal company={company} rootId="app-root" />
            <ActiveFollowTriggersTable company={company} />
          </>
        )}
      </div>
    </div>
  )
}

export default App
