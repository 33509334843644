import _ from 'lodash'
import {
  PushMessageFormCardTypeObject,
  PushMessageFormImageObject,
  PushMessageFormImageWithLinkObject,
  PushMessageFormObject,
  PushMessageFormTextObject,
  PushMessageObject,
} from 'types.ts/PushMessage'

// 以下`PushMessageReservationForm`と`PushMessageTriggerForm`の共通処理
// 2つの共通部分（メッセージフォーム部分）をまとめてコンポーネント化したい

export const includeOcDomainUri = (messages: PushMessageFormObject[]) => {
  const ocDomainRegexp = /omiseconnect\.jp|omct\.jp/
  return messages.some(obj => {
    if (obj.type === 'text') {
      return ocDomainRegexp.test(obj.text)
    }
    if (obj.type === 'imageWithLink') {
      return ocDomainRegexp.test(obj.uri)
    }
    return false
  })
}

// NOTE: `PushMessageObject` を `PushMessageFormObject` に変換
export const convertDBObjectToFormObject = (
  pushMessageObject: PushMessageObject
): PushMessageFormObject => {
  const identifier = `${pushMessageObject.type}-${pushMessageObject.id}`
  if (pushMessageObject.type === 'image') {
    if (pushMessageObject.uri) {
      const formObj: PushMessageFormImageWithLinkObject = {
        ...pushMessageObject,
        type: 'imageWithLink',
        identifier,
        file: null,
      }
      return formObj
    } else {
      const formObj: PushMessageFormImageObject = {
        ...pushMessageObject,
        identifier,
        file: null,
      }
      return formObj
    }
  } else if (pushMessageObject.type === 'text') {
    const formObj: PushMessageFormTextObject = {
      ...pushMessageObject,
      identifier,
    }
    return formObj
  } else {
    // pushMessageObject.type === 'cardType'
    const formObj: PushMessageFormCardTypeObject = {
      ...pushMessageObject,
      identifier,
    }
    return formObj
  }
}

export const checkFormObjectIsValid = (obj: PushMessageFormObject): boolean => {
  if (obj.type === null) return false

  if (obj.type === 'text') {
    return obj.text !== ''
  }

  if (obj.type === 'image') {
    const isImageValid = Boolean(!_.isNil(obj.file) || obj.imageSrc)
    return isImageValid
  }

  if (obj.type === 'imageWithLink') {
    const isImageValid = Boolean(!_.isNil(obj.file) || obj.imageSrc)
    return Boolean(
      isImageValid && obj.uri && !(obj.altText && obj.altText.length > 100)
    )
  }

  if (obj.type === 'cardType') {
    return (
      obj.cardTypeMessageId !== null &&
      !(obj.altText && obj.altText.length > 100)
    )
  }

  return false
}
