import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import {
  setMessageDeliveryConfig,
  setMessageDeliveryConfigChangeSaved,
} from 'components/Dashboard/stores/company'

const AdvancedSettingsForm = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isMaxDuplicatedFormOpen, setIsMaxDuplicatedFormOpen] = useState<
    boolean | null
  >(null)
  const dispatch = useAppDispatch()
  const { messageDeliveryConfig } = useAppSelector(
    (state: RootState) => state.company
  )
  if (!messageDeliveryConfig) return <div />

  if (isMaxDuplicatedFormOpen === null && messageDeliveryConfig) {
    setIsMaxDuplicatedFormOpen(
      messageDeliveryConfig.pvRetargetingMaxDuplicatedItems !== 5
    )
  }

  return (
    <>
      <div className="uk-h6 uk-text-bold" onClick={() => setIsOpen(!isOpen)}>
        <span style={{ cursor: 'pointer' }}>
          その他・高度な設定
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </span>
      </div>
      {isOpen && (
        <div className="uk-margin-bottom">
          <label
            className="uk-flex uk-flex-top uk-margin-bottom"
            style={{
              width: 'fit-content',
              color: messageDeliveryConfig.fillRemindMessageWithRecommended
                ? '#333'
                : '#aaa',
            }}
          >
            <input
              type="checkbox"
              checked={messageDeliveryConfig.fillRemindMessageWithRecommended}
              onChange={e => {
                dispatch(
                  setMessageDeliveryConfig({
                    ...messageDeliveryConfig,
                    fillRemindMessageWithRecommended: e.target.checked,
                  })
                )
                dispatch(setMessageDeliveryConfigChangeSaved(false))
              }}
              className="uk-checkbox"
              style={{ margin: '0 10px 0 0' }}
            />
            <div className="uk-text-small uk-flex-1">
              閲覧商品数が規定数に満たない場合、独自に算出した類似商品またはおすすめの商品によってメッセージの商品を補完する（ON推奨）
            </div>
          </label>
          <div className="uk-margin-bottom">
            <label
              className="uk-flex uk-flex-middle uk-margin-small-bottom"
              style={{
                width: 'fit-content',
                color: isMaxDuplicatedFormOpen ? '#333' : '#aaa',
              }}
            >
              <input
                type="checkbox"
                checked={isMaxDuplicatedFormOpen || false}
                onChange={e => {
                  setIsMaxDuplicatedFormOpen(e.target.checked)
                  dispatch(
                    setMessageDeliveryConfig({
                      ...messageDeliveryConfig,
                      pvRetargetingMaxDuplicatedItems: e.target.checked ? 4 : 5,
                    })
                  )
                  dispatch(setMessageDeliveryConfigChangeSaved(false))
                }}
                className="uk-checkbox"
                style={{ margin: '0 10px 0 0' }}
              />
              <div className="uk-text-small uk-flex-1">
                2回目以降の配信における、前回の配信と重複する商品の上限値を設定する
              </div>
            </label>
            {isMaxDuplicatedFormOpen && (
              <label className="uk-flex uk-flex-middle">
                <select
                  className="uk-select uk-margin-left uk-margin-xsmall-right"
                  style={{ width: 140 }}
                  value={messageDeliveryConfig.pvRetargetingMaxDuplicatedItems}
                  onChange={e => {
                    dispatch(
                      setMessageDeliveryConfig({
                        ...messageDeliveryConfig,
                        pvRetargetingMaxDuplicatedItems: Number(e.target.value),
                      })
                    )
                    dispatch(setMessageDeliveryConfigChangeSaved(false))
                  }}
                >
                  {[4, 3, 2, 1].map(n => (
                    <option key={n} value={n}>
                      {`${n}商品${n === 4 ? '（推奨）' : ''}`}
                    </option>
                  ))}
                </select>
                <div className="uk-text-small uk-flex-1">
                  まで重複を許可する
                </div>
              </label>
            )}
          </div>
          <label
            className="uk-flex uk-flex-middle uk-margin-small-bottom"
            style={{
              width: 'fit-content',
              color:
                messageDeliveryConfig.pvRetargetingItemsOrder === 'random'
                  ? '#333'
                  : '#aaa',
            }}
          >
            <input
              type="checkbox"
              checked={
                messageDeliveryConfig.pvRetargetingItemsOrder === 'random'
              }
              onChange={e => {
                dispatch(
                  setMessageDeliveryConfig({
                    ...messageDeliveryConfig,
                    pvRetargetingItemsOrder: e.target.checked
                      ? 'random'
                      : 'pv_count',
                  })
                )
                dispatch(setMessageDeliveryConfigChangeSaved(false))
              }}
              className="uk-checkbox"
              style={{ margin: '0 10px 0 0' }}
            />
            <div className="uk-text-small uk-flex-1">
              2回目以降の配信では商品の並び順をランダムにする（ON推奨）
            </div>
          </label>
        </div>
      )}
    </>
  )
}

export default AdvancedSettingsForm
