import React from 'react'
import { Company } from '../../types'

interface Props {
  company: Company
}

const BrowserConnectedCount = ({ company }: Props) => (
  <div>
    <div>
      <span className="uk-text-small">ブラウザ紐付き友だち数：</span>
      <span className="uk-h2 uk-margin-remove uk-text-primary">
        {company.browserConnectedCustomersCount.toLocaleString()}
      </span>
      <span className="uk-text-small">人</span>
    </div>
    <div className="uk-flex">
      <a
        href={`/usage_guide_redirects/line_friends_browser_connected?shop_account_type=${company.shopAccountType}`}
        target="_blank"
        rel="noopener"
      >
        <div className="uk-flex uk-flex-middle uk-text-small">
          <div className="uk-margin-xsmall-right">
            ブラウザ紐付き友だち数を増やすには？
          </div>
          <div className="material-icons" style={{ fontSize: 18 }}>
            open_in_new
          </div>
        </div>
      </a>
    </div>
  </div>
)

export default BrowserConnectedCount
