import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const LineGraph = ({ data, xKey, lineData }) => {
  // 中途半端な数字になるとわかりにくいので必ず5の倍数になるように
  const domainMin = dataMin => Math.floor((dataMin * 0.9) / 5) * 5
  const domainMax = dataMax => Math.ceil((dataMax * 1.1) / 5) * 5

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <XAxis dataKey={xKey} />
        <YAxis
          type="number"
          domain={[domainMin, domainMax]}
          allowDecimals={false}
        />
        <Tooltip />
        {lineData.length > 1 && (
          <Legend
            verticalAlign="top"
            height={36}
            iconType={'line'}
            wrapperStyle={{ left: 45 }}
          />
        )}
        {lineData.map(({ key, label, color }) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={color}
            activeDot={{ r: 5 }}
            name={label}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineGraph
