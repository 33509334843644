import React, { useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { RootState } from '../../stores'
import Switch from '../shared/Switch'
import { updateLineFollowTriggersConfig } from 'components/Dashboard/stores/lineFollowTriggers'
import { Company } from '../../types'

interface Props {
  company: Company
  available: boolean
}

const TriggersStatusSwitch = ({ company, available }: Props) => {
  const [saving, setSaving] = useState(false)
  const dispatch = useAppDispatch()
  const { lineFollowTriggersActive } = useAppSelector(
    (state: RootState) => state.lineFollowTriggers
  )

  const toggleTriggerStatus = async () => {
    if (saving || !available) return
    if (
      !lineFollowTriggersActive &&
      !window.confirm('経路別のあいさつメッセージを有効にしますか？')
    ) {
      return
    }

    setSaving(true)
    const newStatus = !lineFollowTriggersActive
    await dispatch(updateLineFollowTriggersConfig(company, newStatus))
    setSaving(false)
  }

  return (
    <label>
      <div className="uk-flex uk-flex-middle">
        <FormControlLabel
          control={
            <Switch
              checked={lineFollowTriggersActive}
              onChange={toggleTriggerStatus}
              disabled={!available}
            />
          }
          label="経路別のあいさつメッセージを有効にする"
        />
      </div>
    </label>
  )
}

export default TriggersStatusSwitch
