import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '.'
import { Company, Customer } from '../types'

export enum FilterTypeEnum {
  LINE_CONNECTED = 'line_connected',
  UNREAD = 'unread',
  ACTION_REQUIRED = 'action_required',
  BROWSER_CONNECTED = 'browser_connected',
  ID_CONNECTED = 'id_connected',
  SHOP_CONNECTED = 'shop_connected',
  RESTOCK_SUBSCRIBED = 'restock_subscribed',
  SPAM = 'spam',
}

interface CustomersState {
  list: Customer[]
  selectedCustomer: Customer | null
  loading: boolean
  currentPage: number
  totalPages: number
  searchParams: {
    filter: FilterTypeEnum
    keyword: string
  }
}

const initialState: CustomersState = {
  list: [],
  selectedCustomer: null,
  loading: false,
  currentPage: 1,
  totalPages: 1,
  searchParams: {
    filter: FilterTypeEnum.LINE_CONNECTED,
    keyword: '',
  },
}

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
    addList: (state, action) => {
      state.list = state.list.concat(action.payload)
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload
    },
    setSearchParams: (state, action) => {
      state.searchParams = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    resetCustomersData: state => {
      state.list = []
      state.currentPage = 1
      state.totalPages = 1
    },
    resetSelectedCustomer: (state, action) => {
      const newCustomer = action.payload
      state.selectedCustomer = newCustomer
      let latestMessageSentAtChanged = false
      const customers = state.list.map(customer => {
        if (customer.id === newCustomer.id) {
          if (
            customer.latestMessageSentAt !== newCustomer.latestMessageSentAt
          ) {
            latestMessageSentAtChanged = true
          }
          return newCustomer
        } else {
          return customer
        }
      })
      if (latestMessageSentAtChanged) {
        customers.sort((a, b) => {
          const ta = new Date(a.latestMessageSentAt).getTime() || 0
          const tb = new Date(b.latestMessageSentAt).getTime() || 0
          return tb - ta
        })
      }
      state.list = customers
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export default slice.reducer

export const {
  setList,
  addList,
  setSelectedCustomer,
  setSearchParams,
  setCurrentPage,
  setTotalPages,
  resetCustomersData,
  resetSelectedCustomer,
  setLoading,
} = slice.actions

export function fetchCustomers(company: Company) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    try {
      const { searchParams, currentPage } = getState().customers
      dispatch(setLoading(true))
      const params = { ...searchParams, page: currentPage }
      const response = await axios.get(
        `/api/companies/${company.slug}/talk_room/customers`,
        { params }
      )
      const { customers, totalPages } = response.data
      if (currentPage === 1) {
        dispatch(setList(customers))
      } else {
        dispatch(addList(customers))
      }
      dispatch(setLoading(false))
      dispatch(setTotalPages(totalPages))
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
    }
  }
}

export function fetchSelectedCustomer(company: Company, customer: Customer) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/talk_room/customers/${customer.id}`
      )
      dispatch(resetSelectedCustomer(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}

type UpdateCustomerParams = {
  support_status?: 'action_required' | 'default'
  chat_read_status?: 'all_chat_read'
  management_name?: string
  management_note?: string
  spam_at?: string | null
}

export function updateCustomer(
  company: Company,
  customer: Customer,
  params: UpdateCustomerParams
) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const { isAdmin } = getState().company
    if (isAdmin) return

    const url = `/api/companies/${company.slug}/talk_room/customers/${customer.id}`
    const data = {
      ...params,
      authenticity_token: $('#authenticity_token').val(),
    }
    const response = await axios.put(url, data)
    dispatch(resetSelectedCustomer(response.data))
  }
}

export function toggleSupportStatus(company: Company, customer: Customer) {
  const newStatus =
    customer.supportStatus === 'default' ? 'action_required' : 'default'
  return updateCustomer(company, customer, { support_status: newStatus })
}

export function updateToAllChatRead(company: Company, customer: Customer) {
  return updateCustomer(company, customer, {
    chat_read_status: 'all_chat_read',
  })
}
