import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { useModal } from 'react-hooks-use-modal'
import { Company } from '../../types'
import UserCustomerConnectForm from '../../../organisms/UserCustomerConnectForm'

interface Props {
  company: Company
  rootId: string
  sendMessage: () => Promise<void>
  errorMessages?: string[]
  prepareSendComponentWarning?: React.ReactNode
  sentComponentWarning?: React.ReactNode
}

declare const window: Window['window'] & {
  dataLayer: object[]
}

const SendingTestModalComponent = ({
  rootId,
  company,
  sendMessage,
  errorMessages = [],
  prepareSendComponentWarning,
  sentComponentWarning,
}: Props) => {
  const [modalStep, setModalStep] = useState(0)

  const [Modal, openModal, closeModal, isModalOpen] = useModal(rootId, {
    closeOnOverlayClick: true,
  })

  useEffect(() => {
    if (!isModalOpen) {
      setModalStep(0)
    }
  }, [isModalOpen])

  const sendTestMessage = () => {
    setModalStep(1)
    sendMessage().then(() => {
      setModalStep(2)
    })
  }

  const PrepareSendComponent = (
    <>
      <div className="uk-text-small">
        事前に自分のLINEアカウントにメッセージをテスト配信することが可能です。
        {prepareSendComponentWarning && (
          <div className="uk-margin-small-top">
            {prepareSendComponentWarning}
          </div>
        )}
      </div>
      <div className="uk-h5">テスト配信用LINEアカウント</div>
      <UserCustomerConnectForm
        company={company}
        sendMessage={sendTestMessage}
        sendButtonText="テスト配信"
      />
      <div className="uk-text-center uk-margin-top">
        <div
          className="uk-button uk-button-text uk-padding-remove"
          onClick={closeModal}
        >
          キャンセル
        </div>
      </div>
    </>
  )

  const SendingComponent = (
    <>
      <div className="uk-text-center">テスト配信の準備中です...</div>
      <div className="uk-section">
        <ReactLoading
          className="uk-margin-auto"
          type={'spin'}
          color={'#00888d'}
          height={100}
          width={100}
        />
      </div>
    </>
  )

  const SentComponent = (
    <>
      <div className="uk-margin-bottom uk-text-small">
        メッセージの送信準備が完了しました。
        <br />
        まもなくテスト配信が行われますので、LINEアカウントにてご確認ください。
      </div>
      <div className="uk-margin-bottom uk-text-small">
        {sentComponentWarning}
        <span className="uk-text-danger">
          ※
          編集した内容はまだ登録されていません。実際に登録するには「保存する」ボタンを押してください。
        </span>
      </div>
      <div className="uk-text-center">
        <div
          className="uk-button uk-button-text uk-padding-remove"
          onClick={closeModal}
        >
          閉じる
        </div>
      </div>
    </>
  )

  const stepComponents = [PrepareSendComponent, SendingComponent, SentComponent]

  return (
    <>
      <div className="uk-flex uk-column uk-flex-middle uk-flex-center">
        <button
          className="uk-button uk-button-default uk-button-small"
          onClick={openModal}
          disabled={errorMessages.length > 0}
        >
          テスト配信
        </button>
      </div>
      <Modal>
        <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
          <div className="uk-h4">テスト配信</div>
          {stepComponents[modalStep]}
        </ModalInner>
      </Modal>
    </>
  )
}

export default SendingTestModalComponent

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
