import React, { useState } from 'react'
import axios from 'axios'
import ReactLoading from 'react-loading'

interface Props {
  companySlug: string
  shopUrl: string
}

type CheckResult = 'success' | 'failed'

const TrackingTagCheckButton = ({ companySlug, shopUrl }: Props) => {
  const [checkResult, setCheckResult] = useState<CheckResult | null>(null)
  const [isChecking, setIsChecking] = useState<boolean>(false)

  const handleClickCheckButton = () => {
    setIsChecking(true)
    tagCheck()
  }

  const tagCheck = () => {
    const data = new FormData()
    const token = String($('#authenticity_token').val())
    data.append('authenticity_token', token)
    axios
      .post(`/api/companies/${companySlug}/tracking_tag_check`, data)
      .then(response => {
        setCheckResult(response.data.result)
        setIsChecking(false)
      })
      .catch(e => {
        console.log(e)
        setIsChecking(false)
      })
  }

  const tagCheckButton = (
    <div
      className="uk-button uk-button-primary uk-button-large"
      onClick={handleClickCheckButton}
    >
      {`トラッキングタグの設置を${
        checkResult === 'failed' ? '再度' : ''
      }チェックする`}
    </div>
  )

  const tagCheckGuide = (
    <div className="uk-section uk-section-muted uk-padding-small uk-margin-xsmall-top">
      <div className="uk-margin-xsmall-bottom uk-text-bold">
        トラッキングタグの設置チェック手順
      </div>
      <div>
        1.{' '}
        <a href={shopUrl} target="_blank" rel="noopener">
          ショップのトップページ
        </a>
        にアクセスしてください。
        <br />
        2. 下のチェックボタンをクリックしてください。
      </div>
    </div>
  )

  return (
    <div>
      {checkResult === 'success' && (
        <div className="uk-text-success">
          <span className="uk-margin-xsmall-right" uk-icon="check" />
          タグの設置が確認できました。
        </div>
      )}
      {checkResult === 'failed' && (
        <div className="uk-text-small uk-margin-small-bottom">
          <span className="uk-text-danger uk-text-bold">
            タグの設置が確認できませんでした。タグを正しく設置して再度チェックを行ってください。
          </span>
          {tagCheckGuide}
        </div>
      )}
      {checkResult === null && (
        <div className="uk-text-small uk-margin-small-bottom">
          設置が完了したら以下のボタンをクリックして正しく設置できているかチェックしてください。
          {tagCheckGuide}
        </div>
      )}
      {isChecking ? (
        <div className="uk-flex">
          <div className="uk-flex uk-flex-column uk-flex-middle">
            <ReactLoading
              type={'bubbles'}
              color={'#00888dbf'}
              height={80}
              width={80}
            />
            <span className="uk-text-small uk-text-muted">チェック中...</span>
          </div>
        </div>
      ) : (
        checkResult !== 'success' && tagCheckButton
      )}
    </div>
  )
}

export default TrackingTagCheckButton
