import React, { useEffect } from 'react'
import {
  CardTypeMessage,
  CardTypeMessageContentLabelStyles,
  Company,
} from '../../types'
import { setCompany } from '../../stores/company'
import { useAppDispatch } from '../../hooks'
import {
  fetchCardTypeMessageContents,
  setCardTypeMessage,
  setCardTypeMessageContentLabelStyles,
} from 'components/Dashboard/stores/cardTypeMessage'
import FormRoot from './FormRoot'

interface Props {
  company: Company
  cardTypeMessage: CardTypeMessage
  cardTypeMessageContentLabelStyles: CardTypeMessageContentLabelStyles[]
}

const App = ({
  company,
  cardTypeMessage,
  cardTypeMessageContentLabelStyles,
}: Props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setCompany(company))
    dispatch(setCardTypeMessage(cardTypeMessage))
    dispatch(
      setCardTypeMessageContentLabelStyles(cardTypeMessageContentLabelStyles)
    )
    dispatch(fetchCardTypeMessageContents(company, cardTypeMessage.id))
  }, [])

  return (
    <div className="uk-margin-top" id="app-root">
      <FormRoot />
    </div>
  )
}

export default App
