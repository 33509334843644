import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch } from '.'
import { Order, AccessLog, Company, Customer } from '../types'

interface CustomerShopLogsState {
  orders: Order[]
  accessLogs: AccessLog[]
}

const initialState: CustomerShopLogsState = {
  orders: [],
  accessLogs: [],
}

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload
    },
    setAccessLogs: (state, action) => {
      state.accessLogs = action.payload
    },
  },
})

export default slice.reducer

export const { setOrders, setAccessLogs } = slice.actions

export function fetchCustomerShopLogs(company: Company, customer: Customer) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/talk_room/customers/${customer.id}/customer_shop_logs`
      )
      const { orders, accessLogs } = response.data
      dispatch(setOrders(orders))
      dispatch(setAccessLogs(accessLogs))
    } catch (error) {
      console.log(error)
    }
  }
}
