import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch } from '.'
import { ShopItem, Company } from '../types'

interface ShopItemState {
  list: ShopItem[]
}

const initialState: ShopItemState = {
  list: [],
}

const slice = createSlice({
  name: 'shopItem',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
  },
})

export default slice.reducer

export const { setList } = slice.actions

export function fetchShopItems(company: Company) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/shop_items`
      )
      dispatch(setList(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}
