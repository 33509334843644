import React from 'react'
import { PvRetargetingTrigger } from '../../types'
import { useAppDispatch } from '../../hooks'
import { updateListTrigger } from '../../stores/pvRetargetingTrigger'

interface Props {
  trigger: PvRetargetingTrigger
  name: 'title' | 'imageLabel' | 'button' | 'limitedStock'
}

interface ColorType {
  text: string
  background: string
}

const labelColors: ColorType[] = [
  { text: '#111111', background: '#ffffff' },
  { text: '#ffffff', background: '#666f86' },
  { text: '#ffffff', background: '#323333' },
  { text: '#ffffff', background: '#ec3d44' },
  { text: '#111111', background: '#fbc000' },
  { text: '#ffffff', background: '#17c950' },
  { text: '#ffffff', background: '#007bff' },
  { text: '#111111', background: '#ffc3c4' },
  { text: '#111111', background: '#f0ebcc' },
  { text: '#111111', background: '#a9c8b8' },
]

const buttonColors: ColorType[] = [
  { text: '#42659a', background: '#ffffff' },
  { text: '#ffffff', background: '#666f86' },
  { text: '#ffffff', background: '#323333' },
  { text: '#ffffff', background: '#ec3d44' },
  { text: '#111111', background: '#fbc000' },
  { text: '#ffffff', background: '#17c950' },
  { text: '#ffffff', background: '#007bff' },
  { text: '#111111', background: '#ffc3c4' },
  { text: '#111111', background: '#f0ebcc' },
  { text: '#111111', background: '#a9c8b8' },
]

const ColorSelector = ({ trigger, name }: Props) => {
  const dispatch = useAppDispatch()

  const { colorSettings } = trigger.pushMessageCardObject
  const isLabel = name === 'title' || name === 'imageLabel'
  const colors = isLabel ? labelColors : buttonColors

  const handleSelect = (color: ColorType) => {
    const newColorSettings = { ...colorSettings }
    newColorSettings[name] = color
    const styleJson = JSON.stringify(newColorSettings)
    dispatch(
      updateListTrigger({
        ...trigger,
        pushMessageCardObject: {
          ...trigger.pushMessageCardObject,
          styleJson,
          colorSettings: newColorSettings,
        },
      })
    )
  }

  return (
    <div className="uk-flex uk-flex-wrap">
      {colors.map(color => {
        const { text, background } = color
        const isSelected =
          colorSettings[name].text === text &&
          colorSettings[name].background === background
        return (
          <div
            key={`${text}${background}`}
            onClick={() => handleSelect(color)}
            style={{
              color: text,
              background: background,
              border: background.match('#ffffff') ? '1px solid #ddd' : 'none',
              width: 32,
              height: 32,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              cursor: 'pointer',
              marginLeft: 5,
              marginBottom: 5,
              boxSizing: 'border-box',
            }}
          >
            {isSelected ? (
              <span style={{ width: 16 }} uk-icon="icon: check" />
            ) : (
              'A'
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ColorSelector
