import React, { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import CheckoutForm from '../organisms/CheckoutForm'
import PaymentMethodsList from '../organisms/PaymentMethodsList'

const env = $('#rails_env_constant').val()
const stripePromise = loadStripe(
  env === 'production'
    ? 'pk_live_51JPhKCFGNN77YMExppiYbR95vn1b881js5KJjj5MKKesvHV6XOxH4cyfnO2gJvTxfSDOaObEdsXwdKVcWkkagKNX00HBmh2Ngy'
    : 'pk_test_51JPhKCFGNN77YMExwA5sO9tfPkgayh6FbtuWzdrjraNYDY5RLBEwUDtXfI49Ks5RQ0t0Ei11bfiHKvHFNImNLGRz00IJYTOyKN'
)

const StripePayment = ({ company }) => {
  const [paymentMethods, setPaymentMethods] = useState([])
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchPaymentMethods()
  }, [])

  const fetchPaymentMethods = () => {
    setIsLoading(true)
    axios
      .get(`/api/companies/${company.slug}/payment/payment_methods`)
      .then(response => {
        setPaymentMethods(response.data.payment_methods)
        setDefaultPaymentMethodId(response.data.default_payment_method_id)
        setIsLoading(false)
      })
  }

  return isLoading ? (
    <div style={{ height: 124 }}>
      <div className="uk-text-small uk-text-muted">読み込み中...</div>
    </div>
  ) : (
    <>
      <PaymentMethodsList
        company={company}
        paymentMethods={paymentMethods}
        defaultPaymentMethodId={defaultPaymentMethodId}
        fetchPaymentMethods={fetchPaymentMethods}
      />
      <Elements stripe={stripePromise}>
        <CheckoutForm
          company={company}
          fetchPaymentMethods={fetchPaymentMethods}
          defaultPaymentMethodId={defaultPaymentMethodId}
        />
      </Elements>
    </>
  )
}
export default StripePayment
