import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'
import { AppDispatch, RootState } from '.'
import { Company, PvRetargetingTrigger } from '../types'

interface PvRetargetingTriggerState {
  list: PvRetargetingTrigger[]
  changeSaved: boolean
}

const initialState: PvRetargetingTriggerState = {
  list: [],
  changeSaved: true,
}

const slice = createSlice({
  name: 'pvRetargetingTrigger',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload.map((trigger: PvRetargetingTrigger) => {
        if (trigger.pushMessageCardObject) {
          const { styleJson } = trigger.pushMessageCardObject
          let colorSettings = {}
          try {
            colorSettings = JSON.parse(styleJson || '{}')
          } catch (error) {
            console.log(error)
          }
          const defaultSettings = {
            title: {
              background: '#ec3d44',
              text: '#ffffff',
            },
            imageLabel: {
              background: '#007bff',
              text: '#ffffff',
            },
            button: {
              background: '#a9c8b8',
              text: '#111111',
            },
            limitedStock: {
              background: '#fbc000',
              text: '#111111',
            },
          }
          trigger.pushMessageCardObject.colorSettings = {
            ...defaultSettings,
            ...colorSettings,
          }
          trigger.pushMessageCardObject.limitedStockLabel ||=
            '在庫残りわずかです'
          trigger.pushMessageCardObject.limitedStockMax ||= 1
        }
        trigger.errorMessages = getValidationMessages(trigger)
        return trigger
      })
      state.changeSaved = true
    },
    setChangeSaved: (state, action) => {
      state.changeSaved = action.payload
    },
    updateListTrigger: (state, action) => {
      const newTrigger = action.payload
      newTrigger.errorMessages = getValidationMessages(newTrigger)
      state.list = state.list.map(trigger =>
        trigger.id === newTrigger.id ? newTrigger : trigger
      )
      state.changeSaved = false
    },
    activateAllTriggers: state => {
      state.list = state.list.map(trigger => ({
        ...trigger,
        status: 'active',
      }))
      state.changeSaved = false
    },
  },
})

export default slice.reducer

export const {
  setList,
  setChangeSaved,
  updateListTrigger,
  activateAllTriggers,
} = slice.actions

const getValidationMessages = (trigger: PvRetargetingTrigger) => {
  const messages: string[] = []
  const { pushMessageCardObject } = trigger
  if (!pushMessageCardObject) return messages
  if (!pushMessageCardObject.title)
    messages.push('「ラベル」を入力してください')
  if (countCharacterLength(pushMessageCardObject.title || '') > 12)
    messages.push('「ラベル」の文字数が長すぎます（全角で最大12文字）')
  if (countCharacterLength(pushMessageCardObject.buttonLabel || '') > 12)
    messages.push('「ボタン」の文字数が長すぎます（全角で最大12文字）')
  if (
    pushMessageCardObject.cardType === 'item_with_coupon' &&
    !pushMessageCardObject.mainText
  )
    messages.push('「クーポン情報」の「クーポンコード」を入力してください')
  if (
    pushMessageCardObject.cardType === 'item_with_coupon' &&
    !pushMessageCardObject.imageLabel
  )
    messages.push('「クーポン情報」の「説明ラベル」を入力してください')
  if (pushMessageCardObject.limitedStockSetting) {
    if (!pushMessageCardObject.limitedStockLabel)
      messages.push('「在庫数でラベル切り替え」の「ラベル」を入力してください')
    if (
      countCharacterLength(pushMessageCardObject.limitedStockLabel || '') > 12
    )
      messages.push(
        '「在庫数でラベル切り替え」の「ラベル」の文字数が長すぎます（全角で最大12文字）'
      )
    if (
      !pushMessageCardObject.limitedStockMax ||
      pushMessageCardObject.limitedStockMax < 1
    )
      messages.push(
        '「在庫数でラベル切り替え」の表示条件で、在庫数は1以上を指定してください'
      )
  }
  return messages
}

// 全角は1文字、半角は0.5文字としてカウント
const countCharacterLength = (str: string) =>
  Math.ceil(
    str
      .split('')
      .map(c => (c.match(/^[^\x01-\x7E\xA1-\xDF]+$/) ? 1 : 0.5)) // eslint-disable-line no-control-regex
      .reduce((a, b) => a + b, 0)
  )

export function fetchPvRetargetingTriggers(company: Company) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/pv_retargeting_triggers`
      )
      dispatch(setList(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export function savePvRetargetingTriggers(
  company: Company,
  settingsActive: boolean
) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const pvRetargetingTriggersParams =
      getState().pvRetargetingTrigger.list.map(trigger => {
        const obj = {
          id: trigger.id,
          status: trigger.status,
          waitMinutes: trigger.waitMinutes,
          pushMessageCardObjectAttributes: trigger.pushMessageCardObject,
        }
        if (!settingsActive) {
          obj.status = 'inactive'
        }
        return obj
      })
    const data = snakecaseKeys({
      pvRetargetingTriggers: pvRetargetingTriggersParams,
      authenticityToken: $('#authenticity_token').val(),
    })
    const response = await axios.put(
      `/api/companies/${company.slug}/pv_retargeting_triggers/save_all`,
      data
    )
    dispatch(setList(response.data))
  }
}
