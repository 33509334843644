import React, { ChangeEvent } from 'react'
import { updateTrigger } from '../../stores/cartAbandonmentTrigger'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { RootState } from '../../stores'

const waitMinutesSettings: { hour: number; label: string }[] = [
  {
    hour: 1,
    label: 'カゴ落ち後まもなく（おすすめ）',
  },
  {
    hour: 22,
    label: '翌日の同じ時間帯',
  },
]

const WaitMinutesSelect = () => {
  const dispatch = useAppDispatch()
  const { trigger } = useAppSelector(
    (state: RootState) => state.cartAbandonmentTrigger
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (trigger) {
      const waitMinutes = Number(e.target.value)
      dispatch(updateTrigger({ ...trigger, waitMinutes }))
    }
  }

  if (!trigger) return <div />

  return (
    <div>
      {waitMinutesSettings.map(({ hour, label }) => {
        const min = hour * 60
        return (
          <div
            key={hour}
            className="uk-text-small uk-margin-small"
            style={{ color: '#333' }}
          >
            <label style={{ cursor: 'pointer' }}>
              <input
                type="radio"
                value={min}
                name="cart_abandonment_trigger-wait_minutes"
                checked={trigger.waitMinutes === min}
                onChange={handleChange}
                className="uk-radio uk-margin-small-right"
              />
              <span>{`${hour}時間後：${label}`}</span>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default WaitMinutesSelect
