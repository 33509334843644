import React, { useState } from 'react'
import styled from 'styled-components'
import { EmojiData, Picker } from 'emoji-mart'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'

type Props = {
  onSelect: (emoji: EmojiData) => void
  iconStyle?: React.CSSProperties
  modalStyle?: React.CSSProperties
}
const EmojiPicker = ({ onSelect, iconStyle, modalStyle }: Props) => {
  const [open, setOpen] = useState(false)

  const handleSelect = (emoji: EmojiData) => {
    onSelect(emoji)
    setOpen(false)
  }

  return (
    <Wrapper>
      <InsertEmoticonIcon
        onClick={() => setOpen(!open)}
        style={{
          color: '#999',
          cursor: 'pointer',
          ...iconStyle,
        }}
      />
      <Picker
        onSelect={handleSelect}
        i18n={{
          search: '検索',
          categories: {
            search: '検索結果',
            recent: 'よく使う絵文字',
            people: '顔 & 人',
            nature: '動物 & 自然',
            foods: '食べ物 & 飲み物',
            activity: 'アクティビティ',
            places: '旅行 & 場所',
            objects: 'オブジェクト',
            symbols: '記号',
            flags: '旗',
            custom: 'カスタム',
          },
        }}
        showPreview={false}
        showSkinTones={false}
        style={{
          position: 'absolute',
          display: open ? 'inline-block' : 'none',
          width: '302px',
          ...modalStyle,
        }}
      />
    </Wrapper>
  )
}
export default EmojiPicker

const Wrapper = styled.div`
  position: relative;
`
