import React, { useState, useEffect } from 'react'
import { RichMenuLayoutImages } from '../../const'

interface Props {
  imageUrl: string
  setTemplateId(templateId: string | null): void
  templateId: string | null
  isLarge: boolean | null
  action: string
}

const SelectRichmenuLayouts = ({
  imageUrl,
  setTemplateId,
  templateId,
  isLarge,
  action,
}: Props) => {
  const [clickedTemplateId, setClickedTemplateId] = useState<string | null>('')

  useEffect(() => {
    if (!clickedTemplateId) return
    const clickedTemplate = document.getElementById(clickedTemplateId)
    if (!clickedTemplate) return
    if (clickedTemplateId.length > 0) {
      clickedTemplate.style.cssText = ''
      setClickedTemplateId(null)
    }
  }, [isLarge])

  useEffect(() => {
    if (action === 'edit') {
      editActionFirstClicked()
    } else {
      if (imageUrl.length > 0 && !clickedTemplateId) {
        newActionFirstClicked()
      }
    }
  })

  const newActionFirstClicked = () => {
    if (isLarge === true) {
      const firstClickedLarge = document.getElementById('large_1')
      if (firstClickedLarge !== null) {
        firstClickedLarge.style.cssText =
          'border-style: solid; border-color: #00B900;'
        setClickedTemplateId(firstClickedLarge.getAttribute('id'))
        setTemplateId(firstClickedLarge.getAttribute('id'))
      }
    } else if (isLarge === false) {
      const firstClickedSmall = document.getElementById('small_1')
      if (firstClickedSmall !== null) {
        firstClickedSmall.style.cssText =
          'border-style: solid; border-color: #00B900;'
        setClickedTemplateId(firstClickedSmall.getAttribute('id'))
        setTemplateId(firstClickedSmall.getAttribute('id'))
      }
    }
  }

  const editActionFirstClicked = () => {
    if (!templateId) return
    const firstClickedEdit = document.getElementById(templateId)
    if (firstClickedEdit !== null) {
      firstClickedEdit.style.cssText =
        'border-style: solid; border-color: #00B900;'
      setClickedTemplateId(templateId)
      setTemplateId(templateId)
    }
  }

  const templateClickedTriggers = (
    e: React.MouseEvent<HTMLImageElement, PointerEvent>
  ) => {
    if (!clickedTemplateId) return
    const changeClieckedTarget = document.getElementById(clickedTemplateId)
    if (!changeClieckedTarget) return
    changeClieckedTarget.style.cssText = ''

    const target = e.target as HTMLImageElement
    target.style.cssText = 'border-style: solid; border-color: #00B900;'

    setClickedTemplateId(target.getAttribute('id'))
    setTemplateId(target.getAttribute('id'))
  }

  return (
    <>
      {imageUrl && (
        <>
          <a className="uk-h6 uk-text-bold">使用するレイアウトの選択</a>
          <br />
          <br />
          {RichMenuLayoutImages.map(templateImage => (
            <img
              src={templateImage['url']}
              id={templateImage['id']}
              key={templateImage['id']}
              width="120"
              height="120"
              className="uk-margin-small-right uk-margin-small-bottom"
              onClick={(e: React.MouseEvent<HTMLImageElement, PointerEvent>) =>
                templateClickedTriggers(e)
              }
            />
          ))}
        </>
      )}
    </>
  )
}

export default SelectRichmenuLayouts
