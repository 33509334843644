import React, { useState, useEffect } from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import { Company, Customer } from '../types'
import { shopCustomerName } from '../customerUtil'
import axios from 'axios'
import { fetchSelectedCustomer, fetchCustomers } from '../stores/customers'
import { useAppDispatch } from '../hooks'
import { fetchCustomerShopLogs } from '../stores/customerShopLogs'

interface Props {
  rootId: string
  company: Company
  customer: Customer
}

const LineConnectModal = ({ rootId, company, customer }: Props) => {
  const dispatch = useAppDispatch()
  const [Modal, openModal, closeModal] = useModal(rootId, {
    closeOnOverlayClick: false,
  })

  const [customerWithShop, setCustomerWithShop] = useState<{
    id: number
    name: string
  } | null>(null)

  const [customerWithLine, setCustomerWithLine] = useState<{
    id: number
    name: string
    chatId: string
  } | null>(null)

  useEffect(() => {
    setCustomerData()
  }, [customer])

  const displayModal = () => {
    setCustomerData()
    openModal()
  }

  const setCustomerData = () => {
    setCustomerWithShop(
      customer.shopCustomer
        ? { id: customer.id, name: shopCustomerName(customer) }
        : null
    )
    setCustomerWithLine(
      customer.customerLineAccount
        ? {
            id: customer.id,
            name: customer.customerLineAccount.displayName,
            chatId: customer.chatId,
          }
        : null
    )
  }

  const fetchCustomerData = () => {
    dispatch(fetchCustomers(company))
    dispatch(fetchSelectedCustomer(company, customer))
    dispatch(fetchCustomerShopLogs(company, customer))
  }

  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()

    const data = { authenticity_token: $('#authenticity_token').val() }
    if (customerWithLine && customerWithShop) {
      axios
        .post(
          `/api/companies/${company.slug}/customer_shop_line_connections`,
          Object.assign({}, data, {
            customer_shop_line_connection_form: {
              customer_with_shop_id: customerWithShop
                ? customerWithShop.id
                : null,
              customer_with_line_id: customerWithLine
                ? customerWithLine.id
                : null,
            },
          })
        )
        .then(_ => {
          if (!customer.customerLineAccount && customer.shopCustomer) {
            window.location.href = `/companies/${company.slug}/talk_rooms/${customerWithLine?.chatId}`
          } else {
            fetchCustomerData()
          }
          window.alert(
            'ショップ顧客情報とLINEアカウントの紐付けが完了しました。'
          )
        })
        .catch(() => {
          window.alert(
            'ショップ顧客情報とLINEアカウントの紐付けに失敗しました。'
          )
        })
    } else {
      axios
        .delete(
          `/api/companies/${company.slug}/customer_shop_line_connections/${customer.id}`,
          { data }
        )
        .then(_ => {
          fetchCustomerData()
          window.alert(
            'ショップ顧客情報とLINEアカウントの紐付けを解除しました。'
          )
        })
        .catch(() => {
          window.alert(
            'ショップ顧客情報とLINEアカウントの紐付けの解除に失敗しました。'
          )
        })
    }
  }

  const getOptions = (name: string, association: string) => {
    if (name) {
      return axios
        .get(
          `/api/companies/${company.slug}/customers?association=${association}&name=${name}`
        )
        .then(response => {
          const options = response.data.map(
            (customer: { id: number; name: string; chatId: string }) => ({
              name: customer.name,
              value: customer.id,
              chatId: customer.chatId,
            })
          )
          return options
        })
    } else {
      return new Promise(resolve => resolve([]))
    }
  }

  const closeModalConfirm = () => {
    if (
      !(isCustomerWithShopChanged || isCustomerWithLineChanged) ||
      window.confirm('紐付きを保存せずに閉じますか？')
    ) {
      closeModal()
    }
  }

  const isCustomerWithShopChanged = customer.shopCustomer
    ? customer.id !== customerWithShop?.id
    : customerWithShop !== null
  const isCustomerWithLineChanged = customer.customerLineAccount
    ? customer.id !== customerWithLine?.id
    : customerWithLine !== null

  return (
    <>
      <a onClick={displayModal}>
        {customer.customerLineAccount && customer.shopCustomer
          ? '紐付けを変更する'
          : customer.customerLineAccount
          ? '購入者情報と紐付ける'
          : 'LINEアカウントと紐付ける'}
      </a>
      <Modal>
        <ModalInner
          style={{ maxWidth: window.innerWidth - 30 }}
          className="uk-section"
        >
          <div className="uk-container uk-container-xsmall">
            <Label>LINEアカウント</Label>
            {customerWithLine ? (
              <SelectedCustomer className="uk-flex uk-flex-left">
                <div className="name-container">{customerWithLine.name}</div>
                <div className="button-container uk-flex-right">
                  {customerWithLine && !customer.customerLineAccount && (
                    <button
                      className="uk-button uk-button-default uk-button-small"
                      onClick={() => {
                        setCustomerWithLine(null)
                      }}
                    >
                      変更する
                    </button>
                  )}
                </div>
              </SelectedCustomer>
            ) : (
              <SelectSearch
                options={[]}
                getOptions={(name: string) => getOptions(name, 'only_line')}
                filterOptions={fuzzySearch}
                search
                placeholder="LINEのアカウント名を入力してください"
                onChange={(_: any, option: any) => {
                  setCustomerWithLine({
                    id: option.value,
                    name: option.name,
                    chatId: option.chatId,
                  })
                }}
                className="select-search uk-width-3-4@m"
              />
            )}
            <Label>ショップのお客さま</Label>
            {!customerWithShop && (
              <div className="uk-text-small uk-text-muted uk-text-left uk-margin-small-bottom">
                ※
                最終購入日がおみせコネクト連携前のお客様情報は表示されない場合がございます。
              </div>
            )}
            {customerWithShop ? (
              <SelectedCustomer className="uk-flex uk-flex-left">
                <div className="name-container">{customerWithShop.name}</div>
                <div className="button-container uk-flex-right">
                  {customer.customerLineAccount && (
                    <button
                      className="uk-button uk-button-default uk-button-small"
                      onClick={() => {
                        setCustomerWithShop(null)
                      }}
                    >
                      {isCustomerWithShopChanged ? '変更する' : '変更・解除'}
                    </button>
                  )}
                </div>
              </SelectedCustomer>
            ) : (
              <SelectSearch
                options={[]}
                getOptions={(name: string) => getOptions(name, 'only_shop')}
                filterOptions={fuzzySearch}
                search
                placeholder="ショップのお客様名を入力してください"
                onChange={(_: any, option: any) => {
                  setCustomerWithShop({
                    id: option.value,
                    name: option.name,
                  })
                }}
                className="select-search uk-width-3-4@m"
              />
            )}
            <div
              className="uk-text-center"
              style={{
                padding: '60px 0 20px',
              }}
            >
              <div>
                <button
                  type="submit"
                  className="uk-button uk-button-primary uk-margin-bottom"
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    handleSubmit(e)
                  }
                  disabled={
                    !isCustomerWithShopChanged && !isCustomerWithLineChanged
                  }
                >
                  {customer.customerLineAccount &&
                  customer.shopCustomer &&
                  customerWithShop === null
                    ? '紐付けを解除して保存する'
                    : '紐付けを保存する'}
                </button>
              </div>
              <div
                className="uk-button uk-button-text uk-padding-remove"
                onClick={closeModalConfirm}
              >
                {isCustomerWithShopChanged || isCustomerWithLineChanged
                  ? 'キャンセル'
                  : '閉じる'}
              </div>
            </div>
          </div>
        </ModalInner>
      </Modal>
    </>
  )
}

export default LineConnectModal

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`

const Label = styled.div`
  margin: 40px 0 20px;
  font-size: 18px;
  color: #333;
  text-align: left;
`

const SelectedCustomer = styled.div`
  box-sizing: border-box;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  width: 100%;
  align-items: center;
  padding: 20px 30px;
  border-radius: 5px;
  .name-container {
    flex: 1;
    text-align: left;
  }

  @media screen and (max-width: 600px) {
    padding: 20px 15px;

    .button-container {
      text-align: right;
      display: flex;
      flex-direction: column;
    }
    .uk-button-small {
      padding: 0 8px;
    }
  }
}`
