import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'
import { AppDispatch, RootState } from '.'
import {
  CardTypeMessage,
  CardTypeMessageContent,
  CardTypeMessageContentLabelStyles,
  Company,
} from '../types'
import _ from 'lodash'

interface cardTypeMessageState {
  cardTypeMessage: CardTypeMessage | null
  cardTypeMessageContents: CardTypeMessageContent[] | null
  cardTypeMessageContentLabelStyles: CardTypeMessageContentLabelStyles[]
  displayContentIndex: number | 'last'
}

const initialState: cardTypeMessageState = {
  cardTypeMessage: null,
  cardTypeMessageContents: null,
  cardTypeMessageContentLabelStyles: [],
  displayContentIndex: 0,
}

const slice = createSlice({
  name: 'cardTypeMessage',
  initialState,
  reducers: {
    setCardTypeMessage: (state, action) => {
      state.cardTypeMessage = action.payload
    },
    setCardTypeMessageContents: (state, action) => {
      state.cardTypeMessageContents = action.payload
    },
    setCardTypeMessageContentLabelStyles: (state, action) => {
      state.cardTypeMessageContentLabelStyles = action.payload
    },
    setDisplayContentIndex: (state, action) => {
      state.displayContentIndex = action.payload
    },
  },
})

export default slice.reducer

export const {
  setCardTypeMessage,
  setCardTypeMessageContents,
  setCardTypeMessageContentLabelStyles,
  setDisplayContentIndex,
} = slice.actions

type CardTypeMessageParams = {
  title?: string
  layout?: CardTypeMessage['layout']
}

export function saveCardTypeMessage(
  company: Company,
  cardTypeMessageId: number,
  cardTypeMessageParams: CardTypeMessageParams
) {
  return async function (dispatch: AppDispatch) {
    const data = snakecaseKeys({
      cardTypeMessage: cardTypeMessageParams,
      authenticityToken: $('#authenticity_token').val(),
    })
    const response = await axios.put(
      `/api/companies/${company.slug}/card_type_messages/${cardTypeMessageId}`,
      data
    )
    // TODO: layout更新後はすべてのカードが削除されたことを反映する必要あり
    dispatch(setCardTypeMessage(response.data))
  }
}

export function saveCardTypeMessageAllCards(formData: FormData) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const companySlug = getState().company.company?.slug
    const cardTypeMessageId = getState().cardTypeMessage.cardTypeMessage?.id
    if (!companySlug || !cardTypeMessageId) return

    const response = await axios.post(
      `/api/companies/${companySlug}/card_type_messages/${cardTypeMessageId}/save_all_cards`,
      formData
    )
    const cardTypeMessage = _.omit(
      response.data,
      'cardTypeMessageContents'
    ) as CardTypeMessage
    const {
      cardTypeMessageContents,
    }: { cardTypeMessageContents: CardTypeMessageContent[] } = response.data
    dispatch(setCardTypeMessage(cardTypeMessage))
    dispatch(setCardTypeMessageContents(cardTypeMessageContents))
  }
}

export function fetchCardTypeMessageContents(
  company: Company,
  cardTypeMessageId: number
) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/card_type_messages/${cardTypeMessageId}/card_type_message_contents`
      )
      dispatch(setCardTypeMessageContents(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}
