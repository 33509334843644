import React, { useState } from 'react'
import axios from 'axios'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

const CheckoutForm = ({
  company,
  fetchPaymentMethods,
  defaultPaymentMethodId,
}) => {
  const [displayForm, setDisplayForm] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState('')
  const [disabled, setDisabled] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const redirectUrl = new URL(
    decodeURIComponent(document.location.href)
  ).searchParams.get('redirect_url')

  const addPaymentMethod = paymentMethod => {
    const url = `/api/companies/${company.slug}/payment/payment_methods`
    const data = {
      authenticity_token: $('#authenticity_token').val(),
      payment_method_id: paymentMethod.id,
    }
    axios
      .post(url, data)
      .then(_response => {
        if (redirectUrl) {
          window.location.href = redirectUrl
          return
        }

        setError(null)
        setProcessing(false)
        fetchPaymentMethods()
        setDisplayForm(false)
        // NOTE: 本来はフォームをクリアする必要があるが、今回はコンポーネントごと消しているので不要
        // elements.getElement(CardElement).clear();
      })
      .catch(error => {
        if (error.response?.data?.error)
          setError(`カードの登録に失敗しました（${error.response.data.error}）`)
        setProcessing(false)
      })
  }

  const handleChange = async event => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements || processing) return

    setProcessing(true)
    const cardElement = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setError(`カードの登録に失敗しました（${error.message}）`)
      setProcessing(false)
    } else {
      addPaymentMethod(paymentMethod)
    }
  }

  return (
    <div
      className="uk-flex uk-flex-middle uk-flex-column"
      style={{ marginTop: 32 }}
    >
      {displayForm ? (
        <div className="uk-width-1-1 uk-width-2-3@s">
          <form id="stripe-payment-form" onSubmit={handleSubmit}>
            <CardElement
              id="card-element"
              options={cardStyle}
              onChange={handleChange}
            />
            <button disabled={processing || disabled || error} id="submit">
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  '登録する'
                )}
              </span>
            </button>
            {error && (
              <div className="card-error" role="alert">
                {error}
              </div>
            )}
            <div className="uk-text-center uk-margin-top">
              <div
                className="uk-button uk-button-text"
                onClick={() => setDisplayForm(false)}
              >
                キャンセル
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="uk-flex uk-flex-column uk-flex-middle">
          <div
            className="uk-button uk-button-primary"
            onClick={() => setDisplayForm(true)}
          >
            {defaultPaymentMethodId && '他の'}
            カードを追加する
          </div>
          <a
            className="uk-button uk-button-small uk-button-default uk-margin-top"
            href={`/companies/${company.slug}/payment`}
          >
            戻る
          </a>
        </div>
      )}
    </div>
  )
}

export default CheckoutForm

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}
