import React from 'react'
import { Company, RestockNotificationVariantData } from '../../types'
import dayjs from 'dayjs'
import SettingsSwitcher from './SettingsSwitcher'
import { ShopItemDefaultImage } from 'components/Dashboard/const'
import { Thumbnail } from '@shopify/polaris'

interface Props {
  company: Company
  restockNotificationVariantData: RestockNotificationVariantData[]
}

const SettingsTable = ({ company, restockNotificationVariantData }: Props) => {
  // lastCreatedAtがあるものを先に表示する
  // lastCreatedAtがあるものの中で、lastCreatedAtが新しいものを先に表示する
  const sortedRestockNotificationVariantData = restockNotificationVariantData
    .slice()
    .sort((a, b) => {
      if (a.lastCreatedAt && b.lastCreatedAt) {
        return dayjs(b.lastCreatedAt).diff(dayjs(a.lastCreatedAt))
      } else if (a.lastCreatedAt) {
        return -1
      } else if (b.lastCreatedAt) {
        return 1
      } else {
        return 0
      }
    })

  return (
    <div className="uk-overflow-auto">
      <table className="uk-table uk-table-striped uk-table-middle">
        <thead>
          <tr>
            <th style={{ minWidth: '60px' }}></th>
            <th style={{ minWidth: '120px' }}>商品</th>
            {company.shopAccountType === 'shopify' && (
              <th style={{ minWidth: '80px' }}>SKUコード</th>
            )}
            <th style={{ minWidth: '70px' }}>
              <div className="uk-flex uk-flex-center">未送信数</div>
            </th>
            <th style={{ minWidth: '70px' }}>
              <div className="uk-flex uk-flex-center">通知送信数</div>
            </th>
            <th style={{ minWidth: '70px' }}>
              <div className="uk-flex uk-flex-center">クリック数</div>
            </th>
            <th style={{ minWidth: '60px' }}>
              <div className="uk-flex uk-flex-center">注文数</div>
            </th>
            <th style={{ minWidth: '100px' }}>
              最終通知
              <br />
              リクエスト日
            </th>
            <th style={{ minWidth: '100px' }}>
              最終通知
              <br />
              送信日
            </th>
            {company.shopAccountType === 'shopify' && (
              <th style={{ minWidth: '80px' }}>
                通知登録
                <br />
                受付
              </th>
            )}
            {company.shopAccountType === 'shopify' && (
              <th style={{ minWidth: '80px' }}>通知送信</th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedRestockNotificationVariantData.map((row, index) => (
            <tr key={index}>
              <td className="uk-table-shrink uk-table-middle">
                <div className="uk-flex uk-flex-center">
                  <Thumbnail
                    source={row.imageUrl || ShopItemDefaultImage}
                    alt={row.productTitle}
                    size="small"
                  />
                </div>
              </td>
              <td className="uk-table-shrink">
                <div className="uk-flex uk-flex-column">
                  <div>{row.productTitle}</div>
                  <div className="uk-text-small uk-text-muted">
                    {row.variantTitle}
                  </div>
                </div>
              </td>
              {company.shopAccountType === 'shopify' && (
                <td className="uk-table-shrink">{row.sku || 'SKUなし'}</td>
              )}
              <td className="uk-table-shrink">
                <div className="uk-flex uk-flex-center">{row.unsentCount}</div>
              </td>
              <td className="uk-table-shrink">
                <div className="uk-flex uk-flex-center">{row.sentCount}</div>
              </td>
              <td className="uk-table-shrink">
                <div className="uk-flex uk-flex-center">{row.clickCount}</div>
              </td>
              <td className="uk-table-shrink">
                <div className="uk-flex uk-flex-center">{row.cvCount}</div>
              </td>
              <td className="uk-table-shrink">
                {row.lastCreatedAt
                  ? dayjs(row.lastCreatedAt).format('YYYY/MM/DD')
                  : '-'}
              </td>
              <td className="uk-table-shrink">
                {row.lastSentAt
                  ? dayjs(row.lastSentAt).format('YYYY/MM/DD')
                  : '-'}
              </td>
              {company.shopAccountType === 'shopify' && (
                <td className="uk-table-shrink">
                  <SettingsSwitcher
                    variantId={row.variantId}
                    company={company}
                    type="subscription"
                  />
                </td>
              )}
              {company.shopAccountType === 'shopify' && (
                <td className="uk-table-shrink">
                  <SettingsSwitcher
                    variantId={row.variantId}
                    company={company}
                    type="notification"
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {sortedRestockNotificationVariantData.length === 0 && (
        <div className="uk-text-small uk-margin-small-left">
          {company.shopAccountType === 'shopify'
            ? '通知リクエスト、個別の設定はありません。'
            : '通知リクエストはありません。'}
        </div>
      )}
    </div>
  )
}

export default SettingsTable
