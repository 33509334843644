import React from 'react'
import { useAppSelector } from '../../hooks'
import { RootState } from '../../stores'

interface Props {
  colorCode: string
  onChange: (newColorCode: string) => void
}

const ButtonColorSelector = ({ colorCode, onChange }: Props) => {
  const buttonThemes = useAppSelector(
    (state: RootState) => state.flexMessageButtonTheme.list
  )

  const handleSelect = (newColorCode: string) => {
    onChange(newColorCode)
  }

  return (
    <div className="uk-flex uk-flex-wrap">
      {buttonThemes.map(theme => {
        const isSelected = colorCode === theme.colorCode
        const textColorCode = {
          link: '42659a',
          primary: 'ffffff',
          secondary: '111111',
        }[theme.style]
        return (
          <div
            key={theme.colorCode}
            onClick={() => handleSelect(theme.colorCode)}
            style={{
              color: `#${textColorCode}`,
              background: `#${theme.colorCode}`,
              border: theme.colorCode === 'ffffff' ? '1px solid #ddd' : 'none',
              width: 32,
              height: 32,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              cursor: 'pointer',
              marginLeft: 5,
              marginBottom: 5,
              boxSizing: 'border-box',
            }}
          >
            {isSelected ? (
              <span style={{ width: 16 }} uk-icon="icon: check" />
            ) : (
              'A'
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ButtonColorSelector
