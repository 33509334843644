import React, { useEffect } from 'react'
import { Company } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setCompany } from '../../stores/company'
import { fetchRestockNotificationSetting } from 'components/Dashboard/stores/restockNotificationSetting'
import VariantListModal from './VariantListModal'
import {
  fetchRestockNotificationVariantData,
  setCurrentPage,
} from 'components/Dashboard/stores/restockNotificationVariantData'
import SettingsTable from './SettingsTable'
import ReactLoading from 'react-loading'

interface Props {
  company: Company
}

const App = ({ company }: Props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setCompany(company))
    dispatch(fetchRestockNotificationSetting(company))
    dispatch(fetchRestockNotificationVariantData(company))
  }, [])

  const {
    list: restockNotificationVariantData,
    initialized: restockNotificationVariantDataInitialized,
    loading,
    currentPage,
    totalPages,
  } = useAppSelector(state => state.restockNotificationVariantData)

  const loadMore = () => {
    dispatch(setCurrentPage(currentPage + 1))
    dispatch(fetchRestockNotificationVariantData(company))
  }

  return (
    <div id="app-root">
      <div className="uk-margin-small-top">
        {company.shopAccountType === 'shopify' && (
          <VariantListModal rootId={'app-root'} company={company} />
        )}
      </div>
      <div className="uk-section-xsmall uk-margin-medium-top">
        <h2 className="uk-h4">商品別一覧</h2>
        <div className="uk-text-small uk-text-muted uk-margin-small-bottom">
          {company.shopAccountType === 'shopify'
            ? '通知リクエストを受け付けた商品、ならびに、個別にON/OFF設定されている商品が一覧表示されます'
            : '通知リクエストを受け付けた商品が一覧表示されます'}
        </div>
        {restockNotificationVariantDataInitialized ? (
          <>
            <SettingsTable
              company={company}
              restockNotificationVariantData={restockNotificationVariantData}
            />
            {!loading && totalPages > currentPage && (
              <div className="uk-text-center uk-padding">
                <div
                  onClick={loadMore}
                  className="uk-button uk-button-small uk-button-default"
                >
                  さらに読み込む
                </div>
              </div>
            )}
            {loading && (
              <div className="uk-flex uk-flex-center uk-margin-large">
                <ReactLoading
                  type="spin"
                  color={'#00888d'}
                  height={100}
                  width={100}
                />
              </div>
            )}
          </>
        ) : (
          <div className="uk-flex uk-flex-center uk-margin-large">
            <ReactLoading
              type="spin"
              color={'#00888d'}
              height={100}
              width={100}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default App
