import React, { useEffect } from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'
import UIkit from 'uikit'
import { RootState } from '../../stores'
import { Company } from '../../types'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { ResponsiveBreakpoint } from '../../const'
import { setCompany } from '../../stores/company'
import {
  fetchRestockTrigger,
  saveRestockTrigger,
} from 'components/Dashboard/stores/restockTrigger'
import { fetchFlexMessageButtonThemes } from '../../stores/flexMessageButtonTheme'
import { fetchShopItems } from '../../stores/shopItem'

import TriggerStatusSwitch from './TriggerStatusSwitch'
import MessageEditor from './MessageEditor'

interface Props {
  company: Company
}

const App = ({ company }: Props) => {
  const dispatch = useAppDispatch()
  const { trigger, changeSaved } = useAppSelector(
    (state: RootState) => state.restockTrigger
  )

  useEffect(() => {
    dispatch(setCompany(company))
    dispatch(fetchRestockTrigger(company))
    dispatch(fetchShopItems(company))
    dispatch(fetchFlexMessageButtonThemes())
  }, [])

  const isTriggerValid = () => {
    if (!trigger) return false

    const { status, restockNotificationMessage } = trigger
    return (
      status === 'inactive' ||
      (restockNotificationMessage.notificationTitle &&
        restockNotificationMessage.notificationText &&
        restockNotificationMessage.subscribedTitle &&
        restockNotificationMessage.subscribedText)
    )
  }

  const handleSubmit = () => {
    if (changeSaved) return

    dispatch(saveRestockTrigger(company))
      .then(() => {
        UIkit.notification({
          message: "<span uk-icon='icon: check'></span> 設定を保存しました",
          pos: 'top-center',
          status: 'success',
        })
      })
      .catch(() => {
        UIkit.notification({
          message: 'システムエラーで保存ができませんでした',
          pos: 'top-center',
          status: 'danger',
        })
      })
  }

  return (
    <div id="app-root" className="uk-section-xsmall">
      <div className="uk-section-xsmall">
        <h2 className="uk-h4">配信レポート</h2>
        <a
          href={`/companies/${company.slug}/restock_trigger`}
          className="uk-button uk-button-default"
        >
          レポート詳細
        </a>
      </div>
      <div className="uk-section-xsmall">
        <h2 className="uk-h4">個別設定</h2>
        <a
          href={`/companies/${company.slug}/restock_notification_settings/variants`}
          className="uk-button uk-button-default"
        >
          商品別設定
        </a>
        <a
          href={`/companies/${company.slug}/talk_rooms?filter=restock_subscribed`}
          className="uk-button uk-button-default uk-margin-small-left"
          target="_blank"
          rel="noopener"
        >
          <div className="uk-flex uk-flex-middle">
            顧客別設定
            <div
              className="material-icons"
              style={{ fontSize: 16, marginLeft: 5 }}
            >
              open_in_new
            </div>
          </div>
        </a>
      </div>
      <div className="uk-section-xsmall">
        <h2 className="uk-h4">配信設定</h2>
        {trigger ? (
          <>
            <div className="uk-margin-bottom">
              <TriggerStatusSwitch />
              <div
                className="uk-text-small uk-text-muted"
                style={{ marginTop: 8 }}
              >
                ※ 再入荷通知ボタンがすべての在庫切れ商品に表示されます。
                {company.shopAccountType === 'shopify' && (
                  <>
                    <br />※
                    商品個別に設定を変更する場合は、「商品別設定」ボタンから設定を行います。
                  </>
                )}
              </div>
            </div>
            {trigger?.status === 'active' && (
              <div className="uk-section-xsmall">
                <h2 className="uk-h5 uk-margin-remove uk-text-bold">
                  メッセージ内容
                </h2>
                <div className="uk-section-xsmall">
                  <div
                    className="uk-label uk-margin-bottom"
                    style={{ padding: '3px 10px' }}
                  >
                    再入荷通知受付メッセージ
                  </div>
                  <div>
                    <MessageEditor restockMessageType="subscribed" />
                  </div>
                </div>
                <div className="uk-section-xsmall">
                  <div
                    className="uk-label uk-margin-bottom"
                    style={{ padding: '3px 10px' }}
                  >
                    再入荷時のメッセージ
                  </div>
                  <div>
                    <MessageEditor restockMessageType="notification" />
                  </div>
                </div>
              </div>
            )}
            <FormFooter>
              {isTriggerValid() ? (
                <button
                  onClick={handleSubmit}
                  className={`uk-button uk-button-large uk-button-${
                    changeSaved ? 'gray' : 'primary'
                  }`}
                  style={{
                    cursor: changeSaved ? 'inherit' : 'pointer',
                  }}
                >
                  変更を保存する
                </button>
              ) : (
                <>
                  <div className="uk-text-small uk-text-danger uk-margin-small-bottom">
                    入力内容に不備があります。
                  </div>
                  <button
                    className="uk-button uk-button-primary uk-button-large"
                    disabled={true}
                  >
                    変更を保存する
                  </button>
                </>
              )}
            </FormFooter>
          </>
        ) : (
          <div className="uk-margin-left">
            <ReactLoading
              type={'bubbles'}
              color={'#00888dbf'}
              height={80}
              width={80}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default App

const FormFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 300px;
  right: 0;
  text-align: center;
  background: #f8f8f8;
  padding: 16px;
  border-top: 1px solid #e5e5e5;

  @media screen and (max-width: ${ResponsiveBreakpoint.large - 1}px) {
    left: 260px;
  }

  @media screen and (max-width: ${ResponsiveBreakpoint.medium - 1}px) {
    left: 0;
  }
`
