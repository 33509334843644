import React from 'react'
import { Company } from '../../types'
import { Provider } from 'react-redux'
import App from './app'
import store from 'components/Dashboard/stores'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { jaJP } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/ja'

interface Props {
  company: Company
  liffUrl: string
}

const AddLineFriendTrackingList = ({ company, liffUrl }: Props) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ja"
      localeText={
        jaJP.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <Provider store={store}>
        <App company={company} liffUrl={liffUrl} />
      </Provider>
    </LocalizationProvider>
  )
}

export default AddLineFriendTrackingList
