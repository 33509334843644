import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { updateTrigger } from '../../stores/cartAbandonmentTrigger'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { RootState } from '../../stores'
import { CartAbandonmentMessage, Company } from 'components/Dashboard/types'
import { ResponsiveBreakpoint } from 'components/Dashboard/const'
import ButtonColorSelector from './ButtonColorSelector'

interface Props {
  company: Company
}

const CartAbandonmentMessageForm = ({ company }: Props) => {
  const dispatch = useAppDispatch()
  const { trigger } = useAppSelector(
    (state: RootState) => state.cartAbandonmentTrigger
  )

  if (!trigger) return <div />

  const setCartAbandonmentMessage = (
    cartAbandonmentMessage: CartAbandonmentMessage
  ) => {
    dispatch(updateTrigger({ ...trigger, cartAbandonmentMessage }))
  }

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value
    setCartAbandonmentMessage({ ...trigger.cartAbandonmentMessage, title })
  }

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value
    setCartAbandonmentMessage({ ...trigger.cartAbandonmentMessage, text })
  }

  const handleChangeButtonText = (e: ChangeEvent<HTMLSelectElement>) => {
    const buttonText = e.target.value
    setCartAbandonmentMessage({ ...trigger.cartAbandonmentMessage, buttonText })
  }

  const { title, text, buttonText } = trigger.cartAbandonmentMessage

  return (
    <div>
      {company.shopAccountType !== 'base' && (
        <FormRow className="uk-flex uk-margin-bottom">
          <FormLabel className="uk-width-1-6">タイトル</FormLabel>
          <div className="uk-flex-1">
            <input
              type="text"
              value={title || ''}
              onChange={handleChangeTitle}
              className="uk-input"
            />
          </div>
        </FormRow>
      )}
      {company.shopAccountType !== 'base' && (
        <FormRow className="uk-flex uk-margin-bottom">
          <FormLabel className="uk-width-1-6">本文</FormLabel>
          <div className="uk-flex-1">
            <textarea
              value={text || ''}
              onChange={handleChangeText}
              className="uk-textarea"
              rows={4}
            />
          </div>
        </FormRow>
      )}
      <FormRow className="uk-flex uk-margin-bottom">
        <FormLabel className="uk-width-1-6">ボタン</FormLabel>
        <div>
          <select
            className="uk-select"
            value={buttonText || 'カートを見る'}
            onChange={handleChangeButtonText}
          >
            {['カートを見る', 'カートを確認する', '購入手続きへ戻る'].map(
              text => (
                <option key={text} value={text}>
                  {text}
                </option>
              )
            )}
          </select>
          <div className="uk-margin-small-top">
            <ButtonColorSelector />
          </div>
        </div>
      </FormRow>
    </div>
  )
}

export default CartAbandonmentMessageForm

const FormRow = styled.div`
  @media screen and (max-width: 1099px) {
    flex-direction: column;
  }
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    flex-direction: row;
  }
`
const FormLabel = styled.div`
  width: 90px;
  flex: none;
  line-height: 40px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    width: 76px;
  }
`
