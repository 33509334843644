import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import store from '../../stores'
import { Company, RichMenuData } from '../../types'
import App from './App'
import axios from 'axios'

interface Props {
  company: Company
  action: string
  richMenuId: string
}

const RichMenuCreateForm = ({ company, action, richMenuId }: Props) => {
  const [richMenuData, setRichMenuData] = useState<RichMenuData | null>(null)
  useEffect(() => {
    if (action === 'edit') {
      getRichMenuData(richMenuId)
    }
  }, [])

  const getRichMenuData = (richMenuId: string) =>
    axios
      .get(
        `/api/companies/${company.slug}/rich_menu?rich_menu_id=${richMenuId}`
      )
      .then(response => {
        setRichMenuData(response.data)
      })

  return (
    <Provider store={store}>
      <App company={company} action={action} richMenuData={richMenuData} />
    </Provider>
  )
}

export default RichMenuCreateForm
