import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import { Company } from 'components/Dashboard/types'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import { fetchLineFollowTriggers } from 'components/Dashboard/stores/lineFollowTriggers'

interface Props {
  rootId: string
  company: Company
}

const RouteSelectModal = ({ rootId, company }: Props) => {
  const [Modal, openModal] = useModal(rootId, {
    closeOnOverlayClick: true,
  })
  const [checkedLineFollowTriggerId, setCheckedLineFollowTriggerId] = useState<
    number | null
  >(null)
  const dispatch = useAppDispatch()
  const { lineFollowTriggers } = useAppSelector(
    (state: RootState) => state.lineFollowTriggers['inactive']
  )

  const handleLineFollowTriggerChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedLineFollowTriggerId(Number(e.target.value))
  }

  useEffect(() => {
    dispatch(fetchLineFollowTriggers(company, 'inactive'))
  }, [])

  return (
    <>
      <button className="uk-button uk-button-primary" onClick={openModal}>
        新規作成
      </button>
      <Modal>
        <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
          <div className="uk-h4">経路別メッセージの作成</div>
          <div className="uk-section-xsmall">
            <div className="uk-h5 uk-margin-small-bottom">経路を選択</div>
            {lineFollowTriggers.length > 0 ? (
              <div className="uk-overflow-auto" style={{ height: 260 }}>
                <table className="uk-table uk-table-striped uk-table-middle uk-margin-xsmall-top">
                  <tbody>
                    {lineFollowTriggers.map(lineFollowTrigger => (
                      <tr key={lineFollowTrigger.addLineFriendTrackingId}>
                        <label>
                          <td>
                            <input
                              type="radio"
                              name="lineFollowTriggerId"
                              value={lineFollowTrigger.addLineFriendTrackingId}
                              onChange={handleLineFollowTriggerChange}
                            />
                          </td>
                          <td>
                            {lineFollowTrigger.addLineFriendTrackingTitle}
                          </td>
                        </label>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <hr />
                <div className="uk-text-small uk-text-muted">
                  選択できる経路はありません
                </div>
              </>
            )}
            <div className="uk-margin-top uk-text-center">
              <button
                className="uk-button uk-button-primary"
                disabled={checkedLineFollowTriggerId === null}
                onClick={() => {
                  window.location.href = `/companies/${company.slug}/company_line_account/line_follow_trigger_message?add_line_friend_tracking_id=${checkedLineFollowTriggerId}`
                }}
              >
                作成に進む
              </button>
            </div>
          </div>
        </ModalInner>
      </Modal>
    </>
  )
}

export default RouteSelectModal

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
