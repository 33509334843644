import React, { useState, useEffect, ChangeEvent } from 'react'
import axios from 'axios'
import ShopCategorySelect from '../../molecules/PushMessage/ShopCategorySelect'
import ShopItemSearchSelect from 'components/molecules/PushMessage/ShopItemSearchSelect'
import MakeshopItemSearchInput from 'components/molecules/PushMessage/MakeshopItemSearchInput'
import { Company } from 'types.ts/Company'
import { PushMessageTrigger } from 'types.ts/PushMessageTrigger'

type Props = {
  company: Company
  triggerType: PushMessageTrigger['triggerType']
  triggerValue: PushMessageTrigger['triggerValue']
  formType: 'order' | 'ship'
  setTriggerType: (type: PushMessageTrigger['triggerType']) => void
  setTriggerValue: (value: PushMessageTrigger['triggerValue']) => void
}

const TriggerOrderTypeForm = ({
  company,
  triggerType,
  triggerValue,
  formType,
  setTriggerType,
  setTriggerValue,
}: Props) => {
  const [itemId, setItemId] = useState<string>('')
  const [categoryId, setCategoryId] = useState('')
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    fetchShopCategories()
  }, [])

  useEffect(() => {
    if (triggerValue) {
      if (triggerType === 'ordered_item' && itemId !== triggerValue) {
        setItemId(triggerValue)
      } else if (
        triggerType === 'ordered_category' &&
        categoryId !== triggerValue
      ) {
        setCategoryId(triggerValue)
      } else if (triggerType === 'shipped_item' && itemId !== triggerValue) {
        setItemId(triggerValue)
      } else if (
        triggerType === 'shipped_category' &&
        categoryId !== triggerValue
      ) {
        setCategoryId(triggerValue)
      }
    }
  }, [triggerType])

  const fetchShopCategories = () => {
    axios
      .get(`/api/companies/${company.slug}/shop_categories`)
      .then(response => {
        setCategoryList(response.data)
      })
  }

  const changeItemId = (id: string) => {
    setItemId(id)
    setTriggerValue(id)
    if (formType === 'order' && triggerType !== 'ordered_item')
      setTriggerType('ordered_item')
    if (formType === 'ship' && triggerType !== 'shipped_item')
      setTriggerType('shipped_item')
  }

  const changeCategoryId = (id: string) => {
    setCategoryId(id)
    setTriggerValue(id)
    if (formType === 'order' && triggerType !== 'ordered_category')
      setTriggerType('ordered_category')
    if (formType === 'ship' && triggerType !== 'shipped_category')
      setTriggerType('shipped_category')
  }

  const handleChangeTriggerType = (
    newTriggerType: PushMessageTrigger['triggerType']
  ) => {
    if (newTriggerType !== triggerType) {
      setTriggerType(newTriggerType)
      if (['ordered_item', 'shipped_item'].includes(newTriggerType)) {
        setTriggerValue(itemId)
      } else if (
        ['ordered_category', 'shipped_category'].includes(newTriggerType)
      ) {
        setTriggerValue(categoryId)
      } else {
        setTriggerValue('')
      }
    }
  }

  const RadioButton = ({
    value,
  }: {
    value: PushMessageTrigger['triggerType']
  }) => (
    <input
      type="radio"
      value={value}
      name="trigger-type"
      checked={triggerType === value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const selectedTriggerType = e.target
          .value as PushMessageTrigger['triggerType']
        handleChangeTriggerType(selectedTriggerType)
      }}
      className="uk-radio"
      style={{ margin: '0 5px 0 0' }}
    />
  )

  return (
    <div className="uk-section-xsmall">
      <div className="uk-form-label uk-text-bold">
        対象となる商品
        <span className="required-icon">必須</span>
      </div>
      <div className="uk-margin-small">
        <label className="uk-flex uk-flex-middle">
          <RadioButton
            value={formType === 'order' ? 'all_order' : 'order_shipped'}
          />
          <div className="uk-text-small">すべての商品</div>
        </label>
      </div>
      <div className="uk-margin-small">
        <label className="uk-flex uk-flex-middle uk-margin-small-bottom">
          <RadioButton
            value={formType === 'order' ? 'ordered_item' : 'shipped_item'}
          />
          <div className="uk-text-small">商品を指定</div>
        </label>
        {['ordered_item', 'shipped_item'].includes(triggerType) && (
          <div
            className={`uk-margin-left uk-width-1-2@s ${
              itemId ? 'uk-margin-bottom' : ''
            }`}
            style={{ maxWidth: 'calc(100% - 20px)' }}
          >
            {company.shopAccountType === 'makeshop' ? (
              <MakeshopItemSearchInput
                itemId={itemId}
                setItemId={changeItemId}
                company={company}
              />
            ) : (
              <ShopItemSearchSelect
                value={itemId}
                setValue={changeItemId}
                company={company}
              />
            )}
          </div>
        )}
      </div>
      <div className="uk-margin-small">
        <label className="uk-flex uk-flex-middle uk-margin-small-bottom">
          <RadioButton
            value={
              formType === 'order' ? 'ordered_category' : 'shipped_category'
            }
          />
          <div className="uk-text-small">カテゴリーを指定</div>
        </label>
        <div className="uk-margin-left uk-width-2-3 uk-width-1-2@s">
          {['ordered_category', 'shipped_category'].includes(triggerType) && (
            <ShopCategorySelect
              value={categoryId}
              setValue={changeCategoryId}
              categoryList={categoryList}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TriggerOrderTypeForm
