import React, { useRef, useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { Img } from 'react-image'
import ReactLoading from 'react-loading'
import _ from 'lodash'
import dayjs from 'dayjs'
import Linkify from 'linkify-react'
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import LastPageIcon from '@mui/icons-material/LastPage'
import FirstPageIcon from '@mui/icons-material/FirstPage'

import { useAppSelector, useAppDispatch, useWindowSize } from '../hooks'
import { RootState } from '../stores'
import { displayName } from '../customerUtil'
import { Message, Customer, MessageSenderType, Company } from '../types'
import ChatForm from './ChatForm'
import {
  fetchChatMessages,
  fetchOldChatMessages,
  setAllMessageFetched,
  setMessages,
} from '../stores/chat'
import {
  setSelectedCustomer,
  toggleSupportStatus,
  updateToAllChatRead,
} from '../stores/customers'
import { PC_MIN_WIDTH } from '../const'
import { setDisplayProfile } from '../stores/layout'
import CustomerProfileImage from './CustomerProfileImage'
import Video from './MessageBodyElements/Video'
import Sticker from './MessageBodyElements/Sticker'
import UnsupportedMessage from './MessageBodyElements/UnsupportedMessage'

interface Props {
  company: Company
  openProfileModal: () => void
  customer: Customer
}

let lastMessageTimestamp: number | null

const ChatArea = ({ company, openProfileModal, customer }: Props) => {
  const dispatch = useAppDispatch()
  const [windowWidth] = useWindowSize()
  const isPcLayout = windowWidth >= PC_MIN_WIDTH
  const scrollBottomRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const { isAdmin } = useAppSelector((state: RootState) => state.company)
  const { messages, loading, allMessageFetched } = useAppSelector(
    (state: RootState) => state.chat
  )
  const { displayProfile } = useAppSelector((state: RootState) => state.layout)
  const [footerHeight, setFooterHeight] = useState(64)

  useEffect(() => {
    dispatch(setMessages([]))
    lastMessageTimestamp = null
    dispatch(setAllMessageFetched(false))
    dispatch(fetchChatMessages(company, customer))
  }, [customer?.id])

  useEffect(() => {
    if (
      messages.length > 0 &&
      customer &&
      customer.chatReadStatus !== 'all_chat_read'
    ) {
      dispatch(updateToAllChatRead(company, customer))
    }
  }, [messages])

  useLayoutEffect(() => {
    const lastMessage = messages[messages.length - 1]
    if (
      scrollBottomRef?.current &&
      lastMessage &&
      lastMessageTimestamp !== lastMessage.timestamp
    ) {
      scrollBottomRef.current.scrollIntoView()
      lastMessageTimestamp = lastMessage.timestamp
    }
  }, [messages])

  const clearSelectedCustomer = (company: Company) => {
    dispatch(setSelectedCustomer(null))
    history.pushState(
      '',
      '',
      `${isAdmin ? '/admin' : ''}/companies/${company.slug}/talk_rooms`
    )
  }

  const displayProfileModal = () => {
    openProfileModal()
  }

  const toggleDisplayProfile = () => {
    dispatch(setDisplayProfile(!displayProfile))
  }

  const dateGroupedMessages = _.groupBy(messages, message => {
    return dayjs(message.timestamp).format('YYYY/MM/DD')
  })

  const handleScrollMessageList = (e: React.UIEvent<HTMLDivElement>) => {
    // FIXME: for fixing type
    const target = e.target as HTMLDivElement
    const { scrollHeight, scrollTop } = target
    if (!loading && !allMessageFetched && scrollTop / scrollHeight < 0.4) {
      dispatch(fetchOldChatMessages(company, customer))
    }
  }

  const handleChangeFormText = () => {
    const element = footerRef.current
    if (element && element.clientHeight !== footerHeight) {
      setFooterHeight(element.clientHeight)
    }
  }

  const handleClickSupportStatusButton = () => {
    dispatch(toggleSupportStatus(company, customer)).catch(() => {
      window.alert(
        '変更内容の保存ができませんでした。ページを再読み込みしていただくか、時間をあけてから再度お試しください。'
      )
    })
  }

  return (
    <Wrapper>
      <Header>
        <HeaderProfile>
          {isPcLayout ? (
            <CustomerProfileImage
              customer={customer}
              style={{
                objectFit: 'cover',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                marginRight: '10px',
                flex: 'none',
              }}
              requiredLineBadge={false}
            />
          ) : (
            <ArrowBackIosSharpIcon
              style={{
                fontSize: 20,
                padding: 10,
                marginLeft: -10,
                cursor: 'pointer',
                boxSizing: 'content-box',
              }}
              onClick={() => clearSelectedCustomer(company)}
            />
          )}
          <ProfileName
            onClick={isPcLayout ? toggleDisplayProfile : displayProfileModal}
          >
            <h2>{displayName(customer)}</h2>
            {!isPcLayout && <ArrowDropDownOutlined style={{ fontSize: 30 }} />}
          </ProfileName>
        </HeaderProfile>
        {customer.customerLineAccount && (
          <ActionRequiredButton
            active={customer.supportStatus === 'action_required'}
            onClick={handleClickSupportStatusButton}
          >
            要対応
          </ActionRequiredButton>
        )}
      </Header>
      {!company.companyLineAccount.isWebhookSet && (
        <AlertMessage>
          「メッセージの受信設定」が完了していないためお客様が送信したメッセージは表示されません。
        </AlertMessage>
      )}
      <MessageList
        onScroll={handleScrollMessageList}
        style={{ paddingBottom: isPcLayout ? 0 : footerHeight }}
        customerLineAccount={customer.customerLineAccount}
      >
        {loading && messages.length === 0 && (
          <ReactLoading
            className="uk-margin-auto uk-margin-medium-top uk-margin-medium-bottom"
            type={'spin'}
            color={'#00888d'}
            height={60}
            width={60}
          />
        )}
        {_.keys(dateGroupedMessages)
          .sort()
          .map(date => {
            const list = dateGroupedMessages[date] || []
            return (
              <div key={date}>
                <div className="uk-text-center">
                  <SendDateText>{date}</SendDateText>
                </div>
                {list.map(message => {
                  const sentDate = new Date(message.timestamp)
                  return (
                    <div key={message.timestamp}>
                      <MessageWrapper
                        sender={message.sender}
                        unsentAt={message.unsentAt}
                      >
                        {message.sender === 'customer' && !message.unsentAt && (
                          <CustomerProfileImage
                            customer={customer}
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              marginRight: '8px',
                            }}
                            requiredLineBadge={false}
                          />
                        )}
                        <MessageBody>
                          {messageBodyElement(message, customer)}
                        </MessageBody>
                        {!message.unsentAt && (
                          <MessageSentTime>
                            {sentDate.getHours()}:
                            {String(sentDate.getMinutes()).padStart(2, '0')}
                          </MessageSentTime>
                        )}
                      </MessageWrapper>
                    </div>
                  )
                })}
              </div>
            )
          })}
        <div ref={scrollBottomRef} />
      </MessageList>
      <Footer ref={footerRef}>
        <ChatForm
          onChangeText={handleChangeFormText}
          customer={customer}
          company={company}
        />
      </Footer>
      {customer.customerLineAccount && (
        <ProfileToggleButton
          onClick={() => dispatch(setDisplayProfile(!displayProfile))}
        >
          <div
            className="hint--left hint--rounded"
            aria-label={`プロフィールを${displayProfile ? '閉じる' : '開く'}`}
          >
            {displayProfile ? <LastPageIcon /> : <FirstPageIcon />}
          </div>
        </ProfileToggleButton>
      )}
    </Wrapper>
  )
}

export default ChatArea

const flexTypes = [
  'pv_retargeting',
  'cart_abandonment',
  'restock_notification',
  'restock_subscribed',
  'card_type_message',
]
const flexMessageTitle = {
  pv_retargeting: '閲覧商品リマインド配信',
  cart_abandonment: 'カゴ落ちリマインド配信',
  restock_notification: '再入荷通知',
  restock_subscribed: '再入荷通知受付メッセージ',
  card_type_message: 'カードタイプメッセージ',
}

const messageBodyElement = (message: Message, customer: Customer) => {
  const { type, timestamp, sender, value, uri, unsentAt, flexType, imageUrls } =
    message
  const { customerLineAccount } = customer
  if (unsentAt && sender === 'customer') {
    return (
      <UnsendMessage>
        {customerLineAccount.displayName}がメッセージの送信を取り消しました
      </UnsendMessage>
    )
  }
  const element = {
    text: (
      <TextMessage sender={sender}>
        <Linkify options={{ target: '_blank' }}>{value}</Linkify>
      </TextMessage>
    ),
    image: <Img src={value || ''} loader={<ImageLoading key={timestamp} />} />,
    flex:
      flexType && flexTypes.includes(flexType) ? (
        <div className="uk-flex uk-flex-column uk-flex-bottom">
          <UnsupportedMessage
            children={`${
              flexMessageTitle[flexType as keyof typeof flexMessageTitle]
            }が送信されました`}
          />
          <div className="uk-flex uk-flex-right">
            {imageUrls.map(url => (
              <TriggerMessageItemImg key={url} src={url} />
            ))}
          </div>
        </div>
      ) : (
        // リンク付き画像
        <a href={uri || ''} target="_blank">
          <Img src={value || ''} loader={<ImageLoading key={timestamp} />} />
        </a>
      ),
    video: <Video videoUrl={value} />,
    audio: <UnsupportedMessage children="音声が送信されました" />,
    file: <UnsupportedMessage children="ファイルが送信されました" />,
    location: <UnsupportedMessage>位置情報が送信されました</UnsupportedMessage>,
    sticker: <Sticker value={value} />,
    template: (
      <UnsupportedMessage
        children={`テンプレートメッセージ「${value}」が送信されました`}
      />
    ),
  }[type]
  return element
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  min-width: 0;
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    height: auto;
  }
`
const MessageList = styled.div<{
  customerLineAccount: Customer['customerLineAccount'] | null
}>`
  box-sizing: border-box;
  flex: 1;
  overflow-y: scroll;
  padding: 20px 40px 0 16px;
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    padding: 84px 16px 64px;
    min-height: 100vh;
    min-height: 100svh; // iPhone+Safari対策
  }
  background-color: ${({ customerLineAccount }) =>
    customerLineAccount ? 'inherit' : 'rgba(0, 0, 0, 0.65)'};
`
const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  z-index: 1;
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
  }
`
const AlertMessage = styled.div`
  box-sizing: border-box;
  padding: 10px;
  z-index: 1;
  background: #fee6e6;
  color: #ed5671;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
  }
`
const Footer = styled.div`
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    min-height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
  }
`
const HeaderProfile = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`
const ProfileName = styled.div`
  display: flex;
  min-width: 0;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
  h2 {
    font-size: 16px;
    margin: 0 4px;
    padding-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const ActionRequiredButton = styled.div<{ active: boolean }>`
  height: 32px;
  line-height: 30px;
  font-size: 13px;
  border-radius: 20px;
  padding: 0 25px;
  border: 1px solid;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#f0506e' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#00888d')};
  border-color: ${({ active }) => (active ? 'transparent' : '#00888d')};
  flex: none;
`
const MessageWrapper = styled.div<{
  sender: MessageSenderType
  unsentAt: number
}>`
  display: flex;
  align-items: flex-end;
  flex-direction: ${({ sender }) =>
    sender === 'customer' ? 'row' : 'row-reverse'};
  margin: 20px 0;
  justify-content: ${({ unsentAt }) => (unsentAt ? 'center' : '')};
`

const MessageBody = styled.div`
  img {
    max-height: 400px;
  }
`
const MessageSentTime = styled.div`
  color: #999;
  font-size: 11px;
  margin: 0 8px;
`
const TextMessage = styled.div<{ sender: MessageSenderType }>`
  font-size: 14px;
  background-color: ${({ sender }) =>
    sender === 'customer' ? '#f2f3f5' : '#b8ebb8'};
  border-radius: 8px;
  padding: 8px 12px;
  white-space: pre-line;
  word-break: break-all;
`
const UnsendMessage = styled.span`
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 12px;
`

const ImageLoading = styled.div`
  width: 100%;
  height: 400px;
  background-color: #999;
`
const SendDateText = styled.span`
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 12px;
`
const ProfileToggleButton = styled.div`
  position: absolute;
  right: 0;
  top: calc(50vh - 40px);
  height: 80px;
  width: 30px;
  background-color: #f3f3f3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: none;
  cursor: pointer;
  > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    display: none;
  }
`
const TriggerMessageItemImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin: 4px 2px 0;
`
