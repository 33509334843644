const Util = {
  formatDate: (dt, separator = '') => {
    const y = dt.getFullYear()
    const m = ('00' + (dt.getMonth() + 1)).slice(-2)
    const d = ('00' + dt.getDate()).slice(-2)
    return y + separator + m + separator + d
  },
  addDaysToDate: (date, days) => {
    const dt = new Date(date)
    dt.setDate(dt.getDate() + days)
    return dt
  },
}

export default Util
