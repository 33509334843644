import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import { sendChatMessage } from '../stores/chat'
import { useAppDispatch } from '../hooks'
import { Company, Customer } from '../types'

interface Props {
  rootId: string
  imageFile: null | File
  company: Company
  customer: Customer
  setImageFile(imageFile: null | File): void
}

const ImageModal = ({
  rootId,
  imageFile,
  company,
  customer,
  setImageFile,
}: Props) => {
  const dispatch = useAppDispatch()
  const [Modal, openModal, closeModal] = useModal(rootId, {
    closeOnOverlayClick: false,
  })

  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (imageFile) {
      openModal()
      const reader = new FileReader()
      reader.onload = e => {
        if (e.target) {
          setImageUrl(e.target.result as string)
        }
      }
      reader.readAsDataURL(imageFile)
    } else {
      setImageUrl('')
    }
  }, [imageFile])

  const sendMessage = (messageType: string, value: string | File) => {
    dispatch(sendChatMessage(company, customer, messageType, value))
    closeModal()
    setImageFile(null)
  }

  const handleSubmit = () => {
    if (imageFile) sendMessage('image', imageFile)
  }

  const handleCancel = () => {
    closeModal()
    setImageFile(null)
  }

  return (
    <>
      <Modal>
        <ModalInner
          style={{ maxWidth: window.innerWidth - 30 }}
          className="uk-section"
        >
          {imageUrl && (
            <div className="uk-text-center">
              <ImagePreview src={imageUrl} />
            </div>
          )}
          <div className="uk-text-center uk-margin-medium-top">
            <button
              className="uk-button uk-button-primary"
              onClick={handleSubmit}
            >
              送信
            </button>
          </div>
          <div className="uk-text-center uk-margin-top">
            <div
              className="uk-button uk-button-text uk-padding-remove"
              onClick={handleCancel}
            >
              キャンセル
            </div>
          </div>
        </ModalInner>
      </Modal>
    </>
  )
}

export default ImageModal

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
const ImagePreview = styled.img`
  max-height: 200px;
`
