import React, { useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination'
import { Company } from '../../types'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import { fetchLineFollowTriggers } from 'components/Dashboard/stores/lineFollowTriggers'

interface Props {
  company: Company
}

const countPerPage = 20

const ActiveFollowTriggersTable = ({ company }: Props) => {
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const { lineFollowTriggers, lineFollowTriggerTotalCount } = useAppSelector(
    (state: RootState) => state.lineFollowTriggers['active']
  )

  useEffect(() => {
    dispatch(fetchLineFollowTriggers(company, 'active', page, countPerPage))
  }, [page])

  return (
    <>
      <h1 className="uk-h5">経路別のメッセージが配信される経路</h1>
      <table className="uk-table uk-table-striped uk-table-middle uk-margin-xsmall-top">
        <thead>
          <tr>
            <th>経路名</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {lineFollowTriggers.map(lineFollowTrigger => (
            <tr key={lineFollowTrigger.addLineFriendTrackingId}>
              <td>{lineFollowTrigger.addLineFriendTrackingTitle}</td>
              <td className="uk-table-shrink" style={{ minWidth: 60 }}>
                <a
                  href={`/companies/${company.slug}/company_line_account/line_follow_trigger_message?add_line_friend_tracking_id=${lineFollowTrigger.addLineFriendTrackingId}`}
                  className="uk-button uk-button-default uk-button-small"
                >
                  編集
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        className="uk-flex uk-flex-center"
        count={Math.ceil(lineFollowTriggerTotalCount / countPerPage)}
        page={page}
        onChange={(_, value) => {
          setPage(value)
        }}
      />
    </>
  )
}

export default ActiveFollowTriggersTable
