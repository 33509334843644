import { createSlice } from '@reduxjs/toolkit'
import { Company, LineFollowTrigger } from '../types'
import axios from 'axios'
import { AppDispatch } from '.'
import snakecaseKeys from 'snakecase-keys'

interface LineFollowTriggerState {
  lineFollowTriggersActive: boolean
  active: {
    lineFollowTriggers: LineFollowTrigger[]
    lineFollowTriggerTotalCount: number
  }
  inactive: {
    lineFollowTriggers: LineFollowTrigger[]
    lineFollowTriggerTotalCount: number
  }
}

const initialState: LineFollowTriggerState = {
  lineFollowTriggersActive: false,
  active: {
    lineFollowTriggers: [],
    lineFollowTriggerTotalCount: 0,
  },
  inactive: {
    lineFollowTriggers: [],
    lineFollowTriggerTotalCount: 0,
  },
}

const slice = createSlice({
  name: 'lineFollowTriggers',
  initialState,
  reducers: {
    setLineFollowTriggersActive: (state, action) => {
      state.lineFollowTriggersActive =
        action.payload.lineFollowTriggerMessageConfig?.status || false
    },
    setLineFollowTriggers: (state, action) => {
      state[action.payload.status as 'active' | 'inactive'] = {
        lineFollowTriggers: action.payload.lineFollowTriggers,
        lineFollowTriggerTotalCount: action.payload.totalCount,
      }
    },
  },
})

export default slice.reducer

export const { setLineFollowTriggers, setLineFollowTriggersActive } =
  slice.actions

export function fetchLineFollowTriggersConfig(company: Company) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/line_follow_trigger_message_config`
      )
      dispatch(setLineFollowTriggersActive(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export function updateLineFollowTriggersConfig(
  company: Company,
  active: boolean
) {
  return async function (dispatch: AppDispatch) {
    try {
      const data = snakecaseKeys({
        lineFollowTriggerMessageConfig: { status: active },
        authenticityToken: $('#authenticity_token').val(),
      })
      await axios.put(
        `/api/companies/${company.slug}/line_follow_trigger_message_config`,
        data
      )
      dispatch(
        setLineFollowTriggersActive({
          lineFollowTriggerMessageConfig: { status: active },
        })
      )
    } catch (error) {
      console.log(error)
    }
  }
}

type fetchLineFollowTriggersRequestParams = {
  status: 'active' | 'inactive'
  page?: number
  per_page?: number
}

export function fetchLineFollowTriggers(
  company: Company,
  status: 'active' | 'inactive',
  page: number | null = null,
  countPerPage: number | null = null
) {
  return async function (dispatch: AppDispatch) {
    const params: fetchLineFollowTriggersRequestParams = { status }
    if (page) params['page'] = page
    if (countPerPage) params['per_page'] = countPerPage
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/line_follow_triggers`,
        {
          params,
        }
      )
      dispatch(setLineFollowTriggers({ ...response.data, status }))
    } catch (error) {
      console.log(error)
    }
  }
}
