import React, { useEffect } from 'react'
import ReactLoading from 'react-loading'
import UIkit from 'uikit'
import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useBeforeunload } from 'react-beforeunload'
import { RootState } from '../../stores'
import { Company } from '../../types'
import {
  setCompany,
  fetchMessageDeliveryConfig,
  saveMessageDeliveryConfig,
} from '../../stores/company'
import {
  useAppSelector,
  useAppDispatch,
  useResponsiveWindowSize,
} from '../../hooks'
import {
  fetchCartAbandonmentTrigger,
  saveCartAbandonmentTrigger,
  updateTrigger,
} from '../../stores/cartAbandonmentTrigger'
import { fetchFlexMessageButtonThemes } from '../../stores/flexMessageButtonTheme'
import { fetchShopItems } from 'components/Dashboard/stores/shopItem'
import MessageDeliveryConfigForm from '../shared/MessageDeliveryConfigForm'
import BrowserConnectedCount from '../shared/BrowserConnectedCount'
import Switch from '../shared/Switch'
import { ResponsiveBreakpoint } from '../../const'
import WaitMinutesSelect from './WaitMinutesSelect'
import CartAbandonmentMessageForm from './CartAbandonmentMessageForm'
import CartAbandonmentMessagePreview from './CartAbandonmentMessagePreview'
import SendingTestModal from './SendingTestModal'

interface Props {
  company: Company
}

const App = ({ company }: Props) => {
  const dispatch = useAppDispatch()
  const { trigger } = useAppSelector(
    (state: RootState) => state.cartAbandonmentTrigger
  )
  const triggerChangeSaved = useAppSelector(
    (state: RootState) => state.cartAbandonmentTrigger.changeSaved
  )
  const { messageDeliveryConfigChangeSaved } = useAppSelector(
    (state: RootState) => state.company
  )
  const responsiveWindowSize = useResponsiveWindowSize()
  const isSaveButtonActive =
    !triggerChangeSaved || !messageDeliveryConfigChangeSaved

  useBeforeunload(event => {
    if (!triggerChangeSaved || !messageDeliveryConfigChangeSaved) {
      event.preventDefault()
    }
  })

  useEffect(() => {
    dispatch(setCompany(company))
    dispatch(fetchCartAbandonmentTrigger(company))
    dispatch(fetchMessageDeliveryConfig(company))
    dispatch(fetchShopItems(company))
    dispatch(fetchFlexMessageButtonThemes())
  }, [])

  const handleChangeTriggerStatus = () => {
    if (trigger) {
      const status = trigger.status === 'active' ? 'inactive' : 'active'
      dispatch(updateTrigger({ ...trigger, status }))
    }
  }

  const handleSubmit = () => {
    if (!isSaveButtonActive) return

    dispatch(saveMessageDeliveryConfig(company))
    dispatch(saveCartAbandonmentTrigger(company))
      .then(() => {
        UIkit.notification({
          message: "<span uk-icon='icon: check'></span> 設定を保存しました",
          pos: 'top-center',
          status: 'success',
        })
      })
      .catch(() => {
        UIkit.notification({
          message: 'システムエラーで保存ができませんでした',
          pos: 'top-center',
          status: 'danger',
        })
      })
  }

  const isTriggerValid = () => {
    if (!trigger) return false

    const { status, cartAbandonmentMessage } = trigger
    const { title, text } = cartAbandonmentMessage
    return status === 'inactive' || (title && text)
  }

  return (
    <div id="app-root">
      <div className="uk-margin-medium-top">
        <div className="uk-h5 uk-margin-remove">
          カゴ落ちリマインド配信が可能な友だち数
        </div>
        <div className="uk-margin-small-top">
          <BrowserConnectedCount company={company} />
        </div>
      </div>
      <div className="uk-section-small">
        <h2 className="uk-h4">配信レポート</h2>
        <a
          href={`/companies/${company.slug}/cart_abandonment_trigger`}
          className="uk-button uk-button-default"
        >
          レポート詳細
        </a>
      </div>
      <div className="uk-section-xsmall">
        <h2 className="uk-h4">配信設定を行う</h2>
        {trigger ? (
          <div>
            <div className="uk-margin-bottom">
              <div className="uk-margin-medium-bottom">
                <label>
                  <div className="uk-flex uk-flex-middle">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={trigger.status === 'active'}
                          onChange={handleChangeTriggerStatus}
                        />
                      }
                      label="「カゴ落ちリマインド配信」を有効にする"
                    />
                  </div>
                </label>
              </div>
            </div>
            {trigger.status === 'active' && (
              <>
                <div className="uk-margin-medium-bottom">
                  <div className="uk-h5 uk-text-bold">配信タイミング</div>
                  <div className="uk-margin-medium-bottom">
                    {company.shopAccountType === 'base' ? (
                      <div
                        className="uk-text-small uk-margin-small"
                        style={{ color: '#333' }}
                      >
                        <span>{'1時間後：カゴ落ち後まもなく'}</span>
                      </div>
                    ) : (
                      <WaitMinutesSelect />
                    )}
                  </div>
                  <div>
                    <MessageDeliveryConfigForm />
                  </div>
                </div>
                <div className="uk-margin-small-top">
                  <div className="uk-margin-small-bottom">
                    <div className="uk-h5 uk-text-bold uk-margin-bottom">
                      メッセージ内容
                    </div>
                    <MessageContainer className="uk-flex">
                      <PreviewWrapper>
                        <CartAbandonmentMessagePreview company={company} />
                        {responsiveWindowSize === 'small' && (
                          <div className="uk-margin-top">
                            <SendingTestModal rootId="app-root" />
                          </div>
                        )}
                      </PreviewWrapper>
                      <div className="uk-flex-1">
                        <CartAbandonmentMessageForm company={company} />
                        {responsiveWindowSize !== 'small' && (
                          <div className="uk-margin-medium-top">
                            <SendingTestModal rootId="app-root" />
                          </div>
                        )}
                      </div>
                    </MessageContainer>
                  </div>
                </div>
              </>
            )}
            <FormFooter>
              {isTriggerValid() ? (
                <button
                  onClick={handleSubmit}
                  className={`uk-button uk-button-large uk-button-${
                    isSaveButtonActive ? 'primary' : 'gray'
                  }`}
                  style={{
                    cursor: isSaveButtonActive ? 'pointer' : 'inherit',
                  }}
                >
                  変更を保存する
                </button>
              ) : (
                <>
                  <div className="uk-text-small uk-text-danger uk-margin-small-bottom">
                    入力内容に不備があります。
                  </div>
                  <button
                    className="uk-button uk-button-primary uk-button-large"
                    disabled={true}
                  >
                    変更を保存する
                  </button>
                </>
              )}
            </FormFooter>
          </div>
        ) : (
          <div className="uk-margin-left">
            <ReactLoading
              type={'bubbles'}
              color={'#00888dbf'}
              height={80}
              width={80}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default App

const MessageContainer = styled.div`
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    flex-direction: column-reverse;
  }
`
const PreviewWrapper = styled.div`
  flex: none;
  margin-right: 40px;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    margin-right: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const FormFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 300px;
  right: 0;
  text-align: center;
  background: #f8f8f8;
  padding: 16px;
  border-top: 1px solid #e5e5e5;

  @media screen and (max-width: ${ResponsiveBreakpoint.large - 1}px) {
    left: 260px;
  }

  @media screen and (max-width: ${ResponsiveBreakpoint.medium - 1}px) {
    left: 0;
  }
`
