import React, { useEffect, useState } from 'react'
import {
  useForm,
  FormProvider,
  useFieldArray,
  FieldErrors,
} from 'react-hook-form'
import _ from 'lodash'
import {
  CardTypeMessage,
  CardTypeMessageContent,
} from 'components/Dashboard/types'
import { useModal } from 'react-hooks-use-modal'
import ReactLoading from 'react-loading'

import ContentsList from './ContentsList'
import CardPreview from './CardPreview'
import ContentsController from './ContentsController'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import {
  saveCardTypeMessageAllCards,
  setDisplayContentIndex,
} from 'components/Dashboard/stores/cardTypeMessage'
import BasicSettingsForm from './BasicSettingsForm'

export type FormValues = {
  contents: ContentFormValue[]
  lastCard: {
    layout: CardTypeMessage['lastCardLayout']
    text: CardTypeMessage['lastCardText']
    url: CardTypeMessage['lastCardUrl']
    uploadedImageFile: Blob | null
    imageUrl: string | null
  }
  removedContentIds: number[]
}

export type ContentFormValue = {
  dbid: number | null
  actionButtonText: string
  actionButtonUrl: string
  description: string
  displayActionButton: boolean
  displayDescription: boolean
  displayLabel: boolean
  displayPrice: boolean
  image: {
    uploadedFile: File | null
    url: string | null
    originalIdentifier: string | null // NOTE: 既存データから複製した際に、既存データのimageカラムの値を保持
  }
  labelStyle: number
  labelText: string
  price: string
  title: string
}

export const defaultContentFormValue: ContentFormValue = {
  dbid: null,
  actionButtonText: '',
  actionButtonUrl: '',
  description: '',
  displayActionButton: true,
  displayDescription: true,
  displayLabel: true,
  displayPrice: true,
  image: {
    uploadedFile: null,
    url: null,
    originalIdentifier: null,
  },
  labelStyle: 0,
  labelText: '',
  price: '',
  title: '',
}

const FormRoot = () => {
  const dispatch = useAppDispatch()
  const { cardTypeMessage, cardTypeMessageContents } = useAppSelector(
    (state: RootState) => state.cardTypeMessage
  )
  const { company } = useAppSelector((state: RootState) => state.company)

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      lastCard: {},
      removedContentIds: [],
    },
  })
  const { control, handleSubmit, setValue, getValues } = methods
  const {
    fields: contentFields,
    append: appendContent,
    remove: removeContent,
    move: moveContent,
    insert: insertContent,
  } = useFieldArray({
    control,
    name: 'contents',
  })

  const [modalContent, setModalContent] = useState<
    'sending' | 'succeeded' | 'failed'
  >()
  const [saveErrorMessage, setSaveErrorMessage] = useState<string | null>(null)

  const [Modal, openModal, closeModal] = useModal('app-root', {
    closeOnOverlayClick: false,
  })

  useEffect(() => {
    if (!cardTypeMessage) return

    const { layout, text, url, imageUrl } = getValues('lastCard')
    const { lastCardLayout, lastCardText, lastCardUrl, lastCardImage } =
      cardTypeMessage
    if (lastCardLayout !== layout) setValue('lastCard.layout', lastCardLayout)
    if (lastCardText !== text) setValue('lastCard.text', lastCardText)
    if (lastCardUrl !== url) setValue('lastCard.url', lastCardUrl)
    if (lastCardImage && lastCardImage.preview.url !== imageUrl)
      setValue('lastCard.imageUrl', lastCardImage.preview.url)
  }, [cardTypeMessage])

  useEffect(() => {
    if (cardTypeMessageContents === null) return

    resetContentsFormValue(cardTypeMessageContents)
  }, [cardTypeMessageContents])

  const resetContentsFormValue = (
    newContents: CardTypeMessageContent[] = []
  ) => {
    removeContent()
    if (newContents.length === 0) {
      appendContent(defaultContentFormValue)
      dispatch(setDisplayContentIndex(0))
      return
    }

    newContents.forEach(content => {
      const pickedContent = _.pick(content, [
        'actionButtonText',
        'actionButtonUrl',
        'description',
        'displayActionButton',
        'displayLabel',
        'displayDescription',
        'displayPrice',
        'labelStyle',
        'labelText',
        'title',
      ])
      const contentFormValue: ContentFormValue = {
        ...pickedContent,
        dbid: content.id,
        price: String(content.price),
        image: {
          uploadedFile: null,
          url: content.image?.preview.url || null,
          originalIdentifier: content.image?.identifier || null,
        },
      }
      appendContent(contentFormValue)
    })
  }

  const handleClickSaveButton = () => {
    handleSubmit(submitHandler, submitErrorHandler)()
  }

  const submitHandler = (data: FormValues) => {
    setModalContent('sending')
    openModal()

    const formData = new FormData()
    const authenticityToken = $('#authenticity_token').val() as string
    formData.append('authenticity_token', authenticityToken)
    // もっと見るカード情報を追加
    const { uploadedImageFile, layout, text, url } = data.lastCard
    formData.append('card_type_message[last_card_layout]', layout)
    if (uploadedImageFile)
      formData.append('card_type_message[last_card_image]', uploadedImageFile)
    if (text) formData.append('card_type_message[last_card_text]', text)
    if (url) formData.append('card_type_message[last_card_url]', url)
    data.removedContentIds.forEach(id => {
      formData.append(
        'card_type_message[removed_card_type_message_content_ids][]',
        String(id)
      )
    })
    // 各カードの情報を追加
    const contentKeys: (keyof ContentFormValue)[] = [
      'actionButtonText',
      'actionButtonUrl',
      'description',
      'displayActionButton',
      'displayDescription',
      'displayLabel',
      'displayPrice',
      'labelStyle',
      'labelText',
      'price',
      'title',
    ]
    data.contents.forEach((d, index) => {
      contentKeys.forEach(key => {
        const snakecaseKey = _.snakeCase(key)
        const value = d[key] as string
        formData.append(
          `card_type_message[card_type_message_contents][${index}][${snakecaseKey}]`,
          value
        )
      })
      if (d.image.uploadedFile) {
        formData.append(
          `card_type_message[card_type_message_contents][${index}][image_file]`,
          d.image.uploadedFile
        )
      } else if (d.image.originalIdentifier) {
        formData.append(
          `card_type_message[card_type_message_contents][${index}][image_identifier]`,
          d.image.originalIdentifier
        )
      }
      if (d.dbid)
        formData.append(
          `card_type_message[card_type_message_contents][${index}][id]`,
          String(d.dbid)
        )
    })

    dispatch(saveCardTypeMessageAllCards(formData))
      .then(() => {
        setModalContent('succeeded')
      })
      .catch(e => {
        const message = e.response.data.message
        setSaveErrorMessage(message || null)
        setModalContent('failed')
      })
  }

  const submitErrorHandler = (errors: FieldErrors<FormValues>) => {
    const contents = getValues('contents')
    for (let index = 0; index < contents.length; index++) {
      const error = errors.contents?.[index]
      if (error) {
        dispatch(setDisplayContentIndex(index))
        break
      }
    }
    if (errors.lastCard) {
      dispatch(setDisplayContentIndex('last'))
    }
  }
  return (
    <FormProvider {...methods}>
      <div className="uk-section-xsmall">
        <BasicSettingsForm resetContentsFormValue={resetContentsFormValue} />
      </div>
      <div className="uk-section-xsmall">
        <div className="uk-margin-bottom">
          <ContentsController
            contentFields={contentFields}
            removeContent={removeContent}
            moveContent={moveContent}
            insertContent={insertContent}
          />
        </div>
        <div className="uk-grid">
          <div style={{ width: 360 }}>
            <CardPreview />
          </div>
          <div className="uk-width-expand">
            <ContentsList contentFields={contentFields} />
          </div>
        </div>
        <hr className="uk-margin-medium" />
        <div className="uk-text-center uk-section-small uk-padding-remove">
          <button
            className="uk-button uk-button-primary uk-button-large uk-width-small"
            onClick={handleClickSaveButton}
          >
            保存
          </button>
        </div>
      </div>
      <Modal>
        <div className="uk-background-default uk-width-xlarge">
          {modalContent === 'sending' && <ModalSending />}
          {modalContent === 'succeeded' && (
            <ModalSucceeded
              slug={company?.slug || ''}
              closeModal={closeModal}
            />
          )}
          {modalContent === 'failed' && (
            <ModalFailed
              closeModal={closeModal}
              errorMessage={saveErrorMessage}
            />
          )}
        </div>
      </Modal>
    </FormProvider>
  )
}

const ModalSending = () => (
  <>
    <div className="uk-modal-header">
      <div>カードタイプメッセージの保存</div>
    </div>
    <div className="uk-modal-body">
      <div className="uk-flex uk-flex-column uk-flex-middle uk-padding-small">
        <div className="uk-text-small uk-text-muted uk-margin-bottom">
          入力内容を保存中です...
        </div>
        <ReactLoading
          type={'bubbles'}
          color={'#00888dbf'}
          height={80}
          width={80}
        />
      </div>
    </div>
  </>
)

const ModalSucceeded = ({
  slug,
  closeModal,
}: {
  slug: string
  closeModal: () => void
}) => {
  const buttonContents = [
    {
      label: '一斉配信',
      href: `/companies/${slug}/push_message_reservations?target_filtering=false`,
    },
    {
      label: 'セグメント配信',
      href: `/companies/${slug}/push_message_reservations?target_filtering=true`,
    },
    { label: 'トリガー配信', href: `/companies/${slug}/push_message_triggers` },
    {
      label: 'あいさつメッセージ',
      href: `/companies/${slug}/line_follow_trigger_messages`,
    },
  ]

  return (
    <>
      <div className="uk-modal-header">
        <div>
          <span
            style={{ width: 18, margin: '0px 5px 0px -10px' }}
            uk-icon="icon: check"
          />
          カードタイプメッセージの保存が完了しました
        </div>
      </div>
      <div className="uk-modal-body">
        <div className="uk-text-small uk-text-center uk-margin-bottom">
          作成したカードタイプメッセージは以下の配信でご利用いただけます。
        </div>
        <div className="uk-grid uk-grid-small uk-child-width-1-2">
          {buttonContents.map(({ label, href }) => (
            <div key={href}>
              <a
                href={href}
                style={{
                  border: '1px solid',
                  display: 'block',
                  textAlign: 'center',
                  padding: 8,
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                {label}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button className="uk-button uk-button-text" onClick={closeModal}>
          閉じる
        </button>
      </div>
    </>
  )
}

const ModalFailed = ({
  closeModal,
  errorMessage,
}: {
  closeModal: () => void
  errorMessage: string | null
}) => (
  <>
    <div className="uk-modal-header">
      <div>カードタイプメッセージの保存ができませんでした</div>
    </div>
    <div className="uk-modal-body">
      <div className="uk-text-small uk-margin-bottom">
        <p>
          ご不便をおかけしてしまい申し訳ございません。
          <br />
          {errorMessage ? (
            <>
              カードタイプメッセージの保存ができませんでした。
              <br />
              <br />
              エラー内容：{errorMessage}
            </>
          ) : (
            '一時的なシステムの不具合によりカードタイプメッセージの保存ができませんでした。'
          )}
        </p>
        <p>お手数ですが時間をあけてから再度お試しください。</p>
      </div>
    </div>
    <div className="uk-modal-footer uk-text-center">
      <button className="uk-button uk-button-text" onClick={closeModal}>
        閉じる
      </button>
    </div>
  </>
)

export default FormRoot
