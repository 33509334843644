import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector, useResponsiveWindowSize } from '../../hooks'
import { RootState } from '../../stores'
import { ShopItem, ShopItemVariant } from '../../types'

interface Props {
  restockMessageType: 'notification' | 'subscribed'
}

const defaultItemImage =
  'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/shop/shop_item_default.png'

const MessagePreview = ({ restockMessageType }: Props) => {
  const { trigger } = useAppSelector((state: RootState) => state.restockTrigger)
  const shopItems = useAppSelector((state: RootState) => state.shopItem.list)
  const buttonThemes = useAppSelector(
    (state: RootState) => state.flexMessageButtonTheme.list
  )
  const responsiveWindowSize = useResponsiveWindowSize()
  const [activeItem, setActiveItem] = useState<ShopItem | null>(null)
  const [activeVariant, setActiveVariant] = useState<ShopItemVariant | null>(
    null
  )

  useEffect(() => {
    if (!activeItem && shopItems.length > 0) setRandomActiveItem()
  }, [shopItems])

  const setRandomActiveItem = () => {
    const shopItem = shopItems[Math.floor(Math.random() * shopItems.length)]
    const shopItemVariant =
      shopItem.variants[Math.floor(Math.random() * shopItem.variants.length)]
    setActiveItem(shopItem)
    setActiveVariant(shopItemVariant)
  }

  if (!trigger) return <div />

  const { restockNotificationMessage } = trigger
  const [title, text, buttonText, buttonColorCode] = {
    notification: [
      restockNotificationMessage.notificationTitle,
      restockNotificationMessage.notificationText,
      restockNotificationMessage.notificationButtonText,
      restockNotificationMessage.notificationButtonColorCode,
    ],
    subscribed: [
      restockNotificationMessage.subscribedTitle,
      restockNotificationMessage.subscribedText,
      restockNotificationMessage.subscribedButtonText,
      restockNotificationMessage.subscribedButtonColorCode,
    ],
  }[restockMessageType]

  const buttonStyle =
    buttonThemes.find(({ colorCode }) => colorCode === buttonColorCode)
      ?.style || 'primary'
  const textColorCode = {
    link: '42659a',
    primary: 'ffffff',
    secondary: '111111',
  }[buttonStyle]

  return (
    <div>
      {responsiveWindowSize === 'small' && (
        <div className="uk-text-small uk-margin-small-bottom">
          メッセージプレビュー
        </div>
      )}
      <div style={{ width: 290 }}>
        <Card>
          <CardTitle>{title}</CardTitle>
          <CardText>{text}</CardText>
          <CardHr />
          <CardItem>
            <CardItemHeading>
              {
                {
                  notification: '再入荷した商品',
                  subscribed: '再入荷をお知らせする商品',
                }[restockMessageType]
              }
            </CardItemHeading>
            <div className="uk-flex uk-flex-middle">
              {activeItem && activeVariant ? (
                <>
                  <CardItemImage
                    src={
                      activeVariant.image?.src ||
                      activeItem.img1Origin ||
                      defaultItemImage
                    }
                  />
                  <div>
                    <CardItemTitle>{activeItem.title}</CardItemTitle>
                    {activeVariant.title !== 'Default Title' && (
                      <CardItemVariantTitle>
                        {activeVariant.title}
                      </CardItemVariantTitle>
                    )}
                    {restockMessageType === 'notification' && (
                      <CardItemPrice>
                        ¥
                        {activeVariant.price?.toLocaleString() ||
                          activeItem.price.toLocaleString()}
                      </CardItemPrice>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <CardItemImage src={defaultItemImage} />
                  <div>
                    <CardItemTitle>商品名</CardItemTitle>
                    <CardItemVariantTitle>
                      バリエーション名
                    </CardItemVariantTitle>
                    <CardItemPrice>¥1,234</CardItemPrice>
                  </div>
                </>
              )}
            </div>
          </CardItem>
          <CardButton
            style={{
              backgroundColor: `#${buttonColorCode}`,
              color: `#${textColorCode}`,
            }}
          >
            {buttonText}
          </CardButton>
        </Card>
        <div className="uk-flex uk-flex-column uk-flex-middle">
          {shopItems.length > 2 && (
            <a
              className="uk-margin-top uk-text-small uk-flex uk-flex-middle"
              onClick={setRandomActiveItem}
            >
              <span uk-icon="refresh" className="uk-margin-small-right" />
              表示商品をランダムに切り替える
            </a>
          )}
          <div
            className="uk-text-small uk-text-muted"
            style={{ marginTop: 16 }}
          >
            ※ 実際には該当する商品情報が送信されます。
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessagePreview

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;

  * {
    box-sizing: border-box;
  }
`
const CardTitle = styled.div`
  font-size: 17px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`
const CardText = styled.div`
  font-size: 13px;
  color: #666;
  white-space: pre-line;
`
const CardHr = styled.hr`
  margin: 16px 0;
`
const CardItem = styled.div`
  margin-bottom: 20px;
`
const CardItemHeading = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
`
const CardItemImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 16px;
  box-sizing: border-box;
`
const CardItemTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #333;
  margin-bottom: 1px;
`
const CardItemVariantTitle = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 1px;
`
const CardItemPrice = styled.div`
  font-size: 12px;
  color: #333;
`
const CardButton = styled.div`
  width: 100%;
  text-align: center;
  height: 44px;
  line-height: 44px;
  font-size: 15px;
  border-radius: 8px;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
