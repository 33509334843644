import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import axios from 'axios'
import { ShopItem } from 'components/Dashboard/types'
import { useForm } from 'react-hook-form'
import { Company } from 'types.ts/Company'

type Props = {
  itemId: string
  setItemId: (itemId: string) => void
  company: Company
}

type FormValues = {
  systemCode: string
}

const MakeshopItemSearchInput = ({ itemId, setItemId, company }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<ShopItem | null>(null)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>()

  useEffect(() => {
    if (itemId && !activeItem) {
      fetchShopItem(itemId)
    }
  }, [itemId])

  const fetchShopItem = async (itemId: string) => {
    setLoading(true)
    try {
      const response = await fetchSingleShopItem(itemId)
      const item = response.data
      if (item) {
        setActiveItem(item)
        setItemId(item.itemId)
      } else {
        setError('systemCode', {
          message: '入力したシステム商品コードの商品が見つかりませんでした。',
        })
      }
    } catch {
      setError('systemCode', {
        message: '商品の取得に失敗しました。再度お試しください。',
      })
    } finally {
      setLoading(false)
    }
  }

  const fetchSingleShopItem = async (itemId: string) =>
    await axios.get(`/api/companies/${company.slug}/shop_items/${itemId}`)

  const clearActiveItem = () => {
    setItemId('')
    setActiveItem(null)
  }

  const submitSystemCode = async (data: FormValues) => {
    const { systemCode } = data
    if (!systemCode.match(/^[0-9]{12}$/)) {
      setError('systemCode', {
        message: 'システム商品コードは半角数字12桁で入力してください。',
      })
      return
    }
    fetchShopItem(systemCode)
  }

  if (loading)
    return (
      <ReactLoading
        className="uk-margin-left"
        type={'bubbles'}
        color={'#00888dbf'}
        height={40}
        width={40}
      />
    )

  return (
    <div>
      {activeItem ? (
        <div className="uk-flex uk-flex-middle uk-flex-between uk-box-shadow-small uk-padding-small">
          <div className="uk-margin-small-right uk-text-small uk-border">
            {activeItem.title}
          </div>
          <div
            className="uk-button uk-button-default uk-button-small uk-flex-none"
            onClick={clearActiveItem}
          >
            変更
          </div>
        </div>
      ) : (
        <div>
          {errors.systemCode && (
            <div className="uk-text-small uk-text-danger uk-margin-xsmall-bottom">{`${errors.systemCode.message}`}</div>
          )}
          <div className="uk-flex uk-flex-middle uk-flex">
            <input
              type="text"
              placeholder="システム商品コード (例: 000000000001)"
              className="uk-input uk-margin-small-right"
              {...register('systemCode')}
            />
            <button
              className="uk-button uk-button-default uk-button-small uk-flex-none"
              onClick={handleSubmit(submitSystemCode)}
            >
              検索
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MakeshopItemSearchInput
