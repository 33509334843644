import React from 'react'
import styled from 'styled-components'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import EmojiPicker from '../EmojiPicker'
import { EmojiData } from 'emoji-mart'
import { TextMessageReplaceKey } from 'types.ts/PushMessage'

const keywordImageSrc = (key: MessageRellaceObject['key']) =>
  `https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/line_keyword/${key}.png`

const keywordImgTag = (key: MessageRellaceObject['key']) =>
  `<img alt='{${key}}' class='keyword-img' src='${keywordImageSrc(key)}'/>`

type MessageRellaceObject = {
  key: TextMessageReplaceKey
  label: string
}

const MessageReplaceObjects: MessageRellaceObject[] = [
  { key: 'Nickname', label: 'お客さまのLINE名' },
  { key: 'AccountName', label: 'ショップ名' },
  { key: 'ShopUrl', label: 'ショップへのリンク' },
  { key: 'ReviewUrl', label: 'レビューへのリンク' },
  { key: 'SlipNumber', label: '伝票番号' },
]

type Props = {
  availableKeywords: MessageRellaceObject['key'][]
  onChange: (text: string) => void
  defaultValue: string
}
type State = {
  html: string
  showPlaceholder: boolean
}

class MessageTextObjectInput extends React.Component<Props, State> {
  private contentEditable = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)
    const text = this.props.defaultValue || ''
    const html = this.textToHtml(text)
    this.state = {
      html: html,
      showPlaceholder: html.length === 0,
    }
    this.preloadKeywordImages()
  }

  messageKeywords = (): MessageRellaceObject[] => {
    const { availableKeywords } = this.props
    return MessageReplaceObjects.filter(obj =>
      availableKeywords.includes(obj.key)
    )
  }

  preloadKeywordImages = () => {
    this.messageKeywords().map(keyword => {
      const img = document.createElement('img')
      img.src = keywordImageSrc(keyword.key)
    })
  }

  handleChange = (evt: ContentEditableEvent) => {
    let html = evt.target.value
    if (html && !html.startsWith('<div>')) {
      html = '<div>' + html + '</div>'
    }
    this.setNewHtml(html)
  }

  resetShowPlaceholder = (str: string) => {
    const { showPlaceholder } = this.state
    if ((str.length === 0) !== showPlaceholder) {
      this.setState({ showPlaceholder: !showPlaceholder })
    }
  }

  focusForm = () => this.contentEditable.current?.focus()

  // TODO: この実装だと末尾にしか挿入できないので、カーソル位置に挿入できるよう修正
  insertKeyword = (key: MessageRellaceObject['key']) => {
    const { html } = this.state
    const imgTag = keywordImgTag(key)
    const newHtml = html.match(/<\/div>$/)
      ? html.replace(/<\/div>$/, imgTag + '</div>')
      : html + imgTag

    const modifiedHtml = newHtml
      .replace(/<br><div>/g, '<div>')
      .replace(/<div><br><img/g, '<div><img')
    this.setNewHtml(modifiedHtml)
    this.focusForm()
  }

  replaceKeywordToImg = (str: string): string => {
    const keys = this.messageKeywords().map(keyword => keyword.key)
    const regexp = new RegExp(`{(${keys.join('|')})}`, 'g')
    return str.replace(regexp, (_match, p1) => keywordImgTag(p1))
  }

  textToHtml = (text: string): string => {
    text = text
      .replace(/\n/g, '<br>')
      .replace(/ /g, '&nbsp;')
      .replace(/\r/g, '')
    text = this.replaceKeywordToImg(text)
    const lines = text.split('<br>')
    const html = lines
      .map((line, _index) => {
        return line === '' ? '<div><br></div>' : `<div>${line}</div>`
      })
      .join('')
    return html
  }

  handlePaste: React.ClipboardEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    const text = e.clipboardData.getData('text/plain')
    const html = this.textToHtml(text)
    document.execCommand('insertHTML', false, html)
  }

  // NOTE: タグの置換補足
  // 前提. innterTextを用いてテキストに変換されるため、HTMLタグは全て無視される
  // 1. <img>タグがある場合はalt属性をもとにキーワード文字を挿入
  // 2. <div>~~~</div>単位毎に改行を行っているのでinnerTextに変換する際に改行コードに変換
  replaceHtmlToText = (html: string): string => {
    const div = document.createElement('div')
    div.innerHTML = html.replace(
      /<img.*?alt=['"](.+?)['"]/g,
      (_match, p1) => `${p1}<img `
    )
    const text = Array.from(div.children)
      .map((child: any) => child.innerText)
      .join('\n')
    return text
  }

  handleSelectEmoji = (emoji: EmojiData) => {
    const { html } = this.state
    // NOTE: EmojiData型に`native`プロパティがない
    const { native } = emoji as EmojiData & { native: string }
    if (native) {
      const newHtml = html.match(/<\/div>$/)
        ? html.replace(/<\/div>$/, native + '</div>')
        : html + native
      this.setNewHtml(newHtml)
      this.focusForm()
    }
  }

  hasNestedDivs = (html: string): boolean => {
    const regex = /<div>(<div>(.*?)<\/div>)<\/div>/g
    return regex.test(html)
  }

  removeNestedDivs = (html: string): string => {
    const regex = /<div>(<div>(.*?)<\/div>)<\/div>/g
    let sanitizedHtml = html
    while (regex.test(sanitizedHtml)) {
      sanitizedHtml = sanitizedHtml.replace(regex, '<div>$2</div>')
    }
    return sanitizedHtml
  }

  setNewHtml = (html: string) => {
    const sanitizedHtml = this.hasNestedDivs(html)
      ? this.removeNestedDivs(html)
      : html
    this.setState({ html: sanitizedHtml })
    this.props.onChange(this.replaceHtmlToText(sanitizedHtml))
    this.resetShowPlaceholder(sanitizedHtml)
  }

  render = () => {
    const { html, showPlaceholder } = this.state
    return (
      <Editor>
        <EditorMain>
          <ContentEditable
            innerRef={this.contentEditable}
            html={html}
            onChange={this.handleChange}
            className="uk-textarea uk-form-small"
            style={styles.contentEditable}
            onPaste={this.handlePaste}
          />
          {showPlaceholder && <Placeholder>テキストを入力</Placeholder>}
          <EmojiPickerWrapper>
            <EmojiPicker
              onSelect={this.handleSelectEmoji.bind(this)}
              iconStyle={{
                fontSize: 25,
              }}
              modalStyle={{
                bottom: 25,
                right: 0,
              }}
            />
          </EmojiPickerWrapper>
        </EditorMain>
        <EditorFooter>
          {this.messageKeywords().map(keyword => (
            <FooterButton
              key={keyword.key}
              onClick={this.insertKeyword.bind(this, keyword.key)}
            >
              {keyword.label}
            </FooterButton>
          ))}
        </EditorFooter>
        <style>{keywordImageCss}</style>
      </Editor>
    )
  }
}

export default MessageTextObjectInput

const styles = {
  contentEditable: {
    height: 250,
    lineHeight: 1.6,
  },
}
const keywordImageCss = `
  img.keyword-img {
    border-radius: 10px;
    margin: 0 4px;
    cursor: grab;
  }
  img.keyword-img::selection {
    background: none;
  }
`
const Editor = styled.div``
const EditorMain = styled.div`
  position: relative;
`
const Placeholder = styled.div`
  position: absolute;
  top: 5px;
  left: 9px;
  color: #bbb;
  font-size: 14px;
  pointer-events: none;
`
const EditorFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0;
`
const FooterButton = styled.div`
  border: 1px solid #e5e5e5;
  font-size: 14px;
  padding: 5px 8px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 8px;
`
const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 9px;
`
