import $ from 'jquery'

$(function () {
  $(document).on('change', '.js-preview-image-input', function () {
    const file = this.files[0]
    const id = $(this).data('id')
    const $preview = $(`.js-preview-image-tag--${id}`)

    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const image = this.result
        $preview.attr({ src: image })
      }
    } else {
      $preview.attr({ src: '' })
    }
  })
})
