import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import LineGraph from '../molecules/LineGraph'
import Util from '../../lib/Util'
import { DateRange } from 'react-date-range'
import { ja } from 'react-date-range/dist/locale'

const today = new Date()

const DashboardLineAnalytics = ({ company }) => {
  const [graphData, setGraphData] = useState([])
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(Util.addDaysToDate(today, -15))
  const [endDate, setEndDate] = useState(Util.addDaysToDate(today, -2))
  const [displayDateRange, setDisplayDateRange] = useState(false)
  const [latestInsight, setLatestInsight] = useState(null)

  useEffect(() => {
    loadLatestInsight()
    loadGraphData()
  }, [])

  const loadLatestInsight = () => {
    axios
      .get(`/api/companies/${company.slug}/line_follower_insights/latest`)
      .then(response => {
        setLatestInsight(response.data.insight)
      })
  }

  const loadGraphData = () => {
    setLoading(true)
    setDisplayDateRange(false)
    const startDateNum = Util.formatDate(startDate, '')
    const endDateNum = Util.formatDate(endDate, '')
    axios
      .get(
        `/api/companies/${company.slug}/line_follower_insights?start_date=${startDateNum}&end_date=${endDateNum}`
      )
      .then(response => {
        setGraphData(response.data)
        setLoading(false)
      })
  }

  const handleSelect = ranges => {
    if (startDate != ranges.selection.startDate)
      setStartDate(ranges.selection.startDate)
    if (endDate != ranges.selection.endDate)
      setEndDate(ranges.selection.endDate)
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }

  const formattedStartDate = Util.formatDate(startDate, '/')
  const formattedEndDate = Util.formatDate(endDate, '/')

  if (!latestInsight) return <div />

  // 原則としてocConnectednumがactiveFollowersより大きくなることはないが、
  // データ取得元が異なるため、タイミングや状況によっては発生しうる
  // その場合はocConnectednumを表示すると紛らわしいので非表示にする
  const showOcConnected =
    latestInsight.ocConnectednum < latestInsight.activeFollowers
  const blockClassName = showOcConnected
    ? 'uk-width-1-4@l uk-width-1-2'
    : 'uk-width-1-3@s uk-width-1-1'
  const NumberBlock = ({ children }) => (
    <div className={`${blockClassName} uk-margin-small-bottom`}>
      <div className="uk-background-default" style={{ padding: '16px 4px' }}>
        {children}
      </div>
    </div>
  )

  return (
    <Wrapper>
      <Header>
        <div className="uk-flex uk-flex-bottom">
          <h2 className="uk-h4">LINE友だち数</h2>
        </div>
        <div className="uk-margin-small-bottom">
          <a
            target="_blank"
            rel="noopener"
            href={`/usage_guide_redirects/line_friends_analysis?shop_account_type=${company.shopAccountType}`}
          >
            <div className="uk-flex uk-flex-middle uk-text-small uk-flex-right">
              <div className="uk-margin-xsmall-right">
                LINE友だち数の各数値について
              </div>
              <div className="material-icons" style={{ fontSize: 18 }}>
                open_in_new
              </div>
            </div>
          </a>
        </div>
      </Header>
      <div className="uk-margin-bottom">
        <div className="uk-grid uk-grid-small uk-grid-match">
          <NumberBlock>
            <div className="uk-text-center uk-margin-small-top">
              有効友だち数
              <TooltipIcon
                text="総友だち数のうち、ブロックした人を差し引いた、現在有効な友だち数です。"
                style={{ width: 16, marginLeft: 5 }}
              />
            </div>
            <div className="uk-text-center uk-padding-small">
              <span className="uk-h1 uk-margin-remove uk-text-primary">
                {/* NOTE: 登録直後などで一時的に「有効友だち数」<「LINEID管理友だち数」となる可能性の対策 */}
                {Math.max(
                  latestInsight.activeFollowers,
                  latestInsight.ocConnectednum
                ).toLocaleString()}
              </span>
              <span className="uk-margin-small-left">人</span>
            </div>
            <div className="uk-text-center uk-text-xsmall uk-margin-small-bottom">
              {`（総友だち数: ${Math.max(
                latestInsight.totalFollowers,
                latestInsight.ocConnectednum
              ).toLocaleString()}人）`}
              <TooltipIcon
                text="LINE公式アカウントを作成してからのすべての友だちの数です。"
                style={{ width: 14 }}
              />
            </div>
          </NumberBlock>
          {showOcConnected && (
            <NumberBlock>
              <div className="uk-text-center uk-margin-small-bottom uk-margin-small-top">
                LINEID管理友だち数
                <TooltipIcon
                  text="おみせコネクトが認識しているLINE友だちの人数です。トークルームでの1対1メッセージや、一斉配信での開封・クリックの計測が可能となります。"
                  style={{ width: 16, marginLeft: 5 }}
                  position="top-left"
                />
              </div>
              <div className="uk-text-center uk-padding-small">
                <span className="uk-h1 uk-margin-remove uk-text-primary">
                  {latestInsight.ocConnectednum.toLocaleString()}
                </span>
                <span className="uk-margin-small-left">人</span>
              </div>
            </NumberBlock>
          )}
          <NumberBlock>
            <div className="uk-text-center uk-margin-small-top">
              ID紐付き友だち数
              <TooltipIcon
                text="お客様のLINEアカウントと、ショップの顧客情報が紐付いている数です。セグメント配信やトリガー配信を送信することが可能です。"
                style={{ width: 16, marginLeft: 5 }}
              />
            </div>
            <div className="uk-text-center uk-padding-small">
              <span className="uk-h1 uk-margin-remove uk-text-primary">
                {latestInsight.accountConnectednum.toLocaleString()}
              </span>
              <span className="uk-margin-small-left">人</span>
            </div>
            <div className="uk-text-center uk-text-xsmall uk-margin-small-bottom">
              {`（ブラウザ紐付き数: ${latestInsight.browserConnectednum.toLocaleString()}人）`}
              <TooltipIcon
                text="おみせコネクトが顧客管理しているLINE友だちと、そのLINE友だちがショップを見るときに使うブラウザが紐付いている数です。閲覧商品リマインド配信を送信することが可能です。"
                style={{ width: 14 }}
              />
            </div>
          </NumberBlock>
          <NumberBlock>
            <div className="uk-text-center uk-margin-small-bottom uk-margin-small-top">
              ブロック数
            </div>
            <div className="uk-text-center uk-padding-small">
              <span className="uk-h1 uk-margin-remove uk-text-primary">
                {latestInsight.blocks.toLocaleString()}
              </span>
              <span className="uk-margin-small-left">人</span>
            </div>
          </NumberBlock>
        </div>
      </div>
      <BorderCard className="uk-flex uk-flex-column uk-text-small">
        <div className="uk-text-right uk-padding-small uk-padding-remove-bottom">
          <RangeSelectButton
            className="uk-text-small"
            onClick={() => setDisplayDateRange(!displayDateRange)}
          >
            {formattedStartDate === formattedEndDate
              ? formattedStartDate
              : `${formattedStartDate} ~ ${formattedEndDate}`}
            <RangeSelectIcon className="material-icons">{`arrow_drop_${
              displayDateRange ? 'up' : 'down'
            }`}</RangeSelectIcon>
          </RangeSelectButton>
          {displayDateRange && (
            <DateRangeModal>
              <DateRange
                ranges={[selectionRange]}
                onChange={handleSelect}
                locale={ja}
                showDateDisplay={false}
              />
              <DateRangeFooter>
                <div
                  className="uk-button uk-button-primary uk-button-small"
                  onClick={loadGraphData}
                >
                  適用する
                </div>
              </DateRangeFooter>
            </DateRangeModal>
          )}
        </div>
        {loading ? (
          <div className="uk-text-center uk-text-snall uk-margin-top">
            読み込み中...
          </div>
        ) : graphData.length === 0 ? (
          <div className="uk-text-center uk-text-snall uk-margin-top">
            表示可能なデータがありません
          </div>
        ) : (
          <GraphWrapper>
            <LineGraph
              data={graphData}
              xKey="date"
              lineData={[
                {
                  key: 'activeFollowers',
                  label: '有効友だち数',
                  color: '#00888d',
                },
                {
                  key: 'browserConnectednum',
                  label: 'ブラウザ紐付き数',
                  color: '#4f65d3',
                },
                {
                  key: 'accountConnectednum',
                  label: 'ID紐付き友だち数',
                  color: '#ff1d5a',
                },
                { key: 'blocks', label: 'ブロック数', color: '#666666' },
              ]}
            />
          </GraphWrapper>
        )}
      </BorderCard>
    </Wrapper>
  )
}

export default DashboardLineAnalytics

const TooltipIcon = ({ text, style, position }) => (
  <span
    uk-icon="question"
    className={`hint--${position || 'top'} hint--medium hint--rounded`}
    aria-label={text}
    style={{ verticalAlign: 'text-top', ...style }}
  />
)

const Wrapper = styled.div``
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 600px) {
    /* flex-direction: column;
    align-items: flex-start; */
    display: block;
  }
`
const BorderCard = styled.div`
  position: relative;
  background-color: #fff;
  height: 350px;
  box-sizing: border-box;
  line-height: 1.6;
`
const GraphWrapper = styled.div`
  position: absolute;
  top: 50px;
  bottom: 2px;
  left: -25px;
  right: 0;
  font-size: 12px;

  @media screen and (max-width: 600px) {
    left: -40px;
  }
`
const RangeSelectButton = styled.div`
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  margin-left: 12px;
  display: inline-block;
  @media screen and (max-width: 600px) {
    margin-left: 0;
  }
`
const RangeSelectIcon = styled.div`
  line-height: 0;
  vertical-align: middle;
  font-size: 26px;
  color: #999;
`
const DateRangeModal = styled.div`
  position: absolute;
  top: 30px;
  right: -5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 8%);
  z-index: 100;
`
const DateRangeFooter = styled.div`
  background-color: #fff;
  padding: 0 20px 12px;
  text-align: center;
  margin-top: -10px;
`
