import React from 'react'
import ShopPopupForm from '../organisms/ShopPopupForm'

const PopupEditPage = ({ company, shopAccount, shopPopup, themes }) => {
  return (
    <div className="uk-section">
      <div className="uk-container uk-container-xsmall">
        <h1 className="uk-h3 uk-margin-remove">友だち追加バナーの設定</h1>
        <div className="uk-section-small">
          <ShopPopupForm
            company={company}
            shopAccount={shopAccount}
            shopPopup={shopPopup}
            themes={themes}
          />
        </div>
      </div>
    </div>
  )
}
export default PopupEditPage
