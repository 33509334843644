import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '.'
import { Company, RestockNotificationVariantData } from '../types'

type RestockNotificationVariantDataState = {
  list: RestockNotificationVariantData[]
  initialized: boolean
  loading: boolean
  currentPage: number
  totalPages: number
}

const initialState: RestockNotificationVariantDataState = {
  list: [],
  initialized: false,
  loading: false,
  currentPage: 1,
  totalPages: 1,
}

const slice = createSlice({
  name: 'restockNotificationVariantData',
  initialState,
  reducers: {
    setVariantData: (state, action) => {
      state.list = action.payload
      state.initialized = true
    },
    addVariantData: (state, action) => {
      state.list = state.list.concat(action.payload)
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export default slice.reducer

export const {
  setVariantData,
  addVariantData,
  setCurrentPage,
  setTotalPages,
  setLoading,
} = slice.actions

export function fetchRestockNotificationVariantData(company: Company) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    try {
      const { currentPage } = getState().restockNotificationVariantData
      dispatch(setLoading(true))
      const params = { page: currentPage }
      const url =
        company.shopAccountType === 'shopify'
          ? `/api/companies/${company.slug}/restock_notification_settings/variants`
          : `/api/companies/${company.slug}/${company.shopAccountType}/restock_notification_settings/variants`
      const response = await axios.get(url, { params })
      const { rows, totalPages } = response.data
      if (currentPage === 1) {
        dispatch(setVariantData(rows))
      } else {
        dispatch(addVariantData(rows))
      }
      dispatch(setLoading(false))
      dispatch(setTotalPages(totalPages))
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
    }
  }
}
