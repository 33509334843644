import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'
import { AppDispatch } from '.'
import { AddLineFriendTracking, Company } from '../types'

interface addLineFriendTrackingsState {
  addLineFriendTrackings: AddLineFriendTracking[]
}

const initialState: addLineFriendTrackingsState = {
  addLineFriendTrackings: [],
}

const slice = createSlice({
  name: 'addLineFriendTrackings',
  initialState,
  reducers: {
    setAddLineFriendTrackings: (state, action) => {
      state.addLineFriendTrackings = action.payload.addLineFriendTrackings
    },
  },
})

export default slice.reducer

export const { setAddLineFriendTrackings } = slice.actions

type createAddLineFriendTrackingParams = {
  title: string
  parameter: string | null
}

export const createAddLineFriendTracking =
  (company: Company, params: createAddLineFriendTrackingParams) =>
  async (_dispatch: AppDispatch) => {
    const data = snakecaseKeys({
      addLineFriendTracking: params,
      authenticityToken: $('#authenticity_token').val(),
    })
    await axios.post(
      `/api/companies/${company.slug}/add_line_friend_trackings`,
      data
    )
  }

export const fetchArchivedAddLineFriendTrackings =
  (company: Company) => async (dispatch: AppDispatch) => {
    const response = await axios.get(
      `/api/companies/${company.slug}/add_line_friend_trackings`,
      {
        params: {
          status: 'archived',
        },
      }
    )
    dispatch(setAddLineFriendTrackings(response.data))
  }

export const archiveAddLineFriendTracking =
  (company: Company, id: number) => async () => {
    const data = snakecaseKeys({
      authenticityToken: $('#authenticity_token').val(),
    })
    await axios.patch(
      `/api/companies/${company.slug}/add_line_friend_trackings/${id}/archive`,
      data
    )
  }
