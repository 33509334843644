import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'
import { AppDispatch, RootState } from '.'
import { Company, RestockNotificationSetting } from '../types'

interface RestockNotificationSettingState {
  restockNotificationSetting: RestockNotificationSetting | null
}

const initialState: RestockNotificationSettingState = {
  restockNotificationSetting: null,
}

const slice = createSlice({
  name: 'restockNotificationSetting',
  initialState,
  reducers: {
    setSetting: (state, action) => {
      state.restockNotificationSetting = action.payload
    },
    updateSetting: (state, action) => {
      state.restockNotificationSetting = action.payload
    },
  },
})

export default slice.reducer

export const { setSetting, updateSetting } = slice.actions

export function fetchRestockNotificationSetting(company: Company) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/restock_notification_setting`
      )
      dispatch(setSetting(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export function saveRestockNotificationSetting(
  company: Company,
  newRestockNotificationSetting: RestockNotificationSetting
) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const { restockNotificationSetting } = getState().restockNotificationSetting
    if (!restockNotificationSetting) return

    const restockNotificationSettingParams = {
      variantIdsDisabledSubscription:
        newRestockNotificationSetting.variantIdsDisabledSubscription,
      variantIdsDisabledNotification:
        newRestockNotificationSetting.variantIdsDisabledNotification,
    }
    const data = snakecaseKeys({
      restockNotificationSetting: restockNotificationSettingParams,
      authenticityToken: $('#authenticity_token').val(),
    })
    const response = await axios.put(
      `/api/companies/${company.slug}/restock_notification_setting`,
      data
    )
    console.log(response.data)
    dispatch(setSetting(response.data))
  }
}
