import React, { useState, useEffect } from 'react'
import axios from 'axios'

import SelectSearch, { fuzzySearch } from 'react-select-search'

const defaultLineIcon =
  'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/line_account/line_default_icon.jpg'

const UserCustomerConnectForm = ({ company, sendMessage, sendButtonText }) => {
  const [userCompanyRelations, setUserCompanyRelations] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const currentUserCompanyRelation =
    userCompanyRelations.find(ucr => ucr.isCurrentUser) || null
  const notCurrentUserCompanyRelations = userCompanyRelations.filter(
    ucr => !ucr.isCurrentUser
  )

  useEffect(() => {
    axios
      .get(`/api/companies/${company.slug}/user_company_relations`)
      .then(response => {
        setUserCompanyRelations(response.data)
      })
  }, [])

  const getOptions = name =>
    new Promise(resolve => {
      if (name) {
        axios
          .get(
            `/api/companies/${company.slug}/customers?association=with_line&name=${name}&name_type=line`
          )
          .then(response => {
            const options = response.data.map(customer => ({
              name: customer.name,
              value: customer.id,
              image: customer.image,
            }))
            resolve(options)
          })
      } else {
        resolve([])
      }
    })

  const saveCustomer = customer => {
    if (!currentUserCompanyRelation) return

    const data = {
      user_company_relation: {
        sending_test_customer_id: customer ? customer.id : null,
      },
      authenticity_token: $('#authenticity_token').val(),
    }
    axios
      .put(
        `/api/companies/${company.slug}/user_company_relations/${currentUserCompanyRelation.id}`,
        data
      )
      .then(response => {
        setUserCompanyRelations(response.data)
        setSelectedCustomer(null)
      })
  }

  const renderOption = (props, option, _snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      objectFit: 'cover',
      marginRight: 10,
    }
    return (
      <button {...props} className={className} type="button">
        <div className="uk-flex uk-flex-middle">
          <img
            alt=""
            style={imgStyle}
            width="28"
            height="28"
            src={option.image || defaultLineIcon}
          />
          <span>{option.name}</span>
        </div>
      </button>
    )
  }

  const connectedLineCard = userCompanyRelation => {
    const { sendingTestCustomer } = userCompanyRelation
    return (
      <div className="uk-card uk-card-default uk-card-body uk-width-1-1 uk-padding-small uk-flex uk-flex-middle uk-flex-between">
        {sendingTestCustomer ? (
          <div className="uk-flex uk-flex-middle">
            <img
              src={
                sendingTestCustomer.customerLineAccount.pictureUrl ||
                defaultLineIcon
              }
              style={{
                width: 32,
                height: 32,
                borderRadius: '50%',
                objectFit: 'cover',
                marginRight: 12,
              }}
            />
            <div className="uk-text-small uk-text-secondary uk-text-bold">
              {sendingTestCustomer.customerLineAccount.displayName}
            </div>
          </div>
        ) : (
          <div
            className="uk-text-small"
            style={{ height: 32, lineHeight: '32px' }}
          >
            未設定
          </div>
        )}
        {userCompanyRelation.isCurrentUser ? (
          <button
            className="uk-button uk-button-default uk-button-small"
            onClick={() => {
              saveCustomer(null)
            }}
          >
            変更・解除
          </button>
        ) : (
          <div
            style={{
              wordWrap: 'break-word',
              minWidth: 0,
              marginLeft: 12,
              fontSize: 14,
            }}
          >
            {userCompanyRelation.user.email}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      {currentUserCompanyRelation ? (
        currentUserCompanyRelation.sendingTestCustomer ? (
          <div>
            <div className="uk-text-small uk-margin-small-bottom">
              <span
                className="uk-margin-small-right uk-text-primary"
                uk-icon="icon: check; ratio: 1"
              ></span>
              以下のLINEアカウントが登録されています。
            </div>
            {connectedLineCard(currentUserCompanyRelation)}
          </div>
        ) : (
          <div>
            <div className="uk-text-small uk-text-secondary uk-margin-small-bottom">
              LINEアカウントの登録
            </div>
            <div className="uk-flex uk-flex-middle">
              <SelectSearch
                options={[]}
                getOptions={name => getOptions(name)}
                search
                filterOptions={fuzzySearch}
                placeholder="LINEのアカウント名を入力してください"
                onChange={(_, option) => {
                  setSelectedCustomer({
                    id: option.value,
                    name: option.name,
                  })
                }}
                emptyMessage={
                  '検索条件に一致するLINEアカウントが見つかりませんでした。'
                }
                renderOption={renderOption}
              />
              {selectedCustomer && (
                <div
                  className="uk-button uk-button-small uk-button-primary uk-margin-left uk-flex-none"
                  onClick={() => saveCustomer(selectedCustomer)}
                >
                  登録する
                </div>
              )}
            </div>
            <div className="uk-section uk-padding-small uk-section-muted uk-margin-top uk-text-small">
              <div>自分のLINEアカウントが見つからない場合</div>
              <ul className="uk-list uk-list-disc">
                <li>
                  ご自身のLINEアカウントでショップのLINEアカウントを友だち追加してください。
                </li>
                <li>
                  ショップのLINEアカウントの受信設定（Webhook）が正しくできているかご確認ください。詳しくは
                  <a
                    href={`/companies/${company.slug}/self_line/company_line_accounts/webhook`}
                  >
                    メッセージの受信設定
                  </a>
                  を参照してください。
                </li>
                <li>
                  ご自身のLINEアカウントからショップのLINEアカウントへ、メッセージを送信してください（内容は何でも大丈夫です）。
                </li>
              </ul>
            </div>
          </div>
        )
      ) : (
        <div className="uk-text-small uk-text-muted">読み込み中...</div>
      )}
      {notCurrentUserCompanyRelations.length > 0 && (
        <div>
          <div className="uk-h5 uk-margin-top uk-margin-small-bottom">
            他のメンバーのテストアカウント
          </div>
          <div className="uk-text-small uk-text-muted uk-margin-small-bottom">
            ※ テスト配信はすべてのテストアカウントに配信されます。
          </div>
          <div>
            {notCurrentUserCompanyRelations.map(userCompanyRelation => (
              <div
                key={userCompanyRelation.id}
                className="uk-margin-small-bottom"
              >
                {connectedLineCard(userCompanyRelation)}
              </div>
            ))}
          </div>
        </div>
      )}
      {sendMessage && (
        <div className="uk-text-center uk-margin-medium-top">
          <button
            className="uk-button uk-button-primary"
            onClick={sendMessage}
            disabled={!currentUserCompanyRelation?.sendingTestCustomer}
          >
            {sendButtonText || '下書きに保存してテスト配信'}
          </button>
        </div>
      )}
    </div>
  )
}

export default UserCustomerConnectForm
