import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import CancelIcon from '@mui/icons-material/Cancel'

import { RootState } from '../stores'
import {
  fetchCustomers,
  resetCustomersData,
  setSearchParams,
} from '../stores/customers'
import { FilterTypeEnum } from '../stores/customers'
import { useAppDispatch, useAppSelector } from '../hooks'
import { Company } from '../types'

interface Props {
  company: Company
}

export const filterOptions: {
  [key in FilterTypeEnum]: string
} = {
  [FilterTypeEnum.LINE_CONNECTED]: 'チャットすべて',
  [FilterTypeEnum.UNREAD]: 'チャット未読',
  [FilterTypeEnum.ACTION_REQUIRED]: 'チャット要対応',
  [FilterTypeEnum.BROWSER_CONNECTED]: 'ブラウザ紐付き',
  [FilterTypeEnum.ID_CONNECTED]: 'ID紐付き',
  [FilterTypeEnum.SHOP_CONNECTED]: '購入者全て',
  [FilterTypeEnum.RESTOCK_SUBSCRIBED]: '再入荷通知リクエスト',
  [FilterTypeEnum.SPAM]: 'チャットスパム',
}

const CustomerSearchForm = ({ company }: Props) => {
  const dispatch = useAppDispatch()
  const { searchParams } = useAppSelector((state: RootState) => state.customers)

  const handleSubmit = () => {
    dispatch(resetCustomersData())
    dispatch(fetchCustomers(company))
  }

  const changeKeyword = (keyword: string) => {
    dispatch(setSearchParams({ ...searchParams, keyword }))
  }

  const addFilterQueryToUrl = (filter: string) => {
    const params = new URLSearchParams(location.search)
    params.set('filter', filter)
    history.pushState(null, '', `?${params.toString()}`)
  }

  const handleSelectFilter = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSearchParams({ ...searchParams, filter: e.target.value }))
    addFilterQueryToUrl(e.target.value)
    handleSubmit()
  }

  const clearSearchText = () => {
    changeKeyword('')
    handleSubmit()
  }

  return (
    <Wrapper className="uk-flex-column uk-padding-small">
      <select
        className="uk-select uk-flex-none uk-margin-small-bottom"
        onChange={handleSelectFilter}
        value={searchParams.filter}
      >
        {Object.entries(filterOptions).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
      <form
        className="uk-flex uk-flex-1"
        style={{ position: 'relative' }}
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <input
          type="text"
          value={searchParams.keyword}
          onChange={e => changeKeyword(e.target.value)}
          placeholder="名前/電話番号/Eメール"
          className="uk-input"
        />
        <input
          type="submit"
          value="検索"
          className="uk-button uk-button-default uk-button-small uk-flex-none uk-margin-small-left"
          disabled={searchParams.keyword === ''}
        />
        {searchParams.keyword !== '' && (
          <ClearButton onClick={clearSearchText}>
            <CancelIcon style={{ fontSize: 18 }} />
          </ClearButton>
        )}
      </form>
    </Wrapper>
  )
}

export default CustomerSearchForm

const Wrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const ClearButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 7px;
  right: 77px;
  color: #ccc;
`
