import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'

import CustomersList from './CustomersList'
import { Company, Customer } from '../types'
import {
  fetchCustomers,
  setSearchParams,
  setSelectedCustomer,
} from '../stores/customers'
import { setCompany, setIsAdmin, setMessageSendable } from '../stores/company'
import ChatArea from './ChatArea'
import CustomerProfile from './CustomerProfile'
import { useWindowSize } from '../hooks'
import { PC_MIN_WIDTH } from '../const'
import { useAppSelector, useAppDispatch } from '../hooks'
import { RootState } from '../stores'
import { fetchNewChatMessages } from '../stores/chat'
import { setDisplayProfile } from '../stores/layout'
import { filterOptions } from './CustomerSearchForm'

interface Props {
  company: Company
  defaultCustomer: Customer | null
  isAdmin: boolean
  messageSendable: boolean
}

let fetchMessageTimeout: NodeJS.Timeout

const App = ({ company, defaultCustomer, isAdmin, messageSendable }: Props) => {
  const dispatch = useAppDispatch()
  const [windowWidth] = useWindowSize()
  const isPcLayout = windowWidth >= PC_MIN_WIDTH
  const { selectedCustomer, searchParams } = useAppSelector(
    (state: RootState) => state.customers
  )
  const { displayProfile } = useAppSelector((state: RootState) => state.layout)

  const [Modal, openModal, closeModal, isModalOpen] = useModal(
    'talkroom-root',
    {
      closeOnOverlayClick: true,
    }
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const filter = params.get('filter')
    if (filter && Object.keys(filterOptions).includes(filter))
      dispatch(setSearchParams({ ...searchParams, filter: filter }))

    dispatch(setCompany(company))
    dispatch(setIsAdmin(isAdmin))
    dispatch(setMessageSendable(messageSendable))
    if (defaultCustomer) {
      dispatch(setSelectedCustomer(defaultCustomer))
    }
    dispatch(fetchCustomers(company))
    // ブラウザの戻るボタンの操作制御
    window.addEventListener('popstate', function (_e) {
      const match = location.href.match(/(\/)talk_rooms(\/)*$/)
      if (match) {
        dispatch(setSelectedCustomer(null))
      }
    })
    setDefaultDisplayProfile()

    fetchMessageTimeout = autoReloadMessages()
    return () => {
      if (fetchMessageTimeout) {
        clearTimeout(fetchMessageTimeout)
      }
    }
  }, [])

  useEffect(() => {
    if (!selectedCustomer && isModalOpen) {
      closeModal()
    }
  }, [selectedCustomer])

  const setDefaultDisplayProfile = () => {
    const value = localStorage.getItem('talkroom.displayProfile')
    if (value) {
      dispatch(setDisplayProfile(value === 'true'))
    }
  }

  const autoReloadMessages = () => {
    return setTimeout(() => {
      dispatch(fetchNewChatMessages(company))
      fetchMessageTimeout = autoReloadMessages()
    }, 10000)
  }

  const renderNoCustomerSelected = () => {
    return (
      <NoCustomerSelectedContainer>
        <img src="https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/chat_area_logo.png" />
        <MessageText>お客さまの情報を見てみましょう！</MessageText>
      </NoCustomerSelectedContainer>
    )
  }

  return (
    <Container id="talkroom-root">
      <CustomersList company={company} />
      {selectedCustomer ? (
        <>
          <ChatArea
            openProfileModal={openModal}
            customer={selectedCustomer}
            company={company}
          />
          {isPcLayout ? (
            <ProfileWrapper displayProfile={displayProfile}>
              <CustomerProfile customer={selectedCustomer} company={company} />
            </ProfileWrapper>
          ) : (
            <Modal>
              <ProfileModalInner>
                <ProfileModalContent>
                  <CustomerProfile
                    customer={selectedCustomer}
                    company={company}
                  />
                </ProfileModalContent>
                <ModalCloseButton onClick={closeModal}>閉じる</ModalCloseButton>
              </ProfileModalInner>
            </Modal>
          )}
        </>
      ) : (
        isPcLayout && renderNoCustomerSelected()
      )}
    </Container>
  )
}

export default App

const Container = styled.div`
  display: flex;
`

const ProfileWrapper = styled.div<{ displayProfile: boolean }>`
  position: relative;
  box-sizing: border-box;
  width: ${({ displayProfile }) => (displayProfile ? 360 : 0)}px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
`

const ProfileModalInner = styled.div`
  background-color: #fff;
  margin: auto;
  width: 340px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ProfileModalContent = styled.div`
  overflow-y: scroll;
`

const ModalCloseButton = styled.div`
  text-align: center;
  padding: 10px;
  box-shadow: 0px -3px 2px 0px rgb(0 0 0 / 5%);
  flex: none;
  font-size: 14px;
  cursor: pointer;
`

const NoCustomerSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f9fa;
  height: 100vh;
`

const MessageText = styled.div`
  margin-top: 10px;
  color: #adb5bd;
  font-size: 16px;
`
