import React, { useEffect, useState } from 'react'
import UIkit from 'uikit'
import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useBeforeunload } from 'react-beforeunload'
import { RootState } from '../../stores'
import { Company } from '../../types'
import {
  setCompany,
  fetchMessageDeliveryConfig,
  saveMessageDeliveryConfig,
} from '../../stores/company'
import { useAppSelector, useAppDispatch } from '../../hooks'
import {
  fetchPvRetargetingTriggers,
  savePvRetargetingTriggers,
  activateAllTriggers,
  setChangeSaved,
} from '../../stores/pvRetargetingTrigger'
import TriggerSettingForm from './TriggerSettingForm'
import CardObjectForm from './CardObjectForm'
import AdvancedSettingsForm from './AdvancedSettingsForm'
import { fetchShopItems } from 'components/Dashboard/stores/shopItem'
import MessageDeliveryConfigForm from '../shared/MessageDeliveryConfigForm'
import BrowserConnectedCount from '../shared/BrowserConnectedCount'
import Switch from '../shared/Switch'
import { ResponsiveBreakpoint } from '../../const'

interface Props {
  company: Company
}

const App = ({ company }: Props) => {
  const dispatch = useAppDispatch()
  const triggers = useAppSelector(
    (state: RootState) => state.pvRetargetingTrigger.list
  )
  const triggersChangeSaved = useAppSelector(
    (state: RootState) => state.pvRetargetingTrigger.changeSaved
  )
  const { messageDeliveryConfigChangeSaved } = useAppSelector(
    (state: RootState) => state.company
  )
  const [settingsActive, setSettingsActive] = useState(false)

  useBeforeunload(event => {
    if (!triggersChangeSaved || !messageDeliveryConfigChangeSaved) {
      event.preventDefault()
    }
  })

  useEffect(() => {
    dispatch(setCompany(company))
    dispatch(fetchPvRetargetingTriggers(company))
    dispatch(fetchMessageDeliveryConfig(company))
    dispatch(fetchShopItems(company))
  }, [])

  useEffect(() => {
    if (triggers.length > 0) {
      const isActive = triggers.some(trigger => trigger.status === 'active')
      setSettingsActive(isActive)
    }
  }, [triggers.length])

  const handleChangeSettingsActive = () => {
    const newIsActive = !settingsActive
    setSettingsActive(newIsActive)
    dispatch(setChangeSaved(false))
    // 全てのtriggerがinactiveな状態のときに「設定を有効にする」とした場合は全てのtriggerをacitveにする
    if (
      newIsActive &&
      triggers.every(trigger => trigger.status === 'inactive')
    ) {
      dispatch(activateAllTriggers())
    }
  }

  const handleSubmitTriggers = () => {
    dispatch(saveMessageDeliveryConfig(company))
    dispatch(savePvRetargetingTriggers(company, settingsActive))
      .then(() => {
        UIkit.notification({
          message: "<span uk-icon='icon: check'></span> 設定を保存しました",
          pos: 'top-center',
          status: 'success',
        })
      })
      .catch(() => {
        UIkit.notification({
          message: 'システムエラーで保存ができませんでした',
          pos: 'top-center',
          status: 'danger',
        })
      })
  }

  return (
    <div id="app-root">
      <div className="uk-margin-medium-top">
        <div className="uk-h5 uk-margin-remove">
          商品閲覧リマインド配信が可能な友だち数
        </div>
        <div className="uk-margin-small-top">
          <BrowserConnectedCount company={company} />
        </div>
      </div>
      <div className="uk-section-small">
        <h2 className="uk-h4">配信レポート</h2>
        <a
          href={`/companies/${company.slug}/pv_retargeting_triggers`}
          className="uk-button uk-button-default"
        >
          レポート詳細
        </a>
      </div>
      {triggers.length > 0 ? (
        <div className="uk-section-xsmall">
          <div className="uk-margin-bottom">
            <h2 className="uk-h4">配信設定を行う</h2>
            <div className="uk-margin-medium-bottom">
              <label>
                <div className="uk-flex uk-flex-middle">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settingsActive}
                        onChange={handleChangeSettingsActive}
                      />
                    }
                    label="「閲覧商品リマインド配信」を有効にする"
                  />
                </div>
              </label>
            </div>
          </div>
          {settingsActive && (
            <>
              <div className="uk-margin-medium-bottom">
                <div className="uk-h5 uk-text-bold">配信タイミング</div>
                <div className="uk-margin-bottom">
                  {triggers.map(trigger => {
                    const disableDays = triggers
                      .filter(t => t.id !== trigger.id)
                      .map(t => t.waitMinutes / (24 * 60))
                    return (
                      <TriggerSettingForm
                        key={trigger.id}
                        trigger={trigger}
                        disableDays={disableDays}
                      />
                    )
                  })}
                </div>
                <div>
                  <MessageDeliveryConfigForm />
                </div>
              </div>
              <div className="uk-margin-medium-bottom">
                <AdvancedSettingsForm />
              </div>
              <div className="uk-margin-small-top">
                <div className="uk-margin-small-bottom">
                  <div className="uk-h5 uk-text-bold uk-margin-bottom">
                    メッセージ内容
                  </div>
                  <div>
                    {triggers.map((trigger, index) => (
                      <CardObjectForm
                        key={trigger.id}
                        trigger={trigger}
                        index={index}
                        company={company}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
          <FormFooter>
            {settingsActive &&
            triggers.some(
              trigger =>
                trigger.status === 'active' && trigger.errorMessages.length > 0
            ) ? (
              <>
                <div className="uk-text-small uk-text-danger uk-margin-small-bottom">
                  入力内容に不備があります。
                </div>
                <button
                  className="uk-button uk-button-primary uk-button-large"
                  disabled={true}
                >
                  変更を保存する
                </button>
              </>
            ) : (
              <button
                onClick={handleSubmitTriggers}
                className={`uk-button uk-button-large uk-button-${
                  !triggersChangeSaved || !messageDeliveryConfigChangeSaved
                    ? 'primary'
                    : 'gray'
                }`}
              >
                変更を保存する
              </button>
            )}
          </FormFooter>
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default App

const FormFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 300px;
  right: 0;
  text-align: center;
  background: #f8f8f8;
  padding: 16px;
  border-top: 1px solid #e5e5e5;

  @media screen and (max-width: ${ResponsiveBreakpoint.large - 1}px) {
    left: 260px;
  }

  @media screen and (max-width: ${ResponsiveBreakpoint.medium - 1}px) {
    left: 0;
  }
`
