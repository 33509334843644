import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  videoUrl: string | null
}

const Video = ({ videoUrl }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  useEffect(() => {
    const videoElement = videoRef.current

    if (videoElement) {
      // iOSで自動で読み込みしないので要素取得して読込みしている
      videoElement.load()
      // loadeddataだとiOSで動作しないのでloadedmetadataを使用
      videoElement.addEventListener('loadedmetadata', () => {
        setIsVideoLoaded(true)
      })
    }
  }, [])

  return (
    <VideoMessageContainer isVideoLoaded={isVideoLoaded}>
      <VideoMessage controls preload="auto" ref={videoRef}>
        <source src={videoUrl || ''} type="video/mp4" />
      </VideoMessage>
    </VideoMessageContainer>
  )
}

export default Video

const VideoMessageContainer = styled.div<{ isVideoLoaded: boolean }>`
  .vsc-controller {
    display: none;
  }
  height: ${props => (props.isVideoLoaded ? 'auto' : '400px')};
`

const VideoMessage = styled.video`
  max-height: 400px;
  width: 100%;
`
