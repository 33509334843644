$(function () {
  $('#tag-check-btn').on('click', function (e) {
    e.preventDefault()
    $('#overlay').removeClass('uk-hidden')

    const shopUrl = $(this).data('shop-url')
    const slug = $(this).data('company-slug')
    const iframe = $(
      `<iframe src="${shopUrl}" width="0" height="0" style="position:fixed"></iframe>`
    )
    iframe.on('load', function () {
      setTimeout(() => {
        window.dataLayer = window.dataLayer || []
        $.ajax({
          type: 'POST',
          url: `/companies/${slug}/tag-check`,
          success: function (data) {
            if (data.result === 'success') {
              window.dataLayer.push({ event: 'user_tag_check_success' })
              location.href = `/companies/${slug}/tag-installed`
            } else {
              window.dataLayer.push({ event: 'user_tag_check_failed' })
              alert(
                'トラッキングタグの設置を確認できませんでした。設置方法をご確認の上、再度お試しください。\n設置されているにも関わらず、完了できない場合はお手数ですがお問い合わせください。'
              )
            }
          },
          complete: function () {
            $('#overlay').addClass('uk-hidden')
          },
        })
      }, 10000)
    })
    $('body').append(iframe)
  })
})
