import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'
import { AppDispatch, RootState } from '.'
import { Company, RestockTrigger } from '../types'

interface RestockTriggerState {
  trigger: RestockTrigger | null
  changeSaved: boolean
}

const initialState: RestockTriggerState = {
  trigger: null,
  changeSaved: true,
}

const slice = createSlice({
  name: 'restockTrigger',
  initialState,
  reducers: {
    setTrigger: (state, action) => {
      state.trigger = action.payload
      state.changeSaved = true
    },
    updateTrigger: (state, action) => {
      state.trigger = action.payload
      state.changeSaved = false
    },
    setChangeSaved: (state, action) => {
      state.changeSaved = action.payload
    },
  },
})

export default slice.reducer

export const { setTrigger, updateTrigger, setChangeSaved } = slice.actions

export function fetchRestockTrigger(company: Company) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/restock_trigger`
      )
      dispatch(setTrigger(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export function saveRestockTrigger(company: Company) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const { trigger } = getState().restockTrigger
    if (!trigger) return

    const restockTriggerParams = {
      status: trigger.status,
      restockNotificationMessageAttributes: trigger.restockNotificationMessage,
      // restockNotificationMessageAttributes: (({
      //   id,
      //   notification_title,
      //   notification_text,
      //   notification_button_text notification_button_color_code subscribed_title subscribed_text subscribed_button_text subscribed_button_color_code
      // }) => ({ id, title, text, buttonText, buttonColorCode }))(
      //   trigger.restockMessage
      // ),
    }
    const data = snakecaseKeys({
      restockTrigger: restockTriggerParams,
      authenticityToken: $('#authenticity_token').val(),
    })
    const response = await axios.put(
      `/api/companies/${company.slug}/restock_trigger`,
      data
    )
    dispatch(setTrigger(response.data))
  }
}
