import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

interface Props {
  onChange: (file: File) => void
}

const ImageDropInput = ({ onChange }: Props) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (file) onChange(file)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  })

  return (
    <div
      {...getRootProps()}
      className="uk-section-small uk-section-muted uk-flex uk-flex-column uk-flex-center uk-flex-middle"
      style={{ opacity: isDragActive ? 0.8 : 1 }}
    >
      <input {...getInputProps()} />
      <div className="uk-hidden@s">
        <div className="uk-text-small uk-margin-bottom">
          画像が選択されていません
        </div>
      </div>
      <div className="uk-visible@s uk-text-center">
        <div>ここにファイルをドラッグ＆ドロップ</div>
        <div className="uk-text-small uk-margin-small">または</div>
      </div>
      <div className="uk-button uk-button-primary">画像を選択する</div>
    </div>
  )
}
export default ImageDropInput
