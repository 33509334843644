import { createSlice } from '@reduxjs/toolkit'
import { Company } from '../types'

interface CompanyState {
  // FIXME: rails側からcompanyを渡してるので基本ある前提だが、initialStateがnullのためnullableになっている
  // componentのmount時にrails側から渡ってきたcompanyをinitialStateとしてstoreにsetしたい
  company: Company | null
  isAdmin: boolean
  messageSendable: boolean
}

const initialState: CompanyState = {
  company: null,
  isAdmin: false,
  messageSendable: false,
}

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload
    },
    setMessageSendable: (state, action) => {
      state.messageSendable = action.payload
    },
  },
})

export default slice.reducer

export const { setCompany, setIsAdmin, setMessageSendable } = slice.actions
