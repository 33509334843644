import React from 'react'
import styled from 'styled-components'
import ReactCrop from 'react-image-crop'

// 参考: https://codesandbox.io/s/lucid-lalande-72py4jlll6?from-embed=&file=/public/index.html

class CropProfileImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      src: null,
      crop: {
        unit: '%',
        aspect: 1,
        x: 0,
        y: 0,
        width: 85,
      },
      displayCropEditor: false,
    }
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      )
      reader.readAsDataURL(e.target.files[0])
    }
    this.setState({ displayCropEditor: true })
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop)
  }

  onCropChange = (crop, _percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop })
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve, _reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty')
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  getCropImgScale(image) {
    return image ? image.naturalWidth / image.width : 1
  }

  render() {
    const { defaultImage, modelName } = this.props
    const { crop, croppedImageUrl, src, displayCropEditor } = this.state
    const scale = this.getCropImgScale(this.imageRef)
    return (
      <Wrapper>
        <PreviewImage src={croppedImageUrl || defaultImage} />
        {displayCropEditor ? (
          <CropEditorWrapper>
            <h4>サイズ調整</h4>
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            <p className="uk-text-center">
              <button
                className="uk-button uk-button-default uk-button-small"
                type="button"
                onClick={() => this.setState({ displayCropEditor: false })}
              >
                保存する
              </button>
            </p>
          </CropEditorWrapper>
        ) : (
          <div className="uk-flex uk-flex-center">
            {croppedImageUrl && (
              <div
                className="uk-button uk-button-default uk-button-small uk-margin-small-right"
                onClick={() => this.setState({ displayCropEditor: true })}
              >
                サイズ調整
              </div>
            )}
            <label>
              <input
                type="file"
                accept="image/*"
                onChange={this.onSelectFile}
                style={{ display: 'none' }}
              />
              <div className="uk-button uk-button-default uk-button-small">
                プロフィール画像を変更する
              </div>
            </label>
          </div>
        )}
        <input
          type="hidden"
          name={`${modelName}[profile_image]`}
          value={src || ''}
        />
        <input
          type="hidden"
          name={`${modelName}[image_x]`}
          value={crop.x * scale}
        />
        <input
          type="hidden"
          name={`${modelName}[image_y]`}
          value={crop.y * scale}
        />
        <input
          type="hidden"
          name={`${modelName}[image_w]`}
          value={crop.width * scale}
        />
        <input
          type="hidden"
          name={`${modelName}[image_h]`}
          value={(crop.height || 0) * scale}
        />
      </Wrapper>
    )
  }
}

export default CropProfileImage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`

const PreviewImage = styled.img`
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 12px;
  border: 1px solid #ddd;
`

const CropEditorWrapper = styled.div`
  max-width: 480px;
  background: #f8f8f8;
  padding: 20px 20px 0;
`
