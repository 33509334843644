import React from 'react'
import * as snakecaseKeys from 'snakecase-keys'
import { Company, PvRetargetingTrigger } from '../../types'
import axios from 'axios'
import SendingTestModalComponent from '../shared/SendingTestModalComponent'

interface Props {
  company: Company
  rootId: string
  trigger: PvRetargetingTrigger
}

declare const window: Window['window'] & {
  dataLayer: object[]
}

const SendingTestModal = ({ rootId, trigger, company }: Props) => {
  const sendTestMessage = async () => {
    const data = snakecaseKeys({
      authenticityToken: $('#authenticity_token').val(),
      pushMessageCardObject: trigger.pushMessageCardObject,
    })
    return axios
      .post(
        `/api/companies/${company.slug}/pv_retargeting_triggers/${trigger.id}/send_test_message`,
        data
      )
      .then(_res => {
        window.dataLayer.push({ event: 'user_test_pv_retargeting_msg_sent' })
      })
  }

  return (
    <SendingTestModalComponent
      company={company}
      rootId={rootId}
      sendMessage={sendTestMessage}
      errorMessages={trigger.errorMessages}
      sentComponentWarning={
        trigger.pushMessageCardObject.limitedStockSetting && (
          <>
            ※
            テスト配信では必ず1つ目の商品が「在庫数でラベル切り替え」が適用された表示になります。
            <br />
          </>
        )
      }
    />
  )
}

export default SendingTestModal
