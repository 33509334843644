import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface Props {
  shopConnected: boolean
  browserConnected: boolean
  shopAccountType: 'base' | 'shopify' | 'makeshop' | 'stores'
  style: CSSProperties
  type: 'line' | 'shop'
}

const CustomerConnectedStatusIcon = ({
  shopConnected,
  browserConnected,
  shopAccountType,
  style,
  type,
}: Props) => {
  return (
    <StatusIcon
      style={style}
      type={type}
      shopConnected={shopConnected}
      browserConnected={browserConnected}
      shopAccountType={shopAccountType}
    >
      {type === 'shop' && (shopConnected || !browserConnected) && (
        <ConnectedShopIcon>ID</ConnectedShopIcon>
      )}
      {type === 'line'
        ? 'LINE'
        : {
            base: 'BASE',
            shopify: 'Shopify',
            makeshop: 'makeshop',
            stores: 'STORES',
          }[shopAccountType]}
    </StatusIcon>
  )
}

const StatusIcon = styled.div<{
  type: 'line' | 'shop'
  shopConnected: boolean
  browserConnected: boolean
  shopAccountType: 'base' | 'shopify' | 'makeshop' | 'stores'
}>`
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  width: ${({ shopAccountType }) =>
    shopAccountType === 'makeshop' ? '85px' : '70px'};
  color: #fff;
  background-color: ${({ type }) => (type === 'line' ? '#06c755' : '#252525')};
  opacity: ${({ type, shopConnected, browserConnected }) =>
    !shopConnected && !browserConnected && type === 'shop' ? 0.2 : 1};
`

const ConnectedShopIcon = styled.span`
  background-color: #fff;
  color: #252525;
  border-radius: 3px;
  font-weight: bold;
  padding: 0 2px 0 2px;
  margin-right: 3px;
`
export default CustomerConnectedStatusIcon
