import React from 'react'
import styled from 'styled-components'

const Button = ({ theme, ...props }) => (
  <ButtonElement
    className={`uk-button uk-button-${theme || 'default'}`}
    {...props}
  />
)

export default Button

const ButtonElement = styled.div``
