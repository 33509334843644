$(function () {
  let num = $('.push-message-image-field').length
  const $addButtonWrapper = $('#add-button-wrapper')
  if (num >= 2) $addButtonWrapper.hide()

  // f.fields_for の仕様で最初にダミーをセットしてあるので削除
  const $dummy = $('#dummy-image-object')
  if ($dummy.length > 0) $dummy.remove()

  $('#add-image-button').on('click', function () {
    const parent = $(this).data('parent')
    const element = `
      <div class="push-message-image-field uk-margin-top">
        <div class="uk-card uk-card-default uk-card-body">
          <div class="uk-margin-small-bottom ">
            <input
              class="js-preview-image-input"
              data-id="${num}"
              type="file"
              name="${parent}[push_message_image_objects_attributes][${num}][image]"
              id="${parent}_push_message_image_objects_attributes_${num}_image">
          </div>
          <img class="js-preview-image-tag--${num}" src="">
          <div class="uk-margin-small-top">
            <label for="uri" class="uk-form-label">画像タップで開くページ（任意）</label>
            <span
              style="width: 16px"
              uk-icon="question"
              className="hint--top hint--large hint--rounded"
              aria-label="以下にURLを登録すると、お客さまが画像をタップした際にそのURLのページを開きます"
            </span>
            <input
              class="uk-input uk-form-small"
              placeholder="https://..."
              type="text"
              name="${parent}[push_message_image_objects_attributes][${num}][uri]"
              id="${parent}_push_message_image_objects_attributes_${num}_uri">
          </div>
          <div class='uk-margin-top uk-text-center'>
            <div class='uk-button uk-button-default uk-button-small remove-image-button'>削除</div>
          </div>
        </div>
      </div>
    `
    $(element).insertBefore('#add-button-wrapper')
    if ($('.push-message-image-field').length >= 2) $addButtonWrapper.hide()
    num++
  })

  $('#push-message-image-area').on(
    'click',
    '.remove-image-button',
    function () {
      const $parent = $(this).parents('.push-message-image-field')
      const persisted = $(this).data('persisted')
      if (persisted) {
        $(this).prev().prop('checked', true)
        $parent.hide()
      } else {
        $parent.remove()
      }
      $addButtonWrapper.show()
    }
  )
})
