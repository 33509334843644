import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

const UnsupportedMessage = ({ children }: Props) => {
  return <Container>{children}</Container>
}

export default UnsupportedMessage

const Container = styled.div`
  background-color: #999;
  color: #fff;
  font-size: 13px;
  border-radius: 8px;
  padding: 8px 12px;
`
