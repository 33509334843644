import React from 'react'
import _ from 'lodash'
import { Category } from 'types.ts/Category'

type Props = {
  value: string
  setValue: (value: string) => void
  categoryList: Category[]
}

const ShopCategorySelect = ({ value, setValue, categoryList }: Props) => {
  const categoryWithChildren = (parent: Category, recursive: boolean) => {
    const children = categoryList.filter(c => c.parentNumber === parent.number)
    if (recursive) {
      children.map(root => categoryWithChildren(root, false))
    }
    parent.children = _.sortBy(children, 'listOrder')
    return parent
  }
  const rootCategories = _.sortBy(
    categoryList
      .filter(c => c.isRootCategory)
      .map(c => categoryWithChildren(c, true)),
    'listOrder'
  )
  const options: {
    value: number
    name: string
  }[] = []
  const pushCategories = (
    categories: Category[] | undefined,
    depth: number
  ) => {
    categories?.forEach(c => {
      // NOTE: selectタグ内で3段階層に表示する良い方法がないため、全角スペースでごまかしている
      options.push({
        value: c.categoryId,
        name: `${'　'.repeat(depth - 1)}${c.name}`,
      })
      if (depth <= 2) {
        pushCategories(c.children, depth + 1)
      }
    })
  }
  pushCategories(rootCategories, 1)

  return (
    <select
      value={value}
      onChange={e => setValue(e.currentTarget.value)}
      className="uk-select uk-text-small"
    >
      <option value="">選択してください</option>
      {options.map(opt => (
        <option value={opt.value} key={opt.value}>
          {opt.name}
        </option>
      ))}
    </select>
  )
}

export default ShopCategorySelect
