import React from 'react'

const DisplayTextArea = ({ value, onChange }) => {
  return (
    <div className="uk-margin">
      <div className="uk-margin-small-bottom">
        <label
          className="uk-form-label uk-text-bold"
          htmlFor="shop_popup_display_text"
        >
          表示メッセージ
          <span className="required-icon">必須</span>
        </label>
      </div>
      <textarea
        required="required"
        className="uk-textarea uk-form-small"
        style={{ height: 94 }}
        name="shop_popup[display_text]"
        id="shop_popup_display_text"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
}

export default DisplayTextArea
