import React, { useEffect, useState } from 'react'
import UIkit from 'uikit'
import { useModal } from 'react-hooks-use-modal'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { RootState } from '../../stores'
import { saveCardTypeMessage } from 'components/Dashboard/stores/cardTypeMessage'
import { CardTypeMessage } from 'components/Dashboard/types'
import { CardTypeMessageLayoutOptions } from 'components/Dashboard/const'

interface Props {
  resetContentsFormValue: () => void
}

const BasicSettingsForm = ({ resetContentsFormValue }: Props) => {
  const dispatch = useAppDispatch()
  const { company } = useAppSelector((state: RootState) => state.company)
  const { cardTypeMessage } = useAppSelector(
    (state: RootState) => state.cardTypeMessage
  )
  const [Modal, openModal, closeModal] = useModal('app-root', {
    closeOnOverlayClick: true,
  })
  const [titleValue, setTitleValue] = useState<string>('')
  const [layoutValue, setLayoutValue] = useState<CardTypeMessage['layout']>()

  const isTitleValid = titleValue.length > 0 && titleValue.length <= 100

  useEffect(() => {
    if (cardTypeMessage && !titleValue) {
      setTitleValue(cardTypeMessage.title)
    }
    if (cardTypeMessage && !layoutValue) {
      setLayoutValue(cardTypeMessage.layout)
    }
  }, [cardTypeMessage])

  const submitTitle = () => {
    if (isTitleValid && company && cardTypeMessage) {
      dispatch(
        saveCardTypeMessage(company, cardTypeMessage.id, { title: titleValue })
      )
        .then(() => {
          UIkit.notification({
            message:
              "<span uk-icon='icon: check'></span> タイトルを更新しました",
            pos: 'top-center',
            status: 'success',
            timeout: 2000,
          })
        })
        .catch(() => {
          UIkit.notification({
            message: 'システムエラーで保存ができませんでした',
            pos: 'top-center',
            status: 'danger',
            timeout: 2000,
          })
        })
    }
  }

  const submitChangeLayout = () => {
    const selectLayoutOption = CardTypeMessageLayoutOptions.find(
      option => option.key === layoutValue
    )
    if (!selectLayoutOption || !company || !cardTypeMessage) return

    if (
      window.confirm(
        `カードタイプを「${selectLayoutOption.label}」に変更しますか？（作成済みのカードはすべて削除されます。）`
      )
    ) {
      dispatch(
        saveCardTypeMessage(company, cardTypeMessage.id, {
          layout: layoutValue,
        })
      )
        .then(() => {
          resetContentsFormValue()
          UIkit.notification({
            message:
              "<span uk-icon='icon: check'></span> カードタイプを更新しました",
            pos: 'top-center',
            status: 'success',
            timeout: 2000,
          })
        })
        .catch(() => {
          UIkit.notification({
            message: 'システムエラーで保存ができませんでした',
            pos: 'top-center',
            status: 'danger',
            timeout: 2000,
          })
        })
        .finally(() => {
          closeModal()
        })
    }
  }

  if (!company || !cardTypeMessage) return <div />

  const activeLayoutOption = CardTypeMessageLayoutOptions.find(
    option => option.key === cardTypeMessage.layout
  )

  return (
    <div>
      <div className="uk-flex uk-flex-top">
        <div className="uk-width-small uk-margin-small-top uk-text-secondary">
          タイトル
        </div>
        <div>
          <div className="uk-flex uk-flex-middle uk-flex">
            <input
              type="text"
              className="uk-input uk-margin-small-right uk-width-large"
              value={titleValue}
              onChange={e => setTitleValue(e.target.value)}
            />
            {cardTypeMessage.title != titleValue && (
              <button
                className={`uk-button uk-button-${
                  isTitleValid ? 'primary' : 'default'
                } uk-button-small uk-flex-none`}
                onClick={submitTitle}
                disabled={!isTitleValid}
              >
                保存
              </button>
            )}
          </div>
          {titleValue.length > 100 && (
            <div className="uk-text-small uk-text-danger uk-margin-xsmall-top">
              タイトルは100文字以内で入力してください。
            </div>
          )}
        </div>
      </div>
      <div className="uk-flex uk-flex-middle uk-margin-top">
        <div className="uk-width-small uk-text-secondary">カードタイプ</div>
        <div className="uk-flex uk-flex-middle">
          <div>{activeLayoutOption?.label}</div>
          <button
            className="uk-button uk-button-default uk-button-small uk-margin-left"
            onClick={openModal}
          >
            変更する
          </button>
        </div>
      </div>
      <Modal>
        <div className="uk-card uk-card-default uk-card-body uk-width-xlarge">
          <div className="uk-text-secondary uk-margin-small-bottom">
            カードタイプの変更
          </div>
          <div className="uk-grid uk-flex-center">
            {CardTypeMessageLayoutOptions.map(layoutOption => {
              const active = layoutOption.key === cardTypeMessage.layout
              return (
                <div key={layoutOption.key}>
                  <label
                    className="uk-flex uk-flex-column uk-flex-middle uk-width-small "
                    htmlFor={`card_type_message_layout_${layoutOption.key}`}
                  >
                    <div className="uk-text-small uk-text-danger">
                      {/* NOTE: レイアウト調整のため空白文字を挿入 */}
                      {active ? '現在適用中' : '\u00A0'}
                    </div>
                    <img
                      src={layoutOption.imageUrl}
                      style={active ? { border: '2px solid #f0506e' } : {}}
                    />
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-margin-small-top">
                      <input
                        className="uk-radio uk-margin-xsmall-right"
                        type="radio"
                        value={layoutOption.key}
                        checked={layoutValue === layoutOption.key}
                        onChange={() => setLayoutValue(layoutOption.key)}
                        id={`card_type_message_layout_${layoutOption.key}`}
                      />
                      {layoutOption.label}
                    </div>
                  </label>
                </div>
              )
            })}
          </div>
          <div className="uk-margin-medium-top">
            {cardTypeMessage.layout === layoutValue ? (
              <div className="uk-text-center">
                <button
                  className="uk-button uk-button-text"
                  onClick={closeModal}
                >
                  キャンセル
                </button>
              </div>
            ) : (
              <div className="uk-flex uk-flex-column uk-flex-middle">
                {/* TODO: カードが1枚もない場合は出さないように */}
                <div className="uk-text-bold uk-text-small uk-margin-bottom">
                  ※
                  レイアウトを変更した場合、作成済みのカードはすべて削除されます。
                </div>
                <button
                  className="uk-button uk-button-default uk-margin-bottom"
                  onClick={submitChangeLayout}
                >
                  変更する
                </button>
                <button
                  className="uk-button uk-button-text"
                  onClick={closeModal}
                >
                  キャンセル
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BasicSettingsForm
