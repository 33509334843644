import { CardTypeMessage } from './types'

export const ResponsiveBreakpoint = {
  small: 640,
  medium: 960,
  large: 1200,
}

export const RichMenuTemplates = [
  {
    id: 'large_1',
    columns: 3,
    rows: 2,
    areas: [
      { x: [0], y: [0] },
      { x: [1], y: [0] },
      { x: [2], y: [0] },
      { x: [0], y: [1] },
      { x: [1], y: [1] },
      { x: [2], y: [1] },
    ],
  },
  {
    id: 'large_2',
    columns: 2,
    rows: 2,
    areas: [
      { x: [0], y: [0] },
      { x: [1], y: [0] },
      { x: [0], y: [1] },
      { x: [1], y: [1] },
    ],
  },
  {
    id: 'large_3',
    columns: 3,
    rows: 2,
    areas: [
      { x: [0, 1, 2], y: [0] },
      { x: [0], y: [1] },
      { x: [1], y: [1] },
      { x: [2], y: [1] },
    ],
  },
  {
    id: 'large_4',
    columns: 3,
    rows: 2,
    areas: [
      { x: [0, 1], y: [0, 1] },
      { x: [2], y: [0] },
      { x: [2], y: [1] },
    ],
  },
  {
    id: 'large_5',
    columns: 1,
    rows: 2,
    areas: [
      { x: [0], y: [0] },
      { x: [0], y: [1] },
    ],
  },
  {
    id: 'large_6',
    columns: 2,
    rows: 1,
    areas: [
      { x: [0], y: [0] },
      { x: [1], y: [0] },
    ],
  },
  {
    id: 'large_7',
    columns: 1,
    rows: 1,
    areas: [{ x: [0], y: [0] }],
  },
  {
    id: 'small_1',
    columns: 3,
    rows: 1,
    areas: [
      { x: [0], y: [0] },
      { x: [1], y: [0] },
      { x: [2], y: [0] },
    ],
  },
  {
    id: 'small_2',
    columns: 3,
    rows: 1,
    areas: [
      { x: [0], y: [0] },
      { x: [1, 2], y: [0] },
    ],
  },
  {
    id: 'small_3',
    columns: 3,
    rows: 1,
    areas: [
      { x: [0, 1], y: [0] },
      { x: [2], y: [0] },
    ],
  },
]

export const RichMenuLayoutImages = [
  {
    id: 'large_1',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/large_1.svg',
  },
  {
    id: 'large_2',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/large_2.svg',
  },
  {
    id: 'large_3',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/large_3.svg',
  },
  {
    id: 'large_4',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/large_4.svg',
  },
  {
    id: 'large_5',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/large_5.svg',
  },
  {
    id: 'large_6',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/large_6.svg',
  },
  {
    id: 'large_7',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/large_7.svg',
  },
  {
    id: 'small_1',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/small_1.svg',
  },
  {
    id: 'small_2',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/small_2.svg',
  },
  {
    id: 'small_3',
    url: 'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/rich_menu/small_3.svg',
  },
]

export const ShopItemDefaultImage =
  'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/shop/shop_item_default.png'

export const ShopItemDefaultImage3x2 =
  'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/shop/shop_item_default_3x2.png'

export const CardTypeMessageLayoutOptions: {
  key: CardTypeMessage['layout']
  label: string
  imageUrl: string
}[] = [
  {
    key: 'image',
    label: '画像のみ',
    imageUrl:
      'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/card_type_message/layout_image.png',
  },
  {
    key: 'product',
    label: '画像+商品情報',
    imageUrl:
      'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/card_type_message/layout_product.png',
  },
]

export const CardTypeMessageLastCardLayoutOptions: {
  key: 'simple' | 'image'
  label: string
  imageUrl: string
}[] = [
  {
    key: 'simple',
    label: 'シンプル',
    imageUrl:
      'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/card_type_message/last_card_layout_simple.png',
  },
  {
    key: 'image',
    label: '画像',
    imageUrl:
      'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/card_type_message/last_card_layout_image.png',
  },
]
