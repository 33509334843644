import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Company, ShopItem } from '../../types'
import { useAppSelector, useResponsiveWindowSize } from '../../hooks'
import { RootState } from '../../stores'

const item_defult_img =
  'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/shop/shop_item_default.png'
const dummyItem: ShopItem = {
  title: 'テスト商品',
  img1Origin: item_defult_img,
  price: 1980,
  itemId: 0,
  variants: [],
  multiPrice: false,
}

interface Props {
  company: Company
}

const CartAbandonmentMessagePreview = ({ company }: Props) => {
  const useSingleItem = company.shopAccountType === 'base'
  const itemLimit = useSingleItem ? 1 : 2
  const responsiveWindowSize = useResponsiveWindowSize()
  const { trigger } = useAppSelector(
    (state: RootState) => state.cartAbandonmentTrigger
  )
  const buttonThemes = useAppSelector(
    (state: RootState) => state.flexMessageButtonTheme.list
  )
  const shopItems = useAppSelector((state: RootState) => state.shopItem.list)
  const [activeItems, setActiveItems] = useState<ShopItem[]>([])
  const displayItems = activeItems.length > 0 ? activeItems : [dummyItem]

  useEffect(() => {
    if (activeItems.length === 0 && shopItems.length > 0) setRandomActiveItems()
  }, [shopItems])

  if (!trigger) return <div />

  const setRandomActiveItems = () => {
    if (shopItems.length === 0) return

    if (shopItems.length <= itemLimit) {
      setActiveItems(shopItems)
      return
    }

    let items = [...shopItems]
    const randomItems = []
    for (let i = 0; i < itemLimit; i++) {
      const randomIndex = Math.floor(Math.random() * items.length)
      randomItems.push(items[randomIndex])
      items = items.filter((_, index) => index !== randomIndex)
    }
    setActiveItems(randomItems)
  }

  const { title, text, buttonText, buttonColorCode } =
    trigger.cartAbandonmentMessage
  const buttonStyle =
    buttonThemes.find(({ colorCode }) => colorCode === buttonColorCode)
      ?.style || 'primary'
  const textColorCode = {
    link: '42659a',
    primary: 'ffffff',
    secondary: '111111',
  }[buttonStyle]
  return (
    <div>
      {responsiveWindowSize === 'small' && (
        <div className="uk-text-small uk-margin-small-bottom">
          メッセージプレビュー
        </div>
      )}
      <div style={{ width: 290 }}>
        <Card>
          <CardTitle>{title}</CardTitle>
          <CardText>{text}</CardText>
          <CardItems>
            {displayItems.map(item => (
              <CardItemRow key={item.itemId}>
                <ItemImg src={item.img1Origin || item_defult_img} />
                <ItemTitle>{item.title}</ItemTitle>
              </CardItemRow>
            ))}
          </CardItems>
          {!useSingleItem && <CardOtherItem>その他の商品</CardOtherItem>}
          <CardButton
            style={{
              backgroundColor: `#${buttonColorCode}`,
              color: `#${textColorCode}`,
            }}
          >
            {buttonText}
          </CardButton>
        </Card>
        <div className="uk-flex uk-flex-column uk-flex-middle">
          {shopItems.length > 2 && (
            <a
              className="uk-margin-top uk-text-small uk-flex uk-flex-middle"
              onClick={setRandomActiveItems}
            >
              <span uk-icon="refresh" className="uk-margin-small-right" />
              表示商品をランダムに切り替える
            </a>
          )}
          <div
            className="uk-text-small uk-text-muted"
            style={{ marginTop: 16 }}
          >
            ※ 実際にはお客さまのカート内の商品が送信されます。
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartAbandonmentMessagePreview

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;

  * {
    box-sizing: border-box;
  }
`
const CardTitle = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`
const CardText = styled.div`
  font-size: 14px;
  white-space: pre-line;
`
const CardItems = styled.div`
  margin: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`
const CardItemRow = styled.div`
  margin: 12px 0;
  display: flex;
  align-items: center;
`
const ItemImg = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 16px;
`
const ItemTitle = styled.div`
  font-size: 14px;
  color: #333;
  font-weight: bold;
  max-height: 64px;
  overflow-y: hidden;
`
const CardOtherItem = styled.div`
  text-align: center;
  font-size: 13px;
  color: #777;
  margin-bottom: 20px;
`
const CardButton = styled.div`
  width: 100%;
  text-align: center;
  height: 44px;
  line-height: 44px;
  font-size: 15px;
  border-radius: 8px;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
