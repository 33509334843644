import React from 'react'
import UnsupportedMessage from './UnsupportedMessage'
import { useImage } from 'react-image'
import styled from 'styled-components'

interface Props {
  value: string | null
}

const Sticker = ({ value }: Props) => {
  const { src, error, isLoading } = useImage({
    srcList: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${value}/iPhone/sticker_key@2x.png`,
    useSuspense: false,
  })

  if (isLoading) {
    return <ImageLoading />
  }

  if (error) {
    return <UnsupportedMessage children="スタンプが送信されました" />
  }

  return <img src={src} />
}

export default Sticker

const ImageLoading = styled.div`
  width: 100%;
  height: 400px;
  background-color: #999;
`
