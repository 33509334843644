import React from 'react'
import styled from 'styled-components'
import { ResponsiveBreakpoint } from '../../const'
import { useAppSelector, useResponsiveWindowSize } from '../../hooks'
import { RootState } from '../../stores'
import MessageForm from './MessageForm'
import MessagePreview from './MessagePreview'
import SendingTestModal from './SendingTestModal'

interface Props {
  restockMessageType: 'notification' | 'subscribed'
}

const MessageEditor = ({ restockMessageType }: Props) => {
  const responsiveWindowSize = useResponsiveWindowSize()
  const { trigger } = useAppSelector((state: RootState) => state.restockTrigger)
  if (!trigger) return <div />

  return (
    <MessageContainer className="uk-flex">
      <PreviewWrapper>
        <MessagePreview restockMessageType={restockMessageType} />
        {responsiveWindowSize === 'small' && (
          <div className="uk-margin-top">
            <SendingTestModal
              rootId="app-root"
              restockMessageType={restockMessageType}
            />
          </div>
        )}
      </PreviewWrapper>
      <div className="uk-flex-1">
        <MessageForm restockMessageType={restockMessageType} />
        {responsiveWindowSize !== 'small' && (
          <div className="uk-margin-medium-top">
            <SendingTestModal
              rootId="app-root"
              restockMessageType={restockMessageType}
            />
          </div>
        )}
      </div>
    </MessageContainer>
  )
}

export default MessageEditor

const MessageContainer = styled.div`
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    flex-direction: column-reverse;
  }
`
const PreviewWrapper = styled.div`
  flex: none;
  margin-right: 40px;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    margin-right: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
