import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const BannerDescription = ({ bannerStyle, shopAccountType }) => {
  if (bannerStyle === 'rich_design_banner')
    return (
      <a
        className="text-underline"
        href={`/usage_guide_redirects/what_is_popup?shop_account_type=${shopAccountType}`}
        target="_blank"
        rel="noopener"
      >
        リッチデザインバナーとは？
        <OpenInNewIcon
          style={{
            fontSize: 16,
            verticalAlign: 'text-bottom',
            marginLeft: 4,
          }}
        />
      </a>
    )

  if (bannerStyle === 'line_icon_banner')
    return (
      <a
        className="text-underline"
        href={`/usage_guide_redirects/what_is_line_popup?shop_account_type=${shopAccountType}`}
        target="_blank"
        rel="noopener"
      >
        LINEアイコンバナーとは？
        <OpenInNewIcon
          style={{
            fontSize: 16,
            verticalAlign: 'text-bottom',
            marginLeft: 4,
          }}
        />
      </a>
    )

  return null
}

export default BannerDescription
