import { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from './stores'
import { ResponsiveBreakpoint } from './const'
import { ResponsiveWindowSize } from './types'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const useResponsiveWindowSize = (): ResponsiveWindowSize => {
  const [windowWidth] = useWindowSize()
  const [size, setSize] = useState<ResponsiveWindowSize>('small')
  useLayoutEffect(() => {
    if (windowWidth <= ResponsiveBreakpoint.small) {
      setSize('small')
    } else if (windowWidth <= ResponsiveBreakpoint.medium) {
      setSize('medium')
    } else if (windowWidth <= ResponsiveBreakpoint.large) {
      setSize('large')
    } else {
      setSize('xlarge')
    }
  }, [windowWidth])
  return size
}
