import React from 'react'
import _ from 'lodash'
import MessageObjectFormCard from './MessageObjectFormCard'
import {
  PushMessageFormObject,
  TextMessageReplaceKey,
} from 'types.ts/PushMessage'
import { Company } from 'types.ts/Company'
import { CardTypeMessage } from 'components/Dashboard/types'

interface Props {
  messageObjects: PushMessageFormObject[]
  onChange: (newMessageObjects: PushMessageFormObject[]) => void
  deletePersistedObject: (messageObject: PushMessageFormObject) => void
  addMessageObject: () => void
  company: Company
  availableKeywords: TextMessageReplaceKey[]
  cardTypeMessages: CardTypeMessage[]
}

const MessageObjectsEditor = ({
  messageObjects,
  onChange,
  deletePersistedObject,
  addMessageObject,
  company,
  availableKeywords,
  cardTypeMessages,
}: Props) => {
  const isEdited = (obj: PushMessageFormObject): boolean => {
    switch (obj.type) {
      case 'text':
        return !_.isEmpty(obj.text)
      case 'image':
        return !_.isNil(obj.file) || !_.isEmpty(obj.imageSrc)
      case 'imageWithLink':
        return (
          !_.isNil(obj.file) || !_.isEmpty(obj.imageSrc) || !_.isEmpty(obj.uri)
        )
      default:
        return false
    }
  }

  const removeMessageObject = (
    identifier: PushMessageFormObject['identifier']
  ) => {
    const messageObject = messageObjects.find(
      obj => obj.identifier === identifier
    )
    if (!messageObject) return

    if (
      isEdited(messageObject) &&
      !window.confirm('選択した吹き出しを削除しますか？')
    )
      return

    const newMessageObjects = messageObjects.filter(
      obj => obj.identifier !== identifier
    )
    onChange(newMessageObjects)

    const deletedObject = messageObjects.find(
      obj => obj.identifier === identifier
    )
    if (deletedObject && deletedObject.id) {
      deletePersistedObject(deletedObject)
    }
  }

  const changeMessageObjectOrder = (
    identifier: PushMessageFormObject['identifier'],
    direction: 'up' | 'down'
  ) => {
    const index = messageObjects.findIndex(obj => obj.identifier === identifier)
    if (index === -1) return

    const otherIndex = direction === 'up' ? index - 1 : index + 1
    if (otherIndex < 0 || otherIndex >= messageObjects.length) return

    const newObjects = messageObjects.map((obj, i) => ({ ...obj, order: i }))
    newObjects[index].order = otherIndex
    newObjects[otherIndex].order = index
    onChange(newObjects)
  }

  const setMessageObject = (
    identifier: PushMessageFormObject['identifier'],
    newObject: PushMessageFormObject
  ) => {
    const newMessageObjects = messageObjects.map(obj =>
      obj.identifier === identifier ? newObject : obj
    )
    onChange(newMessageObjects)
  }

  return (
    <div>
      <div>
        {messageObjects.map((messageObject, index) => {
          const { identifier } = messageObject
          return (
            <div key={identifier}>
              <MessageObjectFormCard
                messageObject={messageObject}
                index={index}
                onChange={newObject => setMessageObject(identifier, newObject)}
                remove={() => removeMessageObject(identifier)}
                changeOrder={direction =>
                  changeMessageObjectOrder(identifier, direction)
                }
                company={company}
                upIconActive={messageObjects.length !== 1 && index !== 0}
                downIconActive={
                  messageObjects.length !== 1 &&
                  index !== messageObjects.length - 1
                }
                availableKeywords={availableKeywords}
                cardTypeMessages={cardTypeMessages}
              />
            </div>
          )
        })}
      </div>
      {messageObjects.length < 5 && (
        <div className="uk-text-center uk-padding-small">
          <div
            className="uk-button uk-button-default uk-button-small"
            onClick={addMessageObject}
          >
            吹き出しを追加
          </div>
        </div>
      )}
    </div>
  )
}

export default MessageObjectsEditor
