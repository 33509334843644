import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useWatch } from 'react-hook-form'
import { RootState } from 'components/Dashboard/stores'
import { FormValues } from './FormRoot'
import {
  ShopItemDefaultImage,
  ShopItemDefaultImage3x2,
} from 'components/Dashboard/const'
import Slider, { CustomArrowProps } from 'react-slick'
import styled from 'styled-components'
import {
  ItemCardContentPreview,
  LastCardPreview,
  ProductCardContentPreview,
} from 'components/organisms/PushMessage/CardTypeMessageContentPreview'
import { setDisplayContentIndex } from 'components/Dashboard/stores/cardTypeMessage'

const NextArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props
  const disabled = className?.split(' ').includes('slick-disabled')
  if (disabled) return <div />

  return (
    <ArrowIconButton
      style={{ right: 10 }}
      className="material-icons"
      onClick={onClick}
    >
      chevron_right
    </ArrowIconButton>
  )
}

const PrevArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props
  const disabled = className?.split(' ').includes('slick-disabled')
  if (disabled) return <div />

  return (
    <ArrowIconButton
      style={{ left: 10 }}
      className="material-icons"
      onClick={onClick}
    >
      chevron_left
    </ArrowIconButton>
  )
}

const CardPreview = () => {
  const dispatch = useAppDispatch()
  const { cardTypeMessage, displayContentIndex } = useAppSelector(
    (state: RootState) => state.cardTypeMessage
  )
  const contents: FormValues['contents'] = useWatch({ name: 'contents' }) || []
  const lastCard: FormValues['lastCard'] = useWatch({ name: 'lastCard' })
  const sliderRef = useRef<Slider>(null)

  useEffect(() => {
    if (!sliderRef.current) return

    const goToIndex =
      displayContentIndex === 'last' ? contents.length : displayContentIndex
    sliderRef.current.slickGoTo(goToIndex)
  }, [displayContentIndex])

  if (!cardTypeMessage || contents.length === 0) return <div />

  const onChangeSlide = (_oldIndex: number, newIndex: number) => {
    if (newIndex < 0) return

    const displayIndex = newIndex === contents.length ? 'last' : newIndex
    dispatch(setDisplayContentIndex(displayIndex))
  }

  const sliderSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '29px', // スライド表示領域幅が`360px`のときにカードの幅が`280px`になるように調整
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: onChangeSlide,
  }

  const defaultImageUrl =
    cardTypeMessage.layout === 'product'
      ? ShopItemDefaultImage3x2
      : ShopItemDefaultImage

  return (
    <div>
      {/* NOTE: 各スライドの高さを一番大きいものに揃えるために無理やりスタイルを調整している（他にいい方法あれば変えたい） */}
      <style>{`
        .slick-track {
          display: flex;
          align-items: stretch;
        }
        .slick-slide {
          height: auto;
        }
        .slick-slide > div {
          height: 100%;
        }
        .slick-slide img {
          display: inline-block;
        }
      `}</style>
      <Slider ref={sliderRef} {...sliderSettings}>
        {contents.map((content, index) => {
          const previewContentProps = {
            ...content,
            price: Number(content.price),
            imageUrl: content.image.url || defaultImageUrl,
            actionButtonUrl: null, // 作成画面のプレビューではリンク化しない
          }
          return (
            <ContentWrapper key={index}>
              <ContentBorder>
                {cardTypeMessage.layout === 'product' && (
                  <ProductCardContentPreview {...previewContentProps} />
                )}
                {cardTypeMessage.layout === 'image' && (
                  <ItemCardContentPreview {...previewContentProps} />
                )}
              </ContentBorder>
            </ContentWrapper>
          )
        })}
        {lastCard.layout !== 'none' && (
          <ContentWrapper>
            <ContentBorder>
              <LastCardPreview
                imageUrl={lastCard.imageUrl}
                layout={lastCard.layout}
                text={lastCard.text}
                url={null}
              />
            </ContentBorder>
          </ContentWrapper>
        )}
      </Slider>
    </div>
  )
}

const ContentWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 0 10px;
`

const ContentBorder = styled.div`
  box-sizing: border-box;
  height: 100%;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`

const ArrowIconButton = styled.div`
  position: absolute;
  top: calc(50% - 24px);
  cursor: pointer;
  z-index: 10;
  width: 48px;
  height: 48px;
  color: #fff;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`

export default CardPreview
