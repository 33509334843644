import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import ReactLoading from 'react-loading'
import MessagePreview from '../organisms/PushMessage/MessagePreview'
import MessageObjectsEditor from '../organisms/PushMessage/MessageObjectsEditor'
import TriggerOrderTypeForm from 'components/organisms/PushMessage/TriggerOrderTypeForm'
import {
  checkFormObjectIsValid,
  convertDBObjectToFormObject,
  includeOcDomainUri,
} from '../../lib/MessageObjectUtil'
import { PushMessageTrigger } from 'types.ts/PushMessageTrigger'
import { Company } from 'types.ts/Company'
import {
  PushMessageFormCardTypeObject,
  PushMessageFormEmptyObject,
  PushMessageFormImageObject,
  PushMessageFormImageWithLinkObject,
  PushMessageFormObject,
  PushMessageFormTextObject,
  PushMessageTextObject,
  TextMessageReplaceKey,
} from 'types.ts/PushMessage'
import { CardTypeMessage } from 'components/Dashboard/types'

interface Props {
  company: Company
  trigger: PushMessageTrigger
  formType: 'order' | 'follow' | 'ship'
  addLineFriendTrackingId: number | null
}

type DeletedMessageIds = {
  text: number[]
  image: number[]
  cardType: number[]
}

const PushMessageTriggerForm = ({
  company,
  trigger,
  formType,
  addLineFriendTrackingId,
}: Props) => {
  const [waitDays, setWaitDays] = useState(0)
  const [waitHours, setWaitHours] = useState(0)
  const [waitMinutes, setWaitMinutes] = useState(0)
  const [statusActive, setStatusActive] = useState(true)
  const [triggerType, setTriggerType] =
    useState<PushMessageTrigger['triggerType']>()
  const [triggerValue, setTriggerValue] =
    useState<PushMessageTrigger['triggerValue']>('')

  const [messageObjects, setMessageObjects] = useState<PushMessageFormObject[]>(
    []
  )
  const [isSending, setIsSending] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [deletedMessageIds, setDeletedMessageIds] = useState<DeletedMessageIds>(
    {
      text: [],
      image: [],
      cardType: [],
    }
  )
  const [cardTypeMessages, setCardTypeMessages] = useState<CardTypeMessage[]>(
    []
  )
  const [addLineFriendTrackingTitle, setAddLineFriendTrackingTitle] = useState<
    string | null
  >(null)

  const [Modal, openModal, closeModal] = useModal('form-root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  })

  const isWelcomeMessage = formType === 'follow'

  const fetchCardTypeMessages = async () => {
    const response = await axios.get(
      `/api/companies/${company.slug}/card_type_messages`
    )
    setCardTypeMessages(response.data)
  }

  const fetchAddLineFriendTracking = async (
    addLineFriendTrackingId: number
  ) => {
    const response = await axios.get(
      `/api/companies/${company.slug}/add_line_friend_trackings/${addLineFriendTrackingId}`
    )
    return response.data
  }

  useEffect(() => {
    const defaultObjects = [getDefaultMessageObject()]
    setMessageObjects(defaultObjects)
    fetchCardTypeMessages()

    if (addLineFriendTrackingId) {
      fetchAddLineFriendTracking(addLineFriendTrackingId).then(data => {
        setAddLineFriendTrackingTitle(data.title)
      })
    }

    if (trigger) {
      setTriggerType(trigger.triggerType)
      if (trigger.triggerValue) setTriggerValue(trigger.triggerValue)

      const totalMin = trigger.waitMinutes || 0
      setWaitDays(Math.floor(totalMin / 24 / 60))
      const r = totalMin % (24 * 60)
      setWaitHours(Math.floor(r / 60))
      setWaitMinutes(r % 60)

      setStatusActive(trigger.status === 'active')

      if (trigger.pushMessageObjects.length > 0) {
        const formObjects: PushMessageFormObject[] =
          trigger.pushMessageObjects.map(pushMessageObject =>
            convertDBObjectToFormObject(pushMessageObject)
          )
        setMessageObjects(formObjects)
      }
    } else {
      if (formType === 'ship') {
        setTriggerType('order_shipped')
      } else if (formType === 'follow') {
        if (addLineFriendTrackingId) {
          setTriggerType('line_follow_tracking_route')
        } else {
          setTriggerType('line_follow')
        }
        // 新規作成 かつ line_follow はあいさつメッセージ新規登録で確定
        const defaultTextObject: PushMessageFormTextObject = {
          type: 'text',
          order: 0,
          text: '{Nickname}さん、はじめまして！\n友だち追加ありがとうございます。{AccountName}です。\n\nショップでのお買い物はこちらから → {ShopUrl}',
          identifier: (Math.random() + 1).toString(36).substring(7),
        }
        setMessageObjects([defaultTextObject])
      } else {
        setTriggerType('all_order')
      }
    }
  }, [])

  const availableKeywords: TextMessageReplaceKey[] = [
    'Nickname',
    'AccountName',
    'ShopUrl',
  ]
  if (
    company.shopAccountType === 'base' &&
    (formType === 'order' || formType === 'ship')
  )
    availableKeywords.push('ReviewUrl')
  if (company.shopAccountType === 'makeshop' && triggerType === 'order_shipped')
    availableKeywords.push('SlipNumber')

  const orderedMessageObjects = _.sortBy(messageObjects, 'order')

  const getDefaultMessageObject = (): PushMessageFormEmptyObject => ({
    type: null,
    order:
      messageObjects.length > 0
        ? (orderedMessageObjects.slice(-1)[0].order || 0) + 1
        : 0,
    identifier: (Math.random() + 1).toString(36).substring(7),
  })

  const addDefaultMessageObject = () => {
    if (messageObjects.length >= 5) return

    setMessageObjects([messageObjects, getDefaultMessageObject()].flat())
  }

  const deletePersistedObject = (deletedObject: PushMessageFormObject) => {
    if (!deletedObject.id) return

    const newIds = Object.assign({}, deletedMessageIds)
    if (deletedObject.type === 'text') {
      newIds.text.push(deletedObject.id)
    } else if (
      deletedObject.type === 'image' ||
      deletedObject.type === 'imageWithLink'
    ) {
      newIds.image.push(deletedObject.id)
    } else if (deletedObject.type === 'cardType') {
      newIds.cardType.push(deletedObject.id)
    }
    setDeletedMessageIds(newIds)
  }

  const validMessageObjects = orderedMessageObjects.filter(obj =>
    checkFormObjectIsValid(obj)
  )

  const messageUriValid = !includeOcDomainUri(orderedMessageObjects)

  const handleChangeMessageObjects = (newObjects: PushMessageFormObject[]) => {
    if (newObjects.length === 0) {
      setMessageObjects([getDefaultMessageObject()])
    } else {
      setMessageObjects(newObjects)
    }
  }

  const handleClickConfirmButton = () => {
    if (isWelcomeMessage) handleSubmit()
    openModal()
  }

  const handleSubmit = () => {
    if (!triggerType || isSending || !messageUriValid) return

    if (
      (triggerType.includes('item') || triggerType.includes('category')) &&
      !triggerValue
    )
      return

    setIsSending(true)
    const formData = new FormData()
    const authenticityToken = $('#authenticity_token').val() as string
    formData.append('authenticity_token', authenticityToken)
    formData.append(
      'deleted_message_object_ids[text]',
      String(deletedMessageIds.text)
    )
    formData.append(
      'deleted_message_object_ids[image]',
      String(deletedMessageIds.image)
    )
    formData.append(
      'deleted_message_object_ids[card_type]',
      String(deletedMessageIds.cardType)
    )
    formData.append(
      'push_message_trigger[wait_minutes]',
      String((waitDays * 24 + waitHours) * 60 + waitMinutes)
    )
    formData.append('push_message_trigger[trigger_type]', triggerType)
    if (triggerValue)
      formData.append('push_message_trigger[trigger_value]', triggerValue)
    formData.append(
      'push_message_trigger[status]',
      statusActive ? 'active' : 'inactive'
    )
    if (addLineFriendTrackingId)
      formData.append(
        'add_line_friend_tracking_id',
        String(addLineFriendTrackingId)
      )
    const textMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'text'
    ) as PushMessageTextObject[]
    textMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_trigger[push_message_text_objects_attributes]'
      formData.append(`${parent}[${index}][text]`, obj.text)
      formData.append(`${parent}[${index}][order]`, String(obj.order))
      if (obj.id) formData.append(`${parent}[${index}][id]`, String(obj.id))
    })
    const imageMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'image' || obj.type === 'imageWithLink'
    ) as (PushMessageFormImageObject | PushMessageFormImageWithLinkObject)[]
    imageMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_trigger[push_message_image_objects_attributes]'
      if (obj.file) formData.append(`${parent}[${index}][image]`, obj.file)
      if (obj.type === 'imageWithLink') {
        formData.append(`${parent}[${index}][uri]`, obj.uri || '')
        formData.append(`${parent}[${index}][alt_text]`, obj.altText || '')
      }
      formData.append(`${parent}[${index}][order]`, String(obj.order))
      if (obj.id) formData.append(`${parent}[${index}][id]`, String(obj.id))
    })
    const cardTypeMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'cardType'
    ) as PushMessageFormCardTypeObject[]
    cardTypeMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_trigger[push_message_card_type_objects_attributes]'
      formData.append(
        `${parent}[${index}][card_type_message_id]`,
        String(obj.cardTypeMessageId)
      )
      formData.append(`${parent}[${index}][alt_text]`, obj.altText || '')
      formData.append(`${parent}[${index}][order]`, String(obj.order))
      if (obj.id) formData.append(`${parent}[${index}][id]`, String(obj.id))
    })

    if (trigger) {
      const url = `/api/companies/${company.slug}/push_message_triggers/${trigger.id}`
      axios
        .put(url, formData)
        .then(_response => {
          setIsSaved(true)
          setIsSending(false)
        })
        .catch(error => {
          const { message } = error.response.data
          window.alert(message)
          setIsSending(false)
          closeModal()
        })
    } else {
      const url = `/api/companies/${company.slug}/push_message_triggers`
      axios
        .post(url, formData)
        .then(_response => {
          setIsSaved(true)
          setIsSending(false)

          const gaEvent = isWelcomeMessage
            ? 'user_greet_msg_registered'
            : 'user_trigger_msg_registered'
          // @ts-expect-error GTM用（活用していない）
          window.dataLayer.push({ event: gaEvent })
        })
        .catch(error => {
          const { message } = error.response.data
          window.alert(message)
          setIsSending(false)
          closeModal()
        })
    }
  }

  const ConfirmModal = (
    <Modal>
      <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
        {(() => {
          if (isSaved) {
            return (
              <div className="uk-text-center">
                <h4>登録が完了しました！</h4>
                <div>
                  {isWelcomeMessage ? (
                    <a
                      className="uk-button uk-button-primary"
                      href={`/companies/${company.slug}/line_follow_trigger_messages`}
                    >
                      あいさつメッセージの設定へ
                    </a>
                  ) : (
                    <a
                      className="uk-button uk-button-primary"
                      href={`/companies/${company.slug}/push_message_triggers`}
                    >
                      メッセージ配信トップへ
                    </a>
                  )}
                </div>
              </div>
            )
          } else if (isSending) {
            return (
              <>
                <h4 className="uk-text-center">
                  メッセージ内容を登録中です...
                </h4>
                <div className="uk-section">
                  <ReactLoading
                    className="uk-margin-auto"
                    type={'spin'}
                    color={'#00888d'}
                    height={100}
                    width={100}
                  />
                </div>
              </>
            )
          } else {
            return (
              <>
                <h4 className="uk-text-center">登録完了まであと少しです！</h4>
                <div className="uk-text-center uk-margin">
                  <img
                    className="uk-width-1-2 uk-width-1-3@s"
                    src="https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/push_message/sending.png"
                  />
                </div>
                <div className="uk-text-small">
                  下記の内容でメッセージを登録してよろしいですか？
                </div>
                <ConfirmModalTable className="uk-text-small uk-margin-small-top uk-margin-bottom">
                  <ConfirmModalTableContent className="uk-width-1-4 uk-text-center">
                    送信タイミング
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-3-4">
                    {
                      {
                        order: '商品購入',
                        follow: '友だち追加',
                        ship: '商品配送',
                      }[formType]
                    }
                    {waitDays + waitHours + waitMinutes === 0
                      ? '後すぐ'
                      : `から${waitDays > 0 ? `${waitDays}日` : ''}${
                          waitHours > 0 ? `${waitHours}時間` : ''
                        }${waitMinutes > 0 ? `${waitMinutes}分` : ''}後`}
                  </ConfirmModalTableContent>
                </ConfirmModalTable>
                <div className="uk-grid uk-grid-small uk-child-width-1-4@s uk-child-width-1-2 uk-flex-center">
                  <div>
                    <button
                      className="uk-button uk-button-default uk-modal-close uk-width-1-1"
                      type="button"
                      onClick={closeModal}
                    >
                      入力へ戻る
                    </button>
                  </div>
                  <div>
                    <div
                      className="uk-button uk-button-primary uk-width-1-1"
                      onClick={handleSubmit}
                    >
                      登録する
                    </div>
                  </div>
                </div>
              </>
            )
          }
        })()}
      </ModalInner>
    </Modal>
  )

  const errorMessage = (() => {
    if (triggerType) {
      if (triggerType.includes('item') && !triggerValue)
        return '対象の商品を選択してください'
      if (triggerType.includes('category') && !triggerValue)
        return '対象のカテゴリーを選択してください'
    }
    if (validMessageObjects.length === 0)
      return '吹き出しを1つ以上登録してください'
    if (!messageUriValid)
      return '「omiseconnect.jp」または「omct.jp」が含まれるURLを指定することはできません。'
  })()

  return (
    <div id="form-root">
      {isWelcomeMessage ? (
        <>
          <div className="uk-text-muted uk-text-small uk-margin-bottom message-editor-width">
            あいさつメッセージには「お客さまのLINE名」やクーポンコードを入れるとより効果的です。
            <br />
            詳しくは
            <a
              href={`/usage_guide_redirects/case_study_greeting_message?shop_account_type=${company.shopAccountType}`}
              target="_blank"
            >
              {' '}
              あいさつメッセージの活用術{' '}
              <div
                className="material-icons"
                style={{ fontSize: 16, verticalAlign: 'text-bottom' }}
              >
                open_in_new
              </div>
            </a>
            をご確認ください。
          </div>
          <div className="uk-alert-danger uk-alert uk-text-small message-editor-width">
            LINE Official Account
            Managerであいさつメッセージを設定している場合におみせコネクト上でもあいさつメッセージを設定するとメッセージが重複して送信されます。
            <br />
            LINE Official Account
            Manager側の設定をOFFにすることをおすすめします。
          </div>
          <div className="uk-section-xsmall">
            <div className="uk-form-label uk-text-bold">配信対象</div>
            <div className="uk-margin-small-top uk-text-small">
              {addLineFriendTrackingId
                ? `経路「${addLineFriendTrackingTitle}」`
                : '全員'}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="uk-section-xsmall">
            <div className="uk-form-label uk-text-bold">
              配信タイミング
              <span className="required-icon">必須</span>
            </div>
            <div className="uk-margin-small-top uk-text-small">
              <div className="uk-display-inline-block uk-margin-small-right">
                {
                  {
                    order: '商品購入の',
                    ship: '商品配送の',
                  }[formType]
                }
              </div>
              <div className="uk-display-inline-block">
                <div className="uk-flex uk-flex-middle">
                  <WaitTimeSelect
                    className="uk-select"
                    value={waitDays}
                    onChange={e => setWaitDays(Number(e.currentTarget.value))}
                  >
                    {_.range(100).map(i => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </WaitTimeSelect>
                  <div className="uk-padding-small">日</div>
                  <WaitTimeSelect
                    className="uk-select"
                    value={waitHours}
                    onChange={e => setWaitHours(Number(e.currentTarget.value))}
                  >
                    {_.range(24).map(i => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </WaitTimeSelect>
                  <div className="uk-padding-small">時間</div>
                  <WaitTimeSelect
                    className="uk-select"
                    value={waitMinutes}
                    onChange={e =>
                      setWaitMinutes(Number(e.currentTarget.value))
                    }
                  >
                    {_.range(12).map(i => (
                      <option value={i * 5} key={i}>
                        {i * 5}
                      </option>
                    ))}
                  </WaitTimeSelect>
                  <div className="uk-padding-small">分</div>
                </div>
              </div>
              <div className="uk-display-inline-block">
                後に下記メッセージを自動で送る
              </div>
              {formType === 'ship' && (
                <div className="uk-margin uk-text-small uk-text-muted">
                  ※ショップ管理画面で注文を「発送済み」に変更したタイミングが基準となります。
                  <br />※
                  仕様上の都合により、発送済みに変更してから最大で1時間程度メッセージ配信に時間がかかる場合がございます。
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {triggerType && (formType === 'order' || formType === 'ship') && (
        <TriggerOrderTypeForm
          company={company}
          triggerType={triggerType}
          triggerValue={triggerValue}
          formType={formType}
          setTriggerType={setTriggerType}
          setTriggerValue={setTriggerValue}
        />
      )}
      <div className="uk-section-xsmall message-editor-width">
        <div className="uk-form-label uk-text-bold uk-margin-small-bottom">
          メッセージ内容
        </div>
        {!isWelcomeMessage && (
          <div className="uk-text-muted uk-text-xsmall uk-margin-bottom">
            複数の吹き出しを登録した場合でも、送信数は1人あたり1通として計算されます。
          </div>
        )}
        <MessageObjectsEditor
          messageObjects={orderedMessageObjects}
          onChange={handleChangeMessageObjects}
          deletePersistedObject={deletePersistedObject}
          addMessageObject={addDefaultMessageObject}
          company={company}
          availableKeywords={availableKeywords}
          cardTypeMessages={cardTypeMessages}
        />
      </div>
      <div className="uk-section-xsmall">
        <label className="uk-flex uk-flex-center uk-flex-middle uk-margin-bottom">
          <input
            type="checkbox"
            checked={statusActive}
            onChange={_e => setStatusActive(!statusActive)}
            className="uk-checkbox"
            style={{ margin: '0 5px 0 0' }}
          />
          <div className="uk-text-small">有効にする</div>
        </label>
        <div className="uk-text-center">
          {!_.isNil(errorMessage) && (
            <div className="uk-text-small uk-text-danger uk-margin-small-bottom">
              {errorMessage}
            </div>
          )}
          <button
            className="uk-button uk-button-large uk-button-primary"
            onClick={handleClickConfirmButton}
            disabled={!_.isNil(errorMessage)}
          >
            メッセージを登録する
          </button>
        </div>
        {ConfirmModal}
      </div>
      <MessagePreview
        messageObjects={validMessageObjects}
        company={company}
        cardTypeMessages={cardTypeMessages}
      />
    </div>
  )
}
export default PushMessageTriggerForm

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
const ConfirmModalTable = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid;
  border-left: 1px solid;
`
const WaitTimeSelect = styled.select`
  height: 30px !important;
  width: 60px;
`
const ConfirmModalTableContent = styled.div`
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 8px;
  display: flex;
  align-items: center;
`
