import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'

import { useAppSelector, useAppDispatch } from '../../hooks'
import { RootState } from '../../stores'
import Switch from '../shared/Switch'
import { updateTrigger } from 'components/Dashboard/stores/restockTrigger'

const TriggerStatusSwitch = () => {
  const dispatch = useAppDispatch()
  const { trigger } = useAppSelector((state: RootState) => state.restockTrigger)
  if (!trigger) return <div />

  const toggleTriggerStatus = () => {
    const status = trigger.status === 'active' ? 'inactive' : 'active'
    dispatch(updateTrigger({ ...trigger, status }))
  }

  return (
    <label>
      <div className="uk-flex uk-flex-middle">
        <FormControlLabel
          control={
            <Switch
              checked={trigger.status === 'active'}
              onChange={toggleTriggerStatus}
            />
          }
          label="再入荷通知を有効にする"
        />
      </div>
    </label>
  )
}

export default TriggerStatusSwitch
