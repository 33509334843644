import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'
import { AppDispatch, RootState } from '.'
import { Company, CartAbandonmentTrigger } from '../types'

interface CartAbandonmentTriggerState {
  trigger: CartAbandonmentTrigger | null
  changeSaved: boolean
}

const initialState: CartAbandonmentTriggerState = {
  trigger: null,
  changeSaved: true,
}

const slice = createSlice({
  name: 'cartAbandonmentTrigger',
  initialState,
  reducers: {
    setTrigger: (state, action) => {
      state.trigger = action.payload
      state.changeSaved = true
    },
    updateTrigger: (state, action) => {
      state.trigger = action.payload
      state.changeSaved = false
    },
    setChangeSaved: (state, action) => {
      state.changeSaved = action.payload
    },
  },
})

export default slice.reducer

export const { setTrigger, updateTrigger, setChangeSaved } = slice.actions

export function fetchCartAbandonmentTrigger(company: Company) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/cart_abandonment_trigger`
      )
      dispatch(setTrigger(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export function saveCartAbandonmentTrigger(company: Company) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const { trigger } = getState().cartAbandonmentTrigger
    if (!trigger) return

    const cartAbandonmentTriggerParams =
      trigger.status === 'active'
        ? {
            ...(({ waitMinutes, status }) => ({ waitMinutes, status }))(
              trigger
            ),
            cartAbandonmentMessageAttributes: (({
              id,
              title,
              text,
              buttonText,
              buttonColorCode,
            }) => ({ id, title, text, buttonText, buttonColorCode }))(
              trigger.cartAbandonmentMessage
            ),
          }
        : { status: trigger.status }
    const data = snakecaseKeys({
      cartAbandonmentTrigger: cartAbandonmentTriggerParams,
      authenticityToken: $('#authenticity_token').val(),
    })
    const response = await axios.put(
      `/api/companies/${company.slug}/cart_abandonment_trigger`,
      data
    )
    dispatch(setTrigger(response.data))
  }
}
