import React from 'react'
import { Provider } from 'react-redux'
import store from '../../stores'
import { Company } from '../../types'
import App from './App'

interface Props {
  company: Company
  defaultPushMessageTriggerActive: boolean
  webhookReady: boolean
  liffReady: boolean
}

const LineFollowTriggerList = ({
  company,
  defaultPushMessageTriggerActive,
  webhookReady,
  liffReady,
}: Props) => {
  return (
    <Provider store={store}>
      <App
        company={company}
        defaultPushMessageTriggerActive={defaultPushMessageTriggerActive}
        webhookReady={webhookReady}
        liffReady={liffReady}
      />
    </Provider>
  )
}

export default LineFollowTriggerList
