import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch } from '.'
import { AddLineFriendTrackingWithInsight, Company } from '../types'
import dayjs from 'dayjs'

interface addLineFriendTrackingsWithInsightState {
  addLineFriendTrackingsWithInsight: AddLineFriendTrackingWithInsight[] | null
  totalCount: number
}

const initialState: addLineFriendTrackingsWithInsightState = {
  addLineFriendTrackingsWithInsight: null,
  totalCount: 0,
}

const slice = createSlice({
  name: 'addLineFriendTrackingsWithInsight',
  initialState,
  reducers: {
    setAddLineFriendTrackingsWithInsight: (state, action) => {
      state.addLineFriendTrackingsWithInsight =
        action.payload.addLineFriendTrackings
      state.totalCount = action.payload.totalCount
    },
  },
})

export default slice.reducer

export const { setAddLineFriendTrackingsWithInsight } = slice.actions

export const fetchAddLineFriendTrackingsWithInsight =
  (company: Company, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, page = 1) =>
  async (dispatch: AppDispatch) => {
    const response = await axios.get(
      `/api/companies/${company.slug}/add_line_friend_tracking_insights`,
      {
        params: {
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
          page: page,
        },
      }
    )
    dispatch(setAddLineFriendTrackingsWithInsight(response.data))
  }
