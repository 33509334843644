import React from 'react'
import {
  useAppSelector,
  useAppDispatch,
  useResponsiveWindowSize,
} from '../../hooks'
import { RootState } from '../../stores'
import {
  setMessageDeliveryConfig,
  setMessageDeliveryConfigChangeSaved,
} from 'components/Dashboard/stores/company'

const hourSelectOptions = (startHour: number, length: number) =>
  [...Array(length)].map((_v, n) => {
    n += startHour
    if (n >= 24) n = n - 24
    return (
      <option key={n} value={n}>
        {n}
      </option>
    )
  })

const MessageDeliveryConfigForm = () => {
  const responsiveWindowSize = useResponsiveWindowSize()
  const dispatch = useAppDispatch()
  const { messageDeliveryConfig } = useAppSelector(
    (state: RootState) => state.company
  )
  if (!messageDeliveryConfig) return <div />

  const startHourForm = (
    <>
      夜
      <select
        className="uk-select"
        value={messageDeliveryConfig.remindMessageProhibitionStartHour}
        onChange={e => {
          dispatch(
            setMessageDeliveryConfig({
              ...messageDeliveryConfig,
              remindMessageProhibitionStartHour: Number(e.target.value),
            })
          )
          dispatch(setMessageDeliveryConfigChangeSaved(false))
        }}
        style={{ width: 60, margin: '0 10px' }}
      >
        {hourSelectOptions(20, 6)}
      </select>
      時から
    </>
  )
  const endHourForm = (
    <>
      翌朝
      <select
        className="uk-select"
        value={messageDeliveryConfig.remindMessageProhibitionEndHour}
        onChange={e => {
          dispatch(
            setMessageDeliveryConfig({
              ...messageDeliveryConfig,
              remindMessageProhibitionEndHour: Number(e.target.value),
            })
          )
          dispatch(setMessageDeliveryConfigChangeSaved(false))
        }}
        style={{ width: 60, margin: '0 10px' }}
      >
        {hourSelectOptions(6, 5)}
      </select>
      時まではメッセージを送らない
    </>
  )

  return (
    <>
      <div className="uk-text-small uk-margin-small-bottom">
        深夜帯の配信除外設定
      </div>
      <div style={{ color: '#333' }}>
        {responsiveWindowSize === 'small' ? (
          <>
            <div className="uk-text-small uk-flex uk-flex-middle uk-margin-small-bottom">
              {startHourForm}
            </div>
            <div className="uk-text-small uk-flex uk-flex-middle">
              {endHourForm}
            </div>
          </>
        ) : (
          <div className="uk-text-small uk-flex uk-flex-middle">
            {startHourForm}
            {endHourForm}
          </div>
        )}
      </div>
    </>
  )
}

export default MessageDeliveryConfigForm
