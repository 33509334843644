import React, { useEffect, useState } from 'react'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'

import { Category } from 'types.ts/Category'
import { Company } from 'types.ts/Company'
import { PushMessageTargetFilter } from 'types.ts/PushMessageTargetFilter'
import MakeshopItemSearchInput from 'components/molecules/PushMessage/MakeshopItemSearchInput'
import ShopCategorySelect from 'components/molecules/PushMessage/ShopCategorySelect'
import ShopItemSearchSelect from 'components/molecules/PushMessage/ShopItemSearchSelect'

type Props = {
  company: Company
  pushMessageTargetFilter: PushMessageTargetFilter
}

type FormValues = {
  minOrderCount: string
  maxOrderCount: string
  includeUnpaidOrCancelledOrder: boolean
  itemId: string
  categoryId: string
}

const PushMessageTargetFilterForm = ({
  company,
  pushMessageTargetFilter,
}: Props) => {
  const [shopCategories, setShopCategories] = useState([])

  useEffect(() => {
    fetchShopCategories()
  }, [])

  const methods = useForm<FormValues>({
    defaultValues: {
      minOrderCount: String(pushMessageTargetFilter.minOrderCount || ''),
      maxOrderCount: String(pushMessageTargetFilter.maxOrderCount || ''),
      includeUnpaidOrCancelledOrder:
        pushMessageTargetFilter.includeUnpaidOrCancelledOrder,
      itemId: pushMessageTargetFilter.itemId || '',
      categoryId: pushMessageTargetFilter.categoryId || '',
    },
  })

  const fetchShopCategories = () => {
    axios
      .get(`/api/companies/${company.slug}/shop_categories`)
      .then(response => {
        setShopCategories(response.data)
      })
  }

  const onSubmit = (values: FormValues) => {
    const queryString = Object.entries(snakecaseKeys(values))
      .map(e => {
        const key = encodeURI(`push_message_target_filter[${e[0]}]`)
        return `${key}=${e[1]}`
      })
      .join('&')
    window.location.href = `/companies/${company.slug}/push_message_target_filters?${queryString}`
  }

  return (
    <div className="uk-section-xsmall">
      <h2 className="uk-h4">配信対象を絞り込む</h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="uk-margin uk-text-small">
            <label className="uk-form-label uk-text-bold">購入回数</label>
            <div className="uk-flex uk-flex-middle uk-margin-small-top">
              <div className="uk-width-1-4 uk-width-1-6@s">
                <input
                  type="number"
                  className="uk-input uk-form-small"
                  min={0}
                  {...methods.register('minOrderCount')}
                />
              </div>
              <div className="uk-margin-small-left uk-margin-right">回以上</div>
              <div className="uk-width-1-4 uk-width-1-6@s">
                <input
                  type="number"
                  className="uk-input uk-form-small"
                  min={0}
                  {...methods.register('maxOrderCount')}
                />
              </div>
              <div className="uk-margin-small-left">回以下</div>
            </div>
          </div>
          <div className="uk-margin uk-text-small">
            <input
              type="checkbox"
              id="checkbox-includeUnpaidOrCancelledOrder"
              className="uk-checkbox"
              {...methods.register('includeUnpaidOrCancelledOrder')}
            />
            <label htmlFor="checkbox-includeUnpaidOrCancelledOrder">
              「入金待ち」と「キャンセル」の注文も購入回数に含める
            </label>
          </div>
          <div className="uk-margin uk-text-small">
            <label className="uk-form-label uk-text-bold">商品を指定</label>
            <div className="uk-flex uk-flex-middle uk-margin-small-top">
              <div className="uk-width-3-5 uk-width-1-2@s">
                <ItemSelect company={company} />
              </div>
            </div>
          </div>
          {shopCategories.length > 0 && (
            <div className="uk-margin uk-text-small">
              <label className="uk-form-label uk-text-bold">
                カテゴリーを指定
              </label>
              <div className="uk-flex uk-flex-middle uk-margin-small-top">
                <div className="uk-width-3-5 uk-width-1-2@s">
                  <CategorySelect shopCategories={shopCategories} />
                </div>
              </div>
            </div>
          )}
          <div className="uk-padding-small uk-flex uk-flex-center">
            <button
              type="submit"
              className="uk-button uk-button-primary uk-width-small"
            >
              絞り込む
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

const ItemSelect = ({ company }: { company: Company }) => {
  const { setValue } = useFormContext()
  const itemId = useWatch({ name: 'itemId' })
  return company.shopAccountType === 'makeshop' ? (
    <MakeshopItemSearchInput
      itemId={itemId}
      setItemId={value => setValue('itemId', value)}
      company={company}
    />
  ) : (
    <ShopItemSearchSelect
      value={itemId}
      setValue={value => setValue('itemId', value)}
      company={company}
    />
  )
}

const CategorySelect = ({ shopCategories }: { shopCategories: Category[] }) => {
  const { setValue } = useFormContext()
  const categoryId = useWatch({ name: 'categoryId' })
  return (
    <ShopCategorySelect
      value={categoryId}
      setValue={value => setValue('categoryId', value)}
      categoryList={shopCategories}
    />
  )
}

export default PushMessageTargetFilterForm
