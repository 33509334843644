import React from 'react'
import { Provider } from 'react-redux'
import store from '../stores'
import { Company, Customer } from '../types'
import App from './App'

interface Props {
  company: Company
  defaultCustomer: Customer | null
  isAdmin: boolean
  messageSendable: boolean
}

const Root = ({
  company,
  defaultCustomer,
  isAdmin,
  messageSendable,
}: Props) => {
  return (
    <Provider store={store}>
      <App
        company={company}
        defaultCustomer={defaultCustomer}
        isAdmin={isAdmin}
        messageSendable={messageSendable}
      />
    </Provider>
  )
}

export default Root
