import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import { saveRestockNotificationSetting } from 'components/Dashboard/stores/restockNotificationSetting'
import { Company } from 'components/Dashboard/types'
import React, { useState } from 'react'
import ReactLoading from 'react-loading'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { teal } from '@mui/material/colors'
import Switch from '@mui/material/Switch'
import { fetchRestockNotificationVariantData } from 'components/Dashboard/stores/restockNotificationVariantData'

type Props = {
  company: Company
  variantId: string
  type: 'notification' | 'subscription'
}

const labelText = {
  notification: '通知送信',
  subscription: '通知登録受付',
}

const SettingsSwitcher = ({ variantId, company, type }: Props) => {
  const [saving, setSaving] = useState(false)
  const dispatch = useAppDispatch()
  const { restockNotificationSetting } = useAppSelector(
    (state: RootState) => state.restockNotificationSetting
  )

  if (!restockNotificationSetting) {
    return <></>
  }

  const { variantIdsDisabledNotification, variantIdsDisabledSubscription } =
    restockNotificationSetting

  const variantIdsDisabled =
    type === 'notification'
      ? variantIdsDisabledNotification
      : variantIdsDisabledSubscription

  const onToOff = () => {
    if (
      window.confirm(`このバリエーションの${labelText[type]}をOFFにしますか？`)
    ) {
      setSaving(true)
      dispatch(
        saveRestockNotificationSetting(company, {
          ...restockNotificationSetting,
          [type === 'notification'
            ? 'variantIdsDisabledNotification'
            : 'variantIdsDisabledSubscription']: [
            ...variantIdsDisabled,
            variantId,
          ],
        })
      )
        .then(() => dispatch(fetchRestockNotificationVariantData(company)))
        .finally(() => {
          setSaving(false)
        })
    }
  }

  const offToOn = () => {
    if (
      window.confirm(`このバリエーションの${labelText[type]}をONにしますか？`)
    ) {
      setSaving(true)
      dispatch(
        saveRestockNotificationSetting(company, {
          ...restockNotificationSetting,
          [type === 'notification'
            ? 'variantIdsDisabledNotification'
            : 'variantIdsDisabledSubscription']: variantIdsDisabled.filter(
            id => id !== variantId
          ),
        })
      )
        .then(() => dispatch(fetchRestockNotificationVariantData(company)))
        .finally(() => {
          setSaving(false)
        })
    }
  }

  const toggle = () => {
    if (variantIdsDisabled.includes(variantId)) {
      offToOn()
    } else {
      onToOff()
    }
  }

  return (
    <>
      {saving ? (
        <ReactLoading
          type={'bubbles'}
          color={'#00888dbf'}
          height={40}
          width={40}
        />
      ) : (
        <ThemeProvider theme={theme}>
          <Switch
            checked={!variantIdsDisabled.includes(variantId)}
            onChange={toggle}
          />
        </ThemeProvider>
      )}
    </>
  )
}

export default SettingsSwitcher

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: teal[600],
          },
        },
        checked: {},
        track: {
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: teal[600],
          },
        },
      },
    },
  },
})
