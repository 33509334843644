import { CardTypeMessage } from 'components/Dashboard/types'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

interface ContentProps {
  actionButtonText: string
  actionButtonUrl: string | null
  description: string
  displayActionButton: boolean
  displayLabel: boolean
  displayDescription: boolean
  displayPrice: boolean
  imageUrl: string | null
  labelStyle: number
  labelText: string
  price: number
  title: string
}

interface LastCardProps {
  imageUrl: string | null
  layout: CardTypeMessage['lastCardLayout']
  text: CardTypeMessage['lastCardText']
  url: CardTypeMessage['lastCardUrl']
}

const labelStyles = [
  { number: 0, text: '#111111', background: '#ffffff' },
  { number: 1, text: '#ffffff', background: '#666f86' },
  { number: 2, text: '#ffffff', background: '#323333' },
  { number: 3, text: '#ffffff', background: '#ec3d44' },
  { number: 4, text: '#111111', background: '#fbc000' },
  { number: 5, text: '#ffffff', background: '#17c950' },
  { number: 6, text: '#ffffff', background: '#007bff' },
  { number: 7, text: '#111111', background: '#ffc3c4' },
  { number: 8, text: '#111111', background: '#f0ebcc' },
  { number: 9, text: '#111111', background: '#a9c8b8' },
]

const previewDefaultValue = {
  labelText: 'タグを入力',
  title: 'タイトルを入力',
  description: '説明文を入力',
  price: 1234,
  actionButtonText: 'ボタンのラベルを入力',
  lastCardText: 'テキストを入力',
}

export const ProductCardContentPreview = ({
  actionButtonText,
  actionButtonUrl,
  description,
  displayActionButton,
  displayLabel,
  displayDescription,
  displayPrice,
  imageUrl,
  labelStyle,
  labelText,
  price,
  title,
}: ContentProps) => {
  return (
    <Card actionUrl={displayActionButton ? actionButtonUrl : null}>
      <>
        <div>
          <img
            src={imageUrl || ''}
            style={{
              width: '100%',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          />
          {displayLabel && (
            <ImageLabel text={labelText} styleNum={labelStyle} />
          )}
        </div>
        <div style={{ padding: 20, flexGrow: 1 }}>
          <div
            style={{
              color: '#353a40',
              fontSize: 20,
              fontWeight: 'bold',
              letterSpacing: '.01px', // 表示可能文字数の調整用
            }}
          >
            {title || previewDefaultValue.title}
          </div>
          {displayDescription && (
            <div
              style={{
                marginTop: 4,
                fontSize: 13,
                whiteSpace: 'pre-wrap',
                color: '#495057',
              }}
            >
              {description || previewDefaultValue.description}
            </div>
          )}
          {displayPrice && (
            <div style={{ textAlign: 'right', color: '#353a40', fontSize: 20 }}>
              ¥{(price || previewDefaultValue.price).toLocaleString()}
            </div>
          )}
        </div>
        {displayActionButton && (
          <div
            style={{
              padding: 20,
              paddingTop: 0,
              color: '#5b82db',
              textAlign: 'center',
              fontSize: 15,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {actionButtonText || previewDefaultValue.actionButtonText}
          </div>
        )}
      </>
    </Card>
  )
}

export const ItemCardContentPreview = ({
  actionButtonText,
  actionButtonUrl,
  displayActionButton,
  displayLabel,
  imageUrl,
  labelStyle,
  labelText,
}: ContentProps) => {
  return (
    <Card actionUrl={displayActionButton ? actionButtonUrl : null}>
      <div>
        <img src={imageUrl || ''} style={{ width: '100%', borderRadius: 12 }} />
        {displayLabel && <ImageLabel text={labelText} styleNum={labelStyle} />}
        {displayActionButton && (
          <div
            style={{
              position: 'absolute',
              bottom: 20,
              left: 18,
              right: 18,
              textAlign: 'center',
            }}
          >
            <div
              style={{
                color: '#fff',
                backgroundColor: '#00000066',
                padding: '10px 18px',
                boxSizing: 'border-box',
                maxWidth: '100%',
                display: 'inline-block',
                textAlign: 'center',
                fontSize: 15,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                borderRadius: 24,
              }}
            >
              {actionButtonText || previewDefaultValue.actionButtonText}
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export const LastCardPreview = ({
  imageUrl,
  layout,
  text,
  url,
}: LastCardProps) => {
  return (
    <Card actionUrl={url}>
      <>
        {layout === 'image' && imageUrl && (
          <img
            src={imageUrl}
            style={{
              width: '100%',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          />
        )}
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}
        >
          <div
            style={{
              color: '#5b82db',
              fontSize: 15,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {text || previewDefaultValue.lastCardText}
          </div>
        </div>
      </>
    </Card>
  )
}

const ImageLabel = ({ text, styleNum }: { text: string; styleNum: number }) => {
  let labelStyle = labelStyles.find(({ number }) => number === styleNum)
  if (!labelStyle) labelStyle = labelStyles[0]

  return (
    <div
      style={{
        position: 'absolute',
        top: 12,
        left: 12,
        right: 12,
      }}
    >
      <div
        style={{
          color: labelStyle.text,
          backgroundColor: labelStyle.background,
          display: 'inline-block',
          fontSize: 13,
          padding: '2px 8px',
          borderRadius: 12,
          boxSizing: 'border-box',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {text || previewDefaultValue.labelText}
      </div>
    </div>
  )
}

const Card = ({
  children,
  actionUrl,
}: {
  children: ReactElement
  actionUrl: string | null
}) => {
  const aTagOptions = actionUrl
    ? { href: actionUrl, target: '_blank', rel: 'noopener' }
    : { style: { cursor: 'default' } }
  return <CardATag {...aTagOptions}>{children}</CardATag>
}

const CardATag = styled.a`
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
`
