import React, { useEffect, useState } from 'react'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import { ShopItem, ShopItemSearchResult } from 'components/Dashboard/types'
import { Company } from 'types.ts/Company'

type Props = {
  value: string
  setValue: (value: string) => void
  company: Company
}

type ItemOption = {
  label: string
  value: string
  shopItemSearchResult: ShopItemSearchResult
}

const ShopItemSearchSelect = ({ value, setValue, company }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [activeItemSearchResult, setActiveItemSearchResult] =
    useState<ShopItemSearchResult | null>(null)

  useEffect(() => {
    if (value && !activeItemSearchResult) {
      setLoading(true)
      fetchSingleShopItem(value).then((shopItem: ShopItem) => {
        setActiveItemSearchResult({
          title: shopItem.title,
          itemId: String(shopItem.itemId),
        })
        setLoading(false)
      })
    }
  }, [value])

  const fetchSingleShopItem = async (itemId: string) =>
    axios
      .get(`/api/companies/${company.slug}/shop_items/${itemId}`)
      .then(response => {
        return response.data
      })

  const promiseOptions = (inputValue: string) => {
    if (inputValue) {
      return axios
        .get(
          `/api/companies/${company.slug}/shop_item_search?keyword=${inputValue}`
        )
        .then(response => {
          const options: ItemOption[] = response.data.map(
            (shopItemSearchResult: ShopItemSearchResult) => ({
              label: shopItemSearchResult.title,
              value: String(shopItemSearchResult.itemId),
              shopItemSearchResult: shopItemSearchResult,
            })
          )
          return options
        })
    } else {
      return new Promise<ItemOption[]>(resolve => resolve([]))
    }
  }

  const removeActiveItemSearchResult = () => {
    setValue('')
    setActiveItemSearchResult(null)
  }

  const handleSelectItem = (itemOption: ItemOption) => {
    setValue(itemOption.value)
    setActiveItemSearchResult(itemOption.shopItemSearchResult)
  }

  if (loading) return <div />

  return (
    <div>
      {activeItemSearchResult ? (
        <div className="uk-flex uk-flex-middle uk-flex-between uk-box-shadow-small uk-padding-small">
          <div className="uk-margin-small-right uk-text-small uk-border">
            {activeItemSearchResult.title}
          </div>
          <div
            className="uk-button uk-button-default uk-button-small uk-flex-none"
            onClick={removeActiveItemSearchResult}
          >
            変更
          </div>
        </div>
      ) : (
        <AsyncSelect
          cacheOptions
          loadOptions={promiseOptions}
          onChange={itemOption => {
            if (itemOption) handleSelectItem(itemOption)
          }}
          placeholder="商品名を入力してください"
          noOptionsMessage={() => '商品が見つかりません'}
          loadingMessage={() => '検索中...'}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: '#00888d',
              primary25: '#00888d40',
              primary50: '#00888d80',
            },
          })}
        />
      )}
    </div>
  )
}

export default ShopItemSearchSelect
