import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '.'
import { Company, Customer, Message } from '../types'
import { updateToAllChatRead } from './customers'

interface ChatState {
  messages: Message[]
  loading: boolean
  allMessageFetched: boolean
}

const initialState: ChatState = {
  messages: [],
  loading: false,
  allMessageFetched: false,
}

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload)
    },
    setAllMessageFetched: (state, action) => {
      state.allMessageFetched = action.payload
    },
  },
})

export default slice.reducer

export const { setMessages, addMessage, setLoading, setAllMessageFetched } =
  slice.actions

export function fetchChatMessages(company: Company, customer: Customer) {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(setLoading(true))
      const params = { customer_id: customer.id }
      const response = await axios.get(
        `/api/companies/${company.slug}/talk_room/line_messages`,
        { params }
      )
      dispatch(setMessages(response.data))
      dispatch(setLoading(false))
    } catch (error) {
      console.log(error)
    }
  }
}

export function fetchOldChatMessages(company: Company, customer: Customer) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    try {
      dispatch(setLoading(true))
      const { messages } = getState().chat
      const params: {
        customer_id: number
        max_timestamp: number | null
      } = { customer_id: customer.id, max_timestamp: null }
      if (messages.length > 0) {
        params.max_timestamp = messages[0].timestamp
      }
      const response = await axios.get(
        `/api/companies/${company.slug}/talk_room/line_messages`,
        { params }
      )
      const list = response.data
      const newMessages = list.concat(messages)
      dispatch(setMessages(newMessages))
      dispatch(setAllMessageFetched(list.length === 0))
      dispatch(setLoading(false))
    } catch (error) {
      console.log(error)
    }
  }
}

export function sendChatMessage(
  company: Company,
  customer: Customer,
  messageType: string,
  value: string | File
) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const { isAdmin } = getState().company
    if (isAdmin) return

    const url = `/api/companies/${company.slug}/talk_room/line_messages`
    const data = new FormData()
    const token = String($('#authenticity_token').val())
    data.append('authenticity_token', token)
    data.append('customer_id', customer.id.toString())
    data.append('message_type', messageType)
    data.append('value', value)
    const response = await axios.post(url, data)
    dispatch(addMessage(response.data))
  }
}

export function fetchNewChatMessages(company: Company) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    try {
      const { selectedCustomer } = getState().customers
      const { messages, loading } = getState().chat
      if (!loading && selectedCustomer && messages.length > 0) {
        const params = {
          customer_id: selectedCustomer.id,
          min_timestamp: messages[messages.length - 1].timestamp,
        }
        const response = await axios.get(
          `/api/companies/${company.slug}/talk_room/line_messages`,
          { params }
        )
        const list = response.data
        if (list.length > 0) {
          const newMessages = messages.concat(list)
          const currentCustomer = getState().customers.selectedCustomer
          if (currentCustomer?.id === selectedCustomer?.id) {
            dispatch(setMessages(newMessages))
            dispatch(updateToAllChatRead(company, selectedCustomer))
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}
