import React, { CSSProperties } from 'react'
import { Customer } from '../types'
import { defaultIcon } from '../const'

interface Props {
  customer: Customer
  style: CSSProperties
  requiredLineBadge: boolean
}

const CustomerProfileImage = ({
  customer,
  style,
  requiredLineBadge,
}: Props) => {
  const profileWidth =
    typeof style.width === 'number' ||
    (typeof style.width === 'string' && style.width !== 'undefined')
      ? parseInt(style.width as string, 10)
      : 0
  const profileHeight =
    typeof style.height === 'number' ||
    (typeof style.height === 'string' && style.height !== 'undefined')
      ? parseInt(style.height as string, 10)
      : 0

  const handleCustomerImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    ;(e.target as HTMLImageElement).src = defaultIcon
  }

  const profileImage = (customer: Customer) =>
    customer.customerLineAccount?.pictureUrl || defaultIcon

  const badgeBorderWidth = profileWidth === 100 ? '3px' : `1.2px`

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        flexShrink: 0,
      }}
    >
      <img
        src={profileImage(customer)}
        style={style}
        onError={handleCustomerImageError}
      />
      {requiredLineBadge && customer.customerLineAccount && (
        <img
          src="https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/line_account/connect_line_icon.jpg"
          style={{
            position: 'absolute',
            bottom: profileWidth === 100 ? -5 : -2,
            right: profileWidth === 100 ? 5 : 9,
            width: `${profileWidth / 2.2}px`,
            height: `${profileHeight / 2.2}px`,
            borderRadius: '43%',
            border: `${badgeBorderWidth} solid white`,
          }}
        />
      )}
    </div>
  )
}

export default CustomerProfileImage
