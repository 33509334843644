import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RestockSubscription } from '../types'
import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import { ShopItemDefaultImage } from '../const'

interface Props {
  companySlug: string
  customerId: number
}

const RestockSubscriptionList = ({ companySlug, customerId }: Props) => {
  const [restockSubscriptions, setRestockSubscriptions] = useState<
    RestockSubscription[]
  >([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchRestockSubscriptions = async () => {
    const res = await axios.get(
      `/api/companies/${companySlug}/talk_room/customers/${customerId}/restock_subscriptions`
    )
    setRestockSubscriptions(res.data.restockSubscriptions)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchRestockSubscriptions()
  }, [customerId])

  if (isLoading || restockSubscriptions.length === 0) {
    return null
  }

  const dateGroupedRestockSubscriptions = _.groupBy(
    restockSubscriptions,
    restockSubscription => {
      if (restockSubscription.sentAt) {
        return dayjs(restockSubscription.sentAt).format('YYYY/MM/DD')
      } else {
        return dayjs(restockSubscription.createdAt).format('YYYY/MM/DD')
      }
    }
  )

  const onClickDelete = async (restockSubscriptionId: number) => {
    if (confirm('再入荷通知リクエストを削除しますか？')) {
      try {
        const data = { authenticity_token: $('#authenticity_token').val() }
        await axios.delete(
          `/api/companies/${companySlug}/talk_room/customers/${customerId}/restock_subscriptions/${restockSubscriptionId}`,
          { data }
        )
        setRestockSubscriptions(
          restockSubscriptions.filter(
            restockSubscription =>
              restockSubscription.id !== restockSubscriptionId
          )
        )
      } catch (e) {
        console.log(e)
        alert('再入荷通知リクエストの削除に失敗しました。')
      }
    }
  }

  return (
    <>
      <Heading>再入荷通知履歴</Heading>
      {_.keys(dateGroupedRestockSubscriptions)
        .sort()
        .reverse()
        .map(date => {
          const groupedRestockSubscriptions =
            dateGroupedRestockSubscriptions[date] || []
          return (
            <DateBlock key={date}>
              <div className="uk-text-small uk-text-secondary uk-margin-small-bottom">
                {date}
              </div>
              <div>
                {groupedRestockSubscriptions.map(restockSubscription => (
                  <RestockSubscriptionRow key={restockSubscription.id}>
                    <RestockSubscriptionRowImage
                      src={
                        restockSubscription.variant.imageUrl ||
                        ShopItemDefaultImage
                      }
                    />
                    <div className="uk-margin-small-left uk-margin-small-right uk-flex-1 uk-text-small">
                      <ProductTitle>
                        {restockSubscription.variant.productTitle}
                      </ProductTitle>
                      <div className="uk-text-muted">
                        {restockSubscription.variant.variantTitle}
                      </div>
                    </div>
                    {restockSubscription.sentAt ? (
                      <div
                        className={
                          'uk-flex-none restock-subscription-status-label restock-subscription-status-label--sent'
                        }
                      >
                        送信済み
                      </div>
                    ) : (
                      <div
                        className={
                          'uk-flex-none restock-subscription-status-label restock-subscription-status-label--not-sent'
                        }
                        onClick={() => onClickDelete(restockSubscription.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        削除
                      </div>
                    )}
                  </RestockSubscriptionRow>
                ))}
              </div>
            </DateBlock>
          )
        })}
    </>
  )
}

export default RestockSubscriptionList

const Heading = styled.h3`
  font-size: 16px;
  margin: 32px 0 16px;
  &:first-child {
    margin-top: 0;
  }
`

const DateBlock = styled.div`
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  padding: 10px 0 4px;
  &:first-child {
    padding-top: 0;
  }
`

const RestockSubscriptionRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
  > * {
    min-width: 0;
  }
`
const RestockSubscriptionRowImage = styled.img`
  width: 40px;
  height: 40px;
  flex: none;
  object-fit: contain;
`

const ProductTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`
