import React, { createRef, useState } from 'react'
import Slider from 'rc-slider'
import styled from 'styled-components'
import AvatarEditor from 'react-avatar-editor'

interface Props {
  width: number
  height: number
  file: File
  onCrop: (blob: Blob) => void
  onCancel: () => void
}

const ImageCropEditor = ({ width, height, file, onCrop, onCancel }: Props) => {
  const [sliderValue, setSliderValue] = useState<number>(0)

  const editorRef: React.RefObject<AvatarEditor> = createRef()

  const handleChangeSlider = (sliderValue: number) => {
    setSliderValue(sliderValue)
  }

  const handleClickSubmitButton = async () => {
    if (editorRef.current) {
      const dataUrl = editorRef.current.getImage().toDataURL()
      const res = await fetch(dataUrl)
      const blob = await res.blob()
      onCrop(blob)
    }
  }

  const innerWidth = width * 0.8
  const innerHeight = height * 0.8
  const borderWidth = width * 0.1
  const borderHeight = height * 0.1
  const scale = 1 + sliderValue / 20
  return (
    <div className="uk-flex uk-flex-column uk-flex-middle">
      <AvatarEditor
        ref={editorRef}
        image={file}
        width={innerWidth}
        height={innerHeight}
        border={[borderWidth, borderHeight]}
        color={[0, 0, 0, 0.7]}
        scale={scale}
        rotate={0}
      />
      <SliderWraper>
        <Slider onChange={handleChangeSlider} value={sliderValue} max={40} />
      </SliderWraper>
      <button
        className="uk-button uk-button-primary uk-margin-top"
        type="button"
        onClick={handleClickSubmitButton}
      >
        適用する
      </button>
      <div
        className="uk-button uk-button-text uk-text-small uk-padding-remove uk-margin-top"
        onClick={onCancel}
      >
        画像を変更する
      </div>
    </div>
  )
}

export default ImageCropEditor

const SliderWraper = styled.div`
  width: 260px;
  margin-top: 20px;
`
