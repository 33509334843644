import { Customer } from './types'

export const displayName = (customer: Customer) => {
  const { managementName, shopCustomer, customerLineAccount } = customer
  if (managementName) return managementName

  if (shopCustomer) {
    if (shopCustomer.firstName && shopCustomer.lastName) {
      return `${shopCustomer.lastName} ${shopCustomer.firstName}`
    }
    if (shopCustomer.firstName) return shopCustomer.firstName
    if (shopCustomer.lastName) return shopCustomer.lastName
    if (shopCustomer.email) return shopCustomer.email
  }

  return customerLineAccount?.displayName || ''
}

export const shopCustomerName = (customer: Customer) => {
  const { shopCustomer } = customer

  if (!shopCustomer) return ''

  if (shopCustomer.lastName && shopCustomer.firstName) {
    return `${shopCustomer.lastName} ${shopCustomer.firstName}`
  } else if (shopCustomer.lastName) {
    return shopCustomer.lastName
  } else if (shopCustomer.firstName) {
    return shopCustomer.firstName
  } else if (shopCustomer.email) {
    return shopCustomer.email
  }

  return ''
}
