import React from 'react'
import { Provider } from 'react-redux'
import store from '../../stores'
import { Company } from '../../types'
import App from './App'
import jaTranslations from '@shopify/polaris/locales/ja.json'
import { PolarisProvider } from './PolarisProvider'

interface Props {
  company: Company
}

const RestockSubscriptionList = ({ company }: Props) => {
  return (
    <PolarisProvider i18n={jaTranslations}>
      <Provider store={store}>
        <App company={company} />
      </Provider>
    </PolarisProvider>
  )
}

export default RestockSubscriptionList
