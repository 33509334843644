import React from 'react'
import { runes } from 'runes2'

const LineDisplayTextArea = ({ value, onChange }) => {
  return (
    <div className="uk-margin">
      <div className="uk-margin-small-bottom uk-flex uk-flex-between">
        <label
          className="uk-form-label uk-text-bold"
          htmlFor="shop_popup_display_text"
        >
          表示メッセージ
          <span className="required-icon">必須</span>
        </label>
        <div
          className="uk-text-small uk-text-muted uk-margin-small-left"
          style={{ width: 76 }}
        >
          <span
            className={`uk-text-bold ${
              runes(value).length > 32 ? 'uk-text-danger' : ''
            }`}
          >
            {runes(value).length}
          </span>
          /32文字
        </div>
      </div>
      <textarea
        required="required"
        className="uk-textarea uk-form-small"
        style={{ height: 94 }}
        name="shop_popup[line_display_text]"
        id="shop_popup_line_display_text"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      <div className="uk-text-small uk-text-muted uk-margin-small-top">
        例）
        <br />
        今すぐ友だち追加で、500円OFFのクーポンをプレゼント✨
        <br />
        【5%OFF】今すぐLINE友だち追加でクーポンプレゼント🙌
        <br />
        友だち追加で○○○（ショップ名やブランド名）の最新の情報を入手しよう👍
      </div>
    </div>
  )
}

export default LineDisplayTextArea
