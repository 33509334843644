import { createSlice } from '@reduxjs/toolkit'

interface LayoutState {
  displayProfile: boolean
}

const initialState: LayoutState = {
  displayProfile: true,
}

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setDisplayProfile: (state, action) => {
      state.displayProfile = action.payload
      localStorage.setItem('talkroom.displayProfile', action.payload)
    },
  },
})

export default slice.reducer

export const { setDisplayProfile } = slice.actions
