import { withStyles } from '@mui/styles'
import { teal } from '@mui/material/colors'
import Switch from '@mui/material/Switch'

export default withStyles({
  switchBase: {
    '&$checked': {
      color: teal[600],
    },
    '&$checked + $track': {
      backgroundColor: teal[600],
    },
  },
  checked: {},
  track: {},
})(Switch)
