import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import * as snakecaseKeys from 'snakecase-keys'
import { AppDispatch, RootState } from '.'
import { Company, MessageDeliveryConfig } from '../types'

interface CompanyState {
  // FIXME: rails側からcompanyを渡してるので基本ある前提だが、initialStateがnullのためnullableになっている
  // componentのmount時にrails側から渡ってきたcompanyをinitialStateとしてstoreにsetしたい
  company: Company | null
  messageDeliveryConfig: MessageDeliveryConfig | null
  messageDeliveryConfigChangeSaved: boolean
}

const initialState: CompanyState = {
  company: null,
  messageDeliveryConfig: null,
  messageDeliveryConfigChangeSaved: true,
}

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload
    },
    setMessageDeliveryConfig: (
      state,
      action: PayloadAction<MessageDeliveryConfig>
    ) => {
      state.messageDeliveryConfig = action.payload
    },
    setMessageDeliveryConfigChangeSaved: (state, action) => {
      state.messageDeliveryConfigChangeSaved = action.payload
    },
  },
})

export default slice.reducer

export const {
  setCompany,
  setMessageDeliveryConfig,
  setMessageDeliveryConfigChangeSaved,
} = slice.actions

export function fetchMessageDeliveryConfig(company: Company) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/message_delivery_config`
      )
      dispatch(setMessageDeliveryConfig(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export function saveMessageDeliveryConfig(company: Company) {
  return async function (dispatch: AppDispatch, getState: () => RootState) {
    const { messageDeliveryConfig } = getState().company
    const data = snakecaseKeys({
      messageDeliveryConfig,
      authenticityToken: $('#authenticity_token').val(),
    })
    await axios.put(
      `/api/companies/${company.slug}/message_delivery_config`,
      data
    )
    dispatch(setMessageDeliveryConfigChangeSaved(true))
  }
}
