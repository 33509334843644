// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'chartkick/chart.js'

Rails.start()
ActiveStorage.start()
UIkit.use(Icons)

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

$(function () {
  var RAILS_ENV = $('#rails_env_constant').val()
  if (['staging', 'production'].includes(RAILS_ENV)) {
    Sentry.init({
      environment: RAILS_ENV,
      dsn: 'https://0c411b80426e45bf90263fb2d8a11b3f@o915837.ingest.sentry.io/5856522',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }
})

// import custom files
import 'src/image_preview.js'
import 'src/tag_check.js'
import 'src/user_company_notification.js'
import 'src/copy_to_clipboard_button.js'
import 'src/push_message_form.js'
import 'src/guide.js'
