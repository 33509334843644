import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { useModal } from 'react-hooks-use-modal'
import ReactLoading from 'react-loading'
import MessagePreview from '../organisms/PushMessage/MessagePreview'
import MessageObjectsEditor from '../organisms/PushMessage/MessageObjectsEditor'
import {
  checkFormObjectIsValid,
  convertDBObjectToFormObject,
  includeOcDomainUri,
} from '../../lib/MessageObjectUtil'
import UserCustomerConnectForm from '../organisms/UserCustomerConnectForm'
import { Company } from 'types.ts/Company'
import { PushMessageReservation } from 'types.ts/PushMessageReservation'
import {
  PushMessageFormObject,
  PushMessageObject,
  PushMessageFormTextObject,
  PushMessageFormImageWithLinkObject,
  PushMessageFormImageObject,
  TextMessageReplaceKey,
  PushMessageFormCardTypeObject,
} from 'types.ts/PushMessage'
import { CardTypeMessage } from 'components/Dashboard/types'

const defaultScheduledDatetime: string = moment()
  .format()
  .replace(/:\d+\+.*/, '')

interface Props {
  company: Company
  filterJson: string | null
  filterText: string | null
  reservation: PushMessageReservation
  scheduled: boolean
}

type DeletedMessageIds = {
  text: number[]
  image: number[]
  cardType: number[]
}

const PushMessageReservationForm = ({
  company,
  filterJson,
  filterText,
  reservation,
  scheduled,
}: Props) => {
  const [reservationType, setReservationType] = useState('realtime')
  const [scheduledDatetime, setScheduledDatetime] = useState(
    defaultScheduledDatetime
  )
  const [messageObjects, setMessageObjects] = useState<PushMessageFormObject[]>(
    []
  )
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const [deletedMessageIds, setDeletedMessageIds] = useState<DeletedMessageIds>(
    {
      text: [],
      image: [],
      cardType: [],
    }
  )
  const [saveAsDraft, setSaveAsDraft] = useState<boolean>(false)
  const [reservationId, setReservationId] = useState<number | null>(null)
  const [isSendingTestMessage, setIsSendingTestMessage] =
    useState<boolean>(false)
  const [isTestMessageSent, setIsTestMessageSent] = useState<boolean>(false)
  const [cardTypeMessages, setCardTypeMessages] = useState<CardTypeMessage[]>(
    []
  )

  const [Modal, openModal, closeModal, isModalOpen] = useModal('form-root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  })

  const [SendingTestModal, openSendingTestModal, closeSendingTestModal] =
    useModal('form-root', {
      closeOnOverlayClick: false,
    })

  const fetchCardTypeMessages = async () => {
    const response = await axios.get(
      `/api/companies/${company.slug}/card_type_messages`
    )
    setCardTypeMessages(response.data)
  }

  useEffect(() => {
    const defaultObjects = [getDefaultMessageObject()]
    setMessageObjects(defaultObjects)

    if (reservation) {
      setReservationId(reservation.id)
      if (reservation.scheduledDatetime) {
        setReservationType('schedule')
        setScheduledDatetime(
          moment(reservation.scheduledDatetime)
            .format()
            .replace(/:\d+\+.*/, '')
        )
      } else {
        setReservationType('realtime')
      }
      if (reservation.pushMessageObjects.length > 0) {
        resetMessageObjects(reservation.pushMessageObjects)
      }
    }

    fetchCardTypeMessages()
  }, [])

  useEffect(() => {
    if (!isModalOpen) {
      setIsSaved(false)
      setSaveAsDraft(false)
    }
  }, [isModalOpen])

  const availableKeywords: TextMessageReplaceKey[] = ['AccountName', 'ShopUrl']
  if (company.companyLineAccount?.allFriendsSaved)
    availableKeywords.push('Nickname')

  const resetMessageObjects = (messageObjects: PushMessageObject[]) => {
    const formObjects: PushMessageFormObject[] = messageObjects.map(
      pushMessageObject => convertDBObjectToFormObject(pushMessageObject)
    )
    setMessageObjects(formObjects)
  }

  const resetDeletedMessageIds = () => {
    setDeletedMessageIds({ text: [], image: [], cardType: [] })
  }

  const orderedMessageObjects = _.sortBy(messageObjects, 'order')

  const getDefaultMessageObject = () => ({
    type: null,
    order:
      messageObjects.length > 0
        ? (orderedMessageObjects.slice(-1)[0].order || 0) + 1
        : 0,
    identifier: (Math.random() + 1).toString(36).substring(7),
  })

  const addDefaultMessageObject = () => {
    if (messageObjects.length >= 5) return

    setMessageObjects([messageObjects, getDefaultMessageObject()].flat())
  }

  const deletePersistedObject = (deletedObject: PushMessageFormObject) => {
    if (!deletedObject.id) return

    const newIds = Object.assign({}, deletedMessageIds)
    if (deletedObject.type === 'text') {
      newIds.text.push(deletedObject.id)
    } else if (
      deletedObject.type === 'image' ||
      deletedObject.type === 'imageWithLink'
    ) {
      newIds.image.push(deletedObject.id)
    } else if (deletedObject.type === 'cardType') {
      newIds.cardType.push(deletedObject.id)
    }
    setDeletedMessageIds(newIds)
  }

  const validMessageObjects = orderedMessageObjects.filter(obj =>
    checkFormObjectIsValid(obj)
  )

  const messageUriValid = !includeOcDomainUri(orderedMessageObjects)

  const messageValid = validMessageObjects.length !== 0 && messageUriValid

  const handleChangeMessageObjects = (newObjects: PushMessageFormObject[]) => {
    if (newObjects.length === 0) {
      setMessageObjects([getDefaultMessageObject()])
    } else {
      setMessageObjects(newObjects)
    }
  }

  const handleDeleteSubmit = () => {
    const url = `/api/companies/${company.slug}/push_message_reservations/${reservationId}`
    const data = { authenticity_token: $('#authenticity_token').val() }

    if (window.confirm('メッセージ配信を削除してよろしいですか？')) {
      axios
        .delete(url, { data })
        .then(
          _ =>
            (window.location.href = `/flash_messages/redirect_with_flash_message?message=メッセージ配信を削除しました&type=notice&url=/companies/${company.slug}/push_message_reservations${targetFilteringParams}`)
        )
        .catch(() => {
          window.alert('メッセージ配信を削除できませんでした')
        })
    }
  }

  const handleClickSaveAsDraft = () => {
    setSaveAsDraft(true)
    openModal()
    handleSubmit({ saveAsDraft: true })
  }

  const handleSubmit = ({
    saveAsDraft,
    callback,
  }: {
    saveAsDraft: boolean
    callback?: (id: number) => void
  }) => {
    if (isSending) return

    if (!messageValid) return

    setIsSending(true)
    const formData = new FormData()
    const authenticityToken = $('#authenticity_token').val() as string
    formData.append('authenticity_token', authenticityToken)
    formData.append('reservation_type', reservationType)
    formData.append('save_as_draft', String(saveAsDraft || false))
    formData.append(
      'deleted_message_object_ids[text]',
      String(deletedMessageIds.text)
    )
    formData.append(
      'deleted_message_object_ids[image]',
      String(deletedMessageIds.image)
    )
    formData.append(
      'deleted_message_object_ids[card_type]',
      String(deletedMessageIds.cardType)
    )
    formData.append(
      'push_message_reservation[scheduled_datetime]',
      scheduledDatetime
    )
    if (filterJson)
      formData.append('push_message_reservation[filter_json]', filterJson)
    const textMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'text'
    ) as PushMessageFormTextObject[]
    textMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_reservation[push_message_text_objects_attributes]'
      formData.append(`${parent}[${index}][text]`, obj.text)
      formData.append(`${parent}[${index}][order]`, String(obj.order))
      if (obj.id) formData.append(`${parent}[${index}][id]`, String(obj.id))
    })
    const imageMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'image' || obj.type === 'imageWithLink'
    ) as (PushMessageFormImageObject | PushMessageFormImageWithLinkObject)[]
    imageMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_reservation[push_message_image_objects_attributes]'
      if (obj.file) {
        formData.append(
          `${parent}[${index}][image]`,
          obj.file,
          `${new Date().getTime()}-${(Math.random() + 1)
            .toString(36)
            .substring(7)}.${obj.file.name.split('.').pop()}`
        )
      }
      if (obj.type === 'imageWithLink') {
        formData.append(`${parent}[${index}][uri]`, obj.uri || '')
        formData.append(`${parent}[${index}][alt_text]`, obj.altText || '')
      }
      formData.append(`${parent}[${index}][order]`, String(obj.order))
      if (obj.id) formData.append(`${parent}[${index}][id]`, String(obj.id))
    })
    const cardTypeMessageObjects = _.filter(
      validMessageObjects,
      obj => obj.type === 'cardType'
    ) as PushMessageFormCardTypeObject[]
    cardTypeMessageObjects.forEach((obj, index) => {
      const parent =
        'push_message_reservation[push_message_card_type_objects_attributes]'
      formData.append(
        `${parent}[${index}][card_type_message_id]`,
        String(obj.cardTypeMessageId)
      )
      formData.append(`${parent}[${index}][alt_text]`, obj.altText || '')
      formData.append(`${parent}[${index}][order]`, String(obj.order))
      if (obj.id) formData.append(`${parent}[${index}][id]`, String(obj.id))
    })
    if (reservationId) {
      const url = `/api/companies/${company.slug}/push_message_reservations/${reservationId}`
      axios
        .put(url, formData)
        .then(response => {
          const { pushMessageObjects } = response.data
          resetMessageObjects(pushMessageObjects)
          resetDeletedMessageIds()
          setIsSaved(true)
          setIsSending(false)
          if (callback) callback(reservationId)
        })
        .catch(error => {
          const { message } = error.response.data
          window.alert(message)
          setIsSending(false)
          closeModal()
        })
    } else {
      const url = `/api/companies/${company.slug}/push_message_reservations`
      axios
        .post(url, formData)
        .then(response => {
          const { id, pushMessageObjects } = response.data
          setReservationId(id)
          history.replaceState(
            '',
            '',
            `/companies/${company.slug}/push_message_reservations/${id}/edit`
          )
          resetMessageObjects(pushMessageObjects)
          setIsSaved(true)
          setIsSending(false)
          // @ts-expect-error GTM用（活用していない）
          window.dataLayer.push({ event: 'user_reservation_msg_registered' })
          if (callback) callback(id)
        })
        .catch(error => {
          const { message } = error.response.data
          window.alert(message)
          setIsSending(false)
          closeModal()
        })
    }
  }

  const saveAndsendTestMessage = () => {
    setIsSendingTestMessage(true)
    setSaveAsDraft(true)
    handleSubmit({
      saveAsDraft: true,
      callback: id => {
        const data = { authenticity_token: $('#authenticity_token').val() }
        axios
          .post(
            `/api/companies/${company.slug}/push_message_reservations/${id}/send_test_message`,
            data
          )
          .then(_res => {
            setIsTestMessageSent(true)
            setIsSendingTestMessage(false)
            // @ts-expect-error GTM用（活用していない）
            window.dataLayer.push({ event: 'user_test_reservation_msg_sent' })
          })
      },
    })
  }

  const handleClickCloseSendingTestModal = () => {
    closeSendingTestModal()
    setIsSaved(false)
    setIsTestMessageSent(false)
  }

  const targetFilteringParams = `?target_filtering=${
    filterJson ? 'true' : 'false'
  }`

  const ConfirmModal = (
    <Modal>
      <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
        {(() => {
          if (isSaved) {
            return saveAsDraft ? (
              <div className="uk-text-center">
                <h4>下書き保存しました！</h4>
                <div className="uk-flex uk-flex-column uk-flex-middle">
                  <div
                    className="uk-button uk-button-primary"
                    onClick={() => {
                      closeModal()
                      setIsSaved(false)
                    }}
                  >
                    入力へ戻る
                  </div>
                  <a
                    className="uk-button uk-button-default uk-margin-top"
                    href={`/companies/${company.slug}/push_message_reservations${targetFilteringParams}`}
                  >
                    保存済みの配信一覧へ
                  </a>
                </div>
              </div>
            ) : (
              <div className="uk-text-center">
                <h4>登録が完了しました！</h4>
                <p>{`${
                  reservationType === 'realtime'
                    ? 'この後すぐに'
                    : '登録した日時になりましたら自動で'
                }メッセージが配信されます。`}</p>
                <a
                  className="uk-button uk-button-primary"
                  href={`/companies/${company.slug}/push_message_reservations${targetFilteringParams}`}
                >
                  配信メッセージ一覧へ
                </a>
              </div>
            )
          } else if (isSending) {
            return (
              <>
                <h4 className="uk-text-center">
                  メッセージ内容を登録中です...
                </h4>
                <div className="uk-section">
                  <ReactLoading
                    className="uk-margin-auto"
                    type={'spin'}
                    color={'#00888d'}
                    height={100}
                    width={100}
                  />
                </div>
              </>
            )
          } else {
            return (
              <>
                <h4 className="uk-text-center">
                  {`${
                    reservationType === 'realtime' ? '配信' : '登録完了'
                  }まであと少しです！`}
                </h4>
                <div className="uk-text-center uk-margin">
                  <img
                    className="uk-width-1-2 uk-width-1-3@s"
                    src="https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/dashboard/push_message/sending.png"
                  />
                </div>
                <div className="uk-text-small">{`下記の内容でメッセージを${
                  reservationType === 'realtime' ? '配信' : '登録'
                }してよろしいですか？`}</div>
                <ConfirmModalTable className="uk-text-small uk-margin-small-top uk-margin-bottom">
                  <ConfirmModalTableContent className="uk-width-1-4 uk-text-center">
                    送信日時
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-3-4">
                    {reservationType === 'realtime'
                      ? '今すぐ'
                      : moment(scheduledDatetime).format(
                          'YYYY年MM月DD日 HH:mm'
                        )}
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-1-4 uk-text-center">
                    送信先
                  </ConfirmModalTableContent>
                  <ConfirmModalTableContent className="uk-width-3-4">
                    {filterText || 'すべてのLINE友だち'}
                  </ConfirmModalTableContent>
                </ConfirmModalTable>
                <div className="uk-grid uk-grid-small uk-child-width-1-4@s uk-child-width-1-2 uk-flex-center">
                  <div>
                    <button
                      className="uk-button uk-button-default uk-modal-close uk-width-1-1"
                      type="button"
                      onClick={closeModal}
                    >
                      入力へ戻る
                    </button>
                  </div>
                  <div>
                    <div
                      className="uk-button uk-button-primary uk-width-1-1"
                      onClick={() => handleSubmit({ saveAsDraft: false })}
                    >
                      {reservationType === 'realtime' ? '配信する' : '登録する'}
                    </div>
                  </div>
                </div>
              </>
            )
          }
        })()}
      </ModalInner>
    </Modal>
  )

  const targetNum = reservation.targetCustomersCount
  return (
    <div id="form-root">
      <div className="uk-section-xsmall">
        <div className="uk-form-label uk-text-bold uk-margin-bottom">
          配信対象
        </div>
        <div className="uk-background-muted uk-padding-small uk-text-small">
          {filterText || 'すべてのLINE友だち'}
        </div>
        {targetNum !== 0 && (
          <div
            className="uk-text-small uk-margin-top"
            style={{ lineHeight: 1.8 }}
          >
            対象人数：
            <b>{targetNum.toLocaleString()}</b>人
            <span className="uk-text-xsmall uk-text-muted">
              （※
              表示人数は現時点での推計値のため、実際の人数とは異なる場合がございます）
            </span>
          </div>
        )}
      </div>
      <div className="uk-section-xsmall">
        <div className="uk-form-label uk-text-bold uk-margin-bottom">
          配信スケジュール
          <span className="required-icon">必須</span>
        </div>
        <label className="uk-flex uk-flex-middle uk-margin-small">
          <input
            type="radio"
            value="realtime"
            name="reservation-type"
            checked={reservationType === 'realtime'}
            onChange={e => setReservationType(e.target.value)}
            className="uk-radio uk-margin-small-right"
          />
          <div className="uk-text-small"> 今すぐ配信する</div>
        </label>
        <div className="uk-flex uk-flex-middle uk-margin-small">
          <input
            type="radio"
            value="schedule"
            name="reservation-type"
            checked={reservationType === 'schedule'}
            onChange={e => setReservationType(e.target.value)}
            className="uk-radio uk-margin-small-right"
          />
          <input
            className="uk-input uk-form-small uk-width-2-3 uk-width-1-3@s"
            value={scheduledDatetime}
            min={defaultScheduledDatetime}
            onChange={e => setScheduledDatetime(e.currentTarget.value)}
            onFocus={() => setReservationType('schedule')}
            type="datetime-local"
          />
        </div>
      </div>
      <div className="uk-section-xsmall message-editor-width">
        <div className="uk-form-label uk-text-bold uk-margin-small-bottom">
          メッセージ内容
        </div>
        <div className="uk-text-muted uk-text-xsmall uk-margin-bottom">
          複数の吹き出しを登録した場合でも、送信数は1人あたり1通として計算されます。
        </div>
        <MessageObjectsEditor
          messageObjects={orderedMessageObjects}
          onChange={handleChangeMessageObjects}
          deletePersistedObject={deletePersistedObject}
          addMessageObject={addDefaultMessageObject}
          company={company}
          availableKeywords={availableKeywords}
          cardTypeMessages={cardTypeMessages}
        />
      </div>
      <div className="uk-section-xsmall message-editor-width">
        <div className="uk-flex uk-flex-column uk-flex-middle">
          {!messageUriValid && (
            <div className="uk-text-danger uk-text-center uk-margin-bottom uk-text-small">
              <b>「omiseconnect.jp」</b>
              または
              <b>「omct.jp」</b>
              が含まれるURLを指定することはできません。
            </div>
          )}
          <div className="uk-flex uk-flex-column">
            <button
              className="uk-button uk-button-large uk-button-primary uk-margin-bottom"
              onClick={() => {
                setSaveAsDraft(false)
                openModal()
              }}
              disabled={!messageValid}
            >
              {reservationType === 'realtime'
                ? 'メッセージを配信'
                : '配信予約を登録'}
            </button>
            <button
              className="uk-button uk-button-default uk-margin-bottom"
              onClick={handleClickSaveAsDraft}
              disabled={!messageValid}
            >
              {scheduled ? '下書きに戻す' : '下書きとして保存'}
            </button>
            <button
              className="uk-button uk-button-default uk-margin-bottom"
              onClick={openSendingTestModal}
              disabled={!messageValid}
            >
              テスト配信
            </button>
            {reservationId && (
              <button
                className="uk-button uk-button-danger"
                onClick={handleDeleteSubmit}
              >
                削除する
              </button>
            )}
          </div>
        </div>
      </div>
      <MessagePreview
        messageObjects={validMessageObjects}
        company={company}
        cardTypeMessages={cardTypeMessages}
      />
      {ConfirmModal}
      <SendingTestModal>
        <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
          <div className="uk-h4">テスト配信</div>
          {isTestMessageSent ? (
            <>
              <div className="uk-margin-bottom">
                メッセージの送信準備が完了しました。
                <br />
                まもなくテスト配信が行われますので、LINEアカウントにてご確認ください。
              </div>
              <div className="uk-text-center">
                <div
                  className="uk-button uk-button-text uk-padding-remove"
                  onClick={handleClickCloseSendingTestModal}
                >
                  閉じる
                </div>
              </div>
            </>
          ) : isSending || isSendingTestMessage ? (
            <>
              <div className="uk-text-center">テスト配信の準備中です...</div>
              <div className="uk-section">
                <ReactLoading
                  className="uk-margin-auto"
                  type={'spin'}
                  color={'#00888d'}
                  height={100}
                  width={100}
                />
              </div>
            </>
          ) : (
            <>
              <div className="uk-text-small">
                実際にお客さまにメッセージを配信する前に、自分のLINEアカウントにメッセージをテスト配信することが可能です。
              </div>
              <div className="uk-h5">テスト配信用LINEアカウント</div>
              <UserCustomerConnectForm
                company={company}
                sendMessage={() => saveAndsendTestMessage()}
                sendButtonText="下書きに保存してテスト配信"
              />
              <div className="uk-text-center uk-margin-top">
                <div
                  className="uk-button uk-button-text uk-padding-remove"
                  onClick={handleClickCloseSendingTestModal}
                >
                  キャンセル
                </div>
              </div>
            </>
          )}
        </ModalInner>
      </SendingTestModal>
    </div>
  )
}
export default PushMessageReservationForm

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
const ConfirmModalTable = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid;
  border-left: 1px solid;
`
const ConfirmModalTableContent = styled.div`
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 8px;
`
