import React from 'react'
import styled from 'styled-components'
import * as dayjs from 'dayjs'
import ReactLoading from 'react-loading'

import CustomerSearchForm from './CustomerSearchForm'
import { useAppSelector } from '../hooks'
import { RootState } from '../stores'
import { setSelectedCustomer } from '../stores/customers'
import { useAppDispatch, useWindowSize } from '../hooks'
import { displayName } from '../customerUtil'
import { PC_MIN_WIDTH } from '../const'
import { fetchCustomers, setCurrentPage } from '../stores/customers'
import { Company, Customer } from '../types'
import CustomerProfileImage from './CustomerProfileImage'
import CustomerConnectedStatusIcon from './CustomerConnectedStatusIcon'

interface Props {
  company: Company
}

const CustomersList = ({ company }: Props) => {
  const dispatch = useAppDispatch()
  const [windowWidth] = useWindowSize()
  const isPcLayout = windowWidth >= PC_MIN_WIDTH
  const { isAdmin } = useAppSelector((state: RootState) => state.company)
  const { list, selectedCustomer, loading, currentPage, totalPages } =
    useAppSelector((state: RootState) => state.customers)

  const selectCustomer = (company: Company, customer: Customer) => {
    dispatch(setSelectedCustomer(customer))
    const query = new URLSearchParams(location.search)
    const queryString = query.toString()
    const path = `${isAdmin ? '/admin' : ''}/companies/${
      company.slug
    }/talk_rooms/${customer.chatId}${queryString ? '?' + queryString : ''}`
    isPcLayout
      ? history.replaceState('', '', path)
      : history.pushState('', '', path)
  }

  const loadMore = () => {
    dispatch(setCurrentPage(currentPage + 1))
    dispatch(fetchCustomers(company))
  }

  return (
    <Wrapper
      style={{ display: !isPcLayout && selectedCustomer ? 'none' : 'flex' }}
    >
      <Header>
        <Logo>
          <img src="https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/oc_logo.png" />
        </Logo>
        <CustomerSearchForm company={company} />
      </Header>
      <List>
        {list.map(customer => {
          const latestMessageTimeText = (() => {
            if (!customer.latestMessageSentAt) return ''
            const today = dayjs().endOf('day')
            const time = dayjs(customer.latestMessageSentAt)
            if (time.add(1, 'day') > today) return time.format('H:mm')
            if (time.add(2, 'day') > today) return '昨日'
            return time.format('M/D')
          })()
          return (
            <Row
              key={customer.id}
              onClick={() => selectCustomer(company, customer)}
              selected={customer.id === selectedCustomer?.id}
            >
              <CustomerProfileImage
                customer={customer}
                style={{
                  objectFit: 'cover',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  marginRight: '10px',
                }}
                requiredLineBadge={true}
              />
              <MainContent>
                <h3>{displayName(customer)}</h3>
                {customer.customerLineAccount && (
                  <div style={{ display: 'inline-block' }}>
                    {customer.supportStatus === 'action_required' && (
                      <ActionRequiredIcon>要対応</ActionRequiredIcon>
                    )}
                    {customer.customerLineAccount &&
                      (customer.shopCustomer ||
                        customer.firstOcidConnectedAt) && (
                        <CustomerConnectedStatusIcon
                          shopConnected={Boolean(customer.shopCustomer)}
                          browserConnected={
                            customer.firstOcidConnectedAt !== null
                          }
                          shopAccountType={company.shopAccountType}
                          style={{
                            fontSize: '12px',
                            height: '22px',
                            marginRight: '4px',
                            transform: 'scale(0.9)',
                            lineHeight: '22px',
                          }}
                          type={'shop'}
                        />
                      )}
                  </div>
                )}
              </MainContent>
              <RowRight>
                <LatestSentTime>{latestMessageTimeText}</LatestSentTime>
                {customer.chatReadStatus === 'unread_chat_present' && (
                  <UnreadIcon />
                )}
              </RowRight>
            </Row>
          )
        })}
        {loading && (
          <ReactLoading
            className="uk-margin-auto uk-margin-large-top"
            type={'spin'}
            color={'#00888d'}
            height={60}
            width={60}
          />
        )}
        {!loading && list.length === 0 && (
          <div className="uk-margin-top uk-text-small uk-text-muted uk-text-center">
            条件に該当する顧客はいません
          </div>
        )}
        {!loading && totalPages > currentPage && (
          <div className="uk-text-center uk-padding">
            <div
              onClick={loadMore}
              className="uk-button uk-button-small uk-button-default"
            >
              さらに読み込む
            </div>
          </div>
        )}
      </List>
    </Wrapper>
  )
}

export default CustomersList

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 360px;
  height: 100vh;
  height: 100svh; // iPhone+Safari対策
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    border: 0;
    width: 100%;
  }
`
const Header = styled.div`
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    position: fixed;
    background-color: #fff;
    height: 135px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
`
const Logo = styled.div`
  box-sizing: border-box;
  height: 60px;
  padding-top: 10px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    text-align: center;
    padding-left: 0;
  }
  img {
    height: 40px;
  }
`
const List = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    margin-top: 180px;
  }
`
const Row = styled.div<{ selected: boolean }>`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  padding: 12px 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? 'rgba(0, 0, 0, .05)' : '#fff'};
  &:hover {
    background-color: ${({ selected }) =>
      selected ? 'rgba(0, 0, 0, .05)' : 'rgba(0, 0, 0, .025)'};
  }
`

const MainContent = styled.div`
  flex: 1;
  min-width: 0;
  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    margin-bottom: 4px;
  }
`
const RowRight = styled.div`
  width: 20px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
`
const LatestSentTime = styled.div`
  font-size: 12px;
  color: #999;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  text-align: right;
`
const UnreadIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00b900;
`
const ActionRequiredIcon = styled.div`
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  width: 70px;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  background-color: #f0506e;
  margin-right: 4px;
  transform: scale(0.9);
`
