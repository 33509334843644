import React from 'react'
import * as snakecaseKeys from 'snakecase-keys'
import axios from 'axios'
import { useAppSelector } from '../../hooks'
import { RootState } from '../../stores'
import SendingTestModalComponent from '../shared/SendingTestModalComponent'

interface Props {
  rootId: string
}

declare const window: Window['window'] & {
  dataLayer: object[]
}

const SendingTestModal = ({ rootId }: Props) => {
  const { trigger } = useAppSelector(
    (state: RootState) => state.cartAbandonmentTrigger
  )
  const { company } = useAppSelector((state: RootState) => state.company)

  if (!company || !trigger) return <div />

  const sendTestMessage = async () => {
    const data = snakecaseKeys({
      authenticityToken: $('#authenticity_token').val(),
      cartAbandonmentMessage: (({
        title,
        text,
        buttonText,
        buttonColorCode,
      }) => ({ title, text, buttonText, buttonColorCode }))(
        trigger.cartAbandonmentMessage
      ),
    })
    return axios
      .post(
        `/api/companies/${company.slug}/cart_abandonment_trigger/send_test_message`,
        data
      )
      .then(_res => {
        window.dataLayer.push({ event: 'user_test_cart_abandonment_msg_sent' })
      })
  }

  return (
    <SendingTestModalComponent
      company={company}
      rootId={rootId}
      sendMessage={sendTestMessage}
    />
  )
}

export default SendingTestModal
