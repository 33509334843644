import React from 'react'
import styled from 'styled-components'
import axios from 'axios'

const PaymentMethodsList = ({
  company,
  paymentMethods,
  defaultPaymentMethodId,
  fetchPaymentMethods,
}) => {
  const removePaymentMethod = paymentMethodId => {
    if (window.confirm('選択したカードを削除してよろしいですか？')) {
      const url = `/api/companies/${company.slug}/payment/payment_methods/${paymentMethodId}`
      const data = { authenticity_token: $('#authenticity_token').val() }
      axios.delete(url, { data }).then(_response => {
        fetchPaymentMethods()
      })
    }
  }

  const setDefaultPaymentMethod = paymentMethodId => {
    const url = `/api/companies/${company.slug}/payment/payment_methods/${paymentMethodId}/default`
    const data = { authenticity_token: $('#authenticity_token').val() }
    axios.post(url, data).then(_response => {
      fetchPaymentMethods()
    })
  }

  return paymentMethods.length === 0 ? (
    <div className="uk-text-small uk-text-muted">
      カードが登録されていません
    </div>
  ) : (
    <ul className="uk-list uk-list-divider">
      {paymentMethods.map(paymentMethod => {
        const { brand, exp_year, exp_month, last4 } = paymentMethod.card
        const isDefault = defaultPaymentMethodId === paymentMethod.id
        return (
          <li
            key={paymentMethod.id}
            className="uk-flex uk-flex-middle uk-flex-center uk-flex-between@s uk-flex-wrap"
          >
            <div className="uk-flex uk-flex-middle">
              <img
                src={`https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/card/${brand}.png`}
                style={{ width: 52 }}
                className="uk-flex-none"
              />
              <div className="uk-margin-small-left uk-text-small">
                <div className="uk-text-bold uk-flex">
                  {brand} •••• {last4}
                  {isDefault && <DefaultIcon>デフォルト</DefaultIcon>}
                </div>
                <div className="uk-text-muted">
                  有効期限: {exp_year}年{exp_month}月
                </div>
              </div>
            </div>
            <div className="uk-flex uk-flex-middle uk-flex-between uk-padding-small">
              {isDefault ? (
                <div className="uk-text-small uk-text-muted">
                  このカードがお支払い時に使用されます
                </div>
              ) : (
                <>
                  <div
                    onClick={setDefaultPaymentMethod.bind(
                      this,
                      paymentMethod.id
                    )}
                    className="uk-button uk-button-small uk-button-default uk-margin-right"
                  >
                    デフォルトに設定
                  </div>
                  <div
                    onClick={removePaymentMethod.bind(this, paymentMethod.id)}
                    className="uk-button uk-button-small uk-button-text uk-margin-small-right"
                  >
                    削除
                  </div>
                </>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
export default PaymentMethodsList

const DefaultIcon = styled.span`
  color: #3d4eac;
  background-color: #d6ecff;
  padding: 1px 8px;
  font-size: 13px;
  border-radius: 3px;
  margin-left: 10px;
  margin-bottom: 4px;
`
