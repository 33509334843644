$(function () {
  $('.guide-close').on('click', () => {
    const guide = $('.guide')
    const key = guide.data('key')
    const slug = guide.data('slug')
    if (key) {
      document.cookie = `oc_guide_${slug}_${key}=hidden;max-age=630720000`
    }
    $('.guide').hide()
  })
})
