import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CardTypeMessage } from 'components/Dashboard/types'
import { RootState } from 'components/Dashboard/stores'
import { useAppSelector } from 'components/Dashboard/hooks'
import { FormValues, ContentFormValue } from './FormRoot'
import ContentImageUploadModal from './ContentImageUploadModal'

interface Props {
  contentIndex: number
  layout: CardTypeMessage['layout']
}

const ContentForm = ({ contentIndex, layout }: Props) => {
  const { cardTypeMessageContentLabelStyles } = useAppSelector(
    (state: RootState) => state.cardTypeMessage
  )

  const { register, setValue, formState, clearErrors } =
    useFormContext<FormValues>()

  const [
    displayActionButton,
    displayDescription,
    displayLabel,
    displayPrice,
    imageUrl,
    imageOriginalIdentifier,
    labelStyle,
  ] = useWatch({
    name: [
      `contents.${contentIndex}.displayActionButton`,
      `contents.${contentIndex}.displayDescription`,
      `contents.${contentIndex}.displayLabel`,
      `contents.${contentIndex}.displayPrice`,
      `contents.${contentIndex}.image.url`,
      `contents.${contentIndex}.image.originalIdentifier`,
      `contents.${contentIndex}.labelStyle`,
    ],
  })

  const setUploadedImageFile = (file: File) => {
    const imageUrl = window.URL.createObjectURL(file)
    setValue(`contents.${contentIndex}.image.uploadedFile`, file)
    setValue(`contents.${contentIndex}.image.url`, imageUrl)
    clearErrors(`contents.${contentIndex}.image.uploadedFile`)
  }

  const clearImage = () => {
    if (window.confirm('画像を変更しますか？')) {
      setValue(`contents.${contentIndex}.image.uploadedFile`, null)
      setValue(`contents.${contentIndex}.image.url`, null)
      setValue(`contents.${contentIndex}.image.originalIdentifier`, null)
    }
  }

  const errors = formState.errors.contents?.[contentIndex] || {}

  return (
    <div>
      <div className="uk-flex uk-margin uk-flex-nowrap">
        <div className="uk-width-small uk-flex-none">
          <label
            className="uk-text-small uk-text-secondary"
            style={{ lineHeight: '40px' }}
          >
            <input
              type="checkbox"
              className="uk-checkbox uk-margin-small-right"
              {...register(`contents.${contentIndex}.displayLabel`)}
            />
            タグ
          </label>
        </div>
        <div className="uk-flex-1">
          <TextFieldWithError
            name="labelText"
            label="タグ"
            contentIndex={contentIndex}
            disabled={!displayLabel}
            validations={{ maxLength: 20 }}
          />
          <div className="uk-flex uk-flex-wrap uk-margin-small-top">
            {cardTypeMessageContentLabelStyles.map(
              ({ number, text, background }) => {
                const isSelected = labelStyle === number
                return (
                  <div
                    key={number}
                    onClick={() =>
                      setValue(`contents.${contentIndex}.labelStyle`, number)
                    }
                    style={{
                      color: text,
                      background: background,
                      border: background.match('#ffffff')
                        ? '1px solid #ddd'
                        : 'none',
                      width: 32,
                      height: 32,
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                    }}
                    className="uk-flex uk-flex-center uk-flex-middle uk-margin-xsmall-right uk-margin-xsmall-bottom uk-border-circle uk-text-xsmall"
                  >
                    {isSelected ? (
                      <span style={{ width: 16 }} uk-icon="icon: check" />
                    ) : (
                      'A'
                    )}
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
      <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
        <div className="uk-width-small uk-flex-none">
          <label
            className="uk-text-small uk-text-secondary"
            style={{ lineHeight: '40px' }}
          >
            画像
          </label>
        </div>
        <div className="uk-flex-1">
          {imageUrl ? (
            <div className="uk-inline">
              <img src={imageUrl} width={200} />
              <div
                className="uk-position-top-right uk-overlay-default uk-overlay-primary uk-border-rounded"
                style={{
                  padding: '1px 4px 3px',
                  margin: 5,
                  cursor: 'pointer',
                }}
                onClick={clearImage}
              >
                <span uk-icon="close" />
              </div>
            </div>
          ) : (
            <ContentImageUploadModal
              aspectRatio={layout === 'product' ? 3 / 2 : 1}
              onChange={setUploadedImageFile}
            />
          )}
          <ErrorMessage message={errors.image?.uploadedFile?.message} />
          <input
            type="hidden"
            {...register(`contents.${contentIndex}.image.uploadedFile`, {
              // NOTE: 既存のデータから複製した場合（= imageOriginalIdentifier がある場合）は`image.uploadedFile`はなくても大丈夫
              required: imageOriginalIdentifier
                ? undefined
                : '画像が登録されていません。',
            })}
          />
        </div>
      </div>
      {layout === 'product' && (
        <>
          <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
            <div className="uk-width-small uk-flex-none">
              <label
                className="uk-text-small uk-text-secondary"
                style={{ lineHeight: '40px' }}
              >
                タイトル
              </label>
            </div>
            <div className="uk-flex-1">
              <TextFieldWithError
                name="title"
                label="タイトル"
                contentIndex={contentIndex}
                validations={{ maxLength: 20 }}
              />
            </div>
          </div>
          <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
            <div className="uk-width-small uk-flex-none">
              <label
                className="uk-text-small uk-text-secondary"
                style={{ lineHeight: '40px' }}
              >
                <input
                  type="checkbox"
                  className="uk-checkbox uk-margin-small-right"
                  {...register(`contents.${contentIndex}.displayDescription`)}
                />
                説明文
              </label>
            </div>
            <div className="uk-flex-1">
              <TextFieldWithError
                name="description"
                label="説明文"
                contentIndex={contentIndex}
                disabled={!displayDescription}
                rows={4}
                validations={{ maxLength: 100 }}
              />
            </div>
          </div>
          <div className="uk-flex uk-margin-bottom uk-flex-nowrap">
            <div className="uk-width-small uk-flex-none">
              <label
                className="uk-text-small uk-text-secondary"
                style={{ lineHeight: '40px' }}
              >
                <input
                  type="checkbox"
                  className="uk-checkbox uk-margin-small-right"
                  {...register(`contents.${contentIndex}.displayPrice`)}
                />
                価格
              </label>
            </div>
            <div className="uk-flex-1">
              <TextFieldWithError
                name="price"
                label="価格"
                inputType="number"
                disabled={!displayPrice}
                contentIndex={contentIndex}
                validations={{ maxLength: 10 }}
              />
            </div>
          </div>
        </>
      )}
      <div className="uk-flex uk-flex-nowrap">
        <div className="uk-width-small uk-flex-none">
          <label
            className="uk-text-small uk-text-secondary"
            style={{ lineHeight: '40px' }}
          >
            <input
              type="checkbox"
              className="uk-checkbox uk-margin-small-right"
              {...register(`contents.${contentIndex}.displayActionButton`)}
            />
            ボタン
          </label>
        </div>
        <div className="uk-flex-1">
          <div className="uk-margin-small-bottom">
            <TextFieldWithError
              name="actionButtonText"
              label="ボタンのラベル"
              contentIndex={contentIndex}
              disabled={!displayActionButton}
              validations={{ maxLength: 20 }}
            />
          </div>
          <div>
            <TextFieldWithError
              name="actionButtonUrl"
              label="ボタンのURL"
              contentIndex={contentIndex}
              disabled={!displayActionButton}
              validations={{
                maxLength: 200,
                pattern:
                  /^(?:(?:https?):\/\/)(?!.*(omiseconnect\.jp|omct\.jp))[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                errorMessage: {
                  pattern:
                    'URLの形式が間違っている、もしくは「omiseconnect.jp」「omct.jp」が含まれています。',
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const TextFieldWithError = ({
  name,
  label,
  contentIndex,
  disabled = false,
  inputType = 'text',
  rows,
  validations = {},
}: {
  name: keyof ContentFormValue
  label: string
  contentIndex: number
  disabled?: boolean
  inputType?: 'text' | 'number'
  rows?: number
  validations?: {
    maxLength?: number
    pattern?: RegExp
    errorMessage?: {
      pattern?: string
    }
  }
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>()
  const error = errors.contents?.[contentIndex]?.[name]
  // optionsを`RegisterOptions`で型付けするとエラーになってしまう
  let options = {}
  options = { required: disabled ? undefined : `${label}を入力してください。` }
  if (validations.maxLength) {
    options = {
      ...options,
      maxLength: disabled
        ? undefined
        : {
            value: validations.maxLength,
            message: `${validations.maxLength}文字以下で入力してください。`,
          },
    }
  }
  if (validations.pattern) {
    options = {
      ...options,
      pattern: disabled
        ? undefined
        : {
            value: validations.pattern,
            message:
              validations.errorMessage?.pattern || `${label}の形式が不正です。`,
          },
    }
  }
  const attributes = {
    placeholder: `${label}を入力`,
    disabled,
    ...register(`contents.${contentIndex}.${name}`, options),
  }
  return (
    <div>
      {rows ? (
        <textarea
          rows={rows}
          className={`uk-textarea ${error ? 'uk-form-danger' : ''}`}
          {...attributes}
        />
      ) : (
        <input
          type={inputType}
          className={`uk-input ${error ? 'uk-form-danger' : ''}`}
          {...attributes}
        />
      )}
      {!disabled && <ErrorMessage message={error?.message} />}
    </div>
  )
}

const ErrorMessage = ({ message }: { message: string | undefined }) => (
  <>
    {message && (
      <div className="uk-text-danger uk-text-small uk-margin-xsmall-top">
        {message}
      </div>
    )}
  </>
)

export default ContentForm
