import React from 'react'
import { Provider } from 'react-redux'
import store from '../../stores'
import { Company } from '../../types'
import App from './App'

interface Props {
  company: Company
}

const PvTriggersForm = ({ company }: Props) => {
  return (
    <Provider store={store}>
      <App company={company} />
    </Provider>
  )
}

export default PvTriggersForm
