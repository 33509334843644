import React, { useState, useEffect } from 'react'
import { Company, RichMenuData } from '../../types'
import { setCompany } from '../../stores/company'
import { useAppDispatch } from '../../hooks'
import TitleMenuBar from './TitleMenuBar'
import UploadRichmenuImages from './UploadRichmenuImages'
import axios from 'axios'
import { RichMenuLayoutImages } from '../../const'

interface Props {
  company: Company
  action: string
  richMenuData: RichMenuData | null
}

const App = ({ company, action, richMenuData }: Props) => {
  const dispatch = useAppDispatch()
  const [editData, setEditData] = useState(richMenuData)
  const [validateMessages, setValidateMessages] = useState<string[]>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [sendFile, setSendFile] = useState<File | null>(null)

  useEffect(() => {
    setEditData(richMenuData)
    dispatch(setCompany(company))
  })

  const handleSubmit = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    option: string
  ) => {
    e.preventDefault()
    setIsDisabled(true)

    const richMenuForm = document.getElementById(
      'rich-menu-form'
    ) as HTMLFormElement
    let formData = new FormData(richMenuForm)
    formData = encodeFormDataUrl(formData)
    formData.append(
      'authenticity_token',
      String($('#authenticity_token').val())
    )
    if (!sendFile) return
    formData.append('file', sendFile)
    const url = `/api/companies/${company.slug}/rich_menu`

    if (!formValidation(formData)) {
      setIsDisabled(false)
      return
    }

    if (action === 'new') {
      formData.append('rich_menu[action]', 'create')
      axios
        .post(url, formData)
        .then(
          _ =>
            (window.location.href = `/flash_messages/redirect_with_flash_message?message=リッチメニューの設定を保存しました。&type=notice&url=/companies/${company.slug}/company_line_account/custom_rich_menus`)
        )
        .catch(({ response }) => {
          window.alert(
            response.status === 400
              ? 'URLの形式が不正です。'
              : 'リッチメニューの保存ができませんでした。お手数ですが時間をあけてから再度お試しください。'
          )
          setIsDisabled(false)
        })
    } else if (action === 'edit' && option === 'delete') {
      if (!editData) return
      formData.append('rich_menu[rich_menu_id]', String(editData.richMenuId))
      if (window.confirm('リッチメニューを削除してよろしいですか？')) {
        axios
          .delete(url, { data: formData })
          .then(
            _ =>
              (window.location.href = `/flash_messages/redirect_with_flash_message?message=削除しました。&type=notice&url=/companies/${company.slug}/company_line_account/custom_rich_menus`)
          )
          .catch(() => {
            window.alert(
              'リッチメニューの削除ができませんでした。お手数ですが時間をあけてから再度お試しください。'
            )
            setIsDisabled(false)
          })
      }
    } else {
      if (!editData) return
      formData.append('rich_menu[rich_menu_id]', String(editData.richMenuId))
      formData.append(
        'rich_menu[setting_status]',
        String(editData.settingStatus)
      )
      formData.append('rich_menu[action]', 'update')
      axios
        .put(url, formData)
        .then(
          _ =>
            (window.location.href = `/flash_messages/redirect_with_flash_message?message=リッチメニューの設定を保存しました。&type=notice&url=/companies/${company.slug}/company_line_account/custom_rich_menus`)
        )
        .catch(({ response }) => {
          window.alert(
            response.status === 400
              ? 'URLの形式が不正です。'
              : 'リッチメニューの保存ができませんでした。お手数ですが時間をあけてから再度お試しください。'
          )
          setIsDisabled(false)
        })
    }
  }

  const encodeFormDataUrl = (formData: FormData) => {
    let beforeAction = ''
    for (const action of formData as any) {
      if (action[0].match(/area_actions/)) {
        if (beforeAction === action[0]) {
          if (action[1].length !== 0) {
            try {
              const uri = new URL(action[1])
              formData.set(action[0], uri.href)
            } catch (error) {
              console.log(error)
            }
          }
        }
        if (action[1] === 'uri') {
          beforeAction = action[0]
        }
      }
    }
    return formData
  }

  const formValidation = (actionsData: FormData) => {
    const array = []
    const messages = []
    let i = 0

    for (const action of actionsData as any) {
      if (action[0].match(/area_actions/)) {
        if (action[1] === 'uri' || action[1] === 'message_response') continue
        i += 1
        // 「リンク」のバリデーション
        if (action[0].match(/uri/)) {
          if (action[1].length === 0) {
            messages.push(`${i}番目のリンクのURLを入力してください`)
          } else {
            if (!action[1].match(/^https?:\/\/[-_.!~*()a-zA-Z0-9;?:&=+,%#]+/)) {
              messages.push(`${i}番目のURLの形式が不正です`)
            } else {
              try {
                const url = new URL(action[1])
                if (url.searchParams.has('lid'))
                  messages.push(
                    `${i}番目のURLのパラメータ「lid」を削除してください`
                  )
                if (url.searchParams.has('mtid')) {
                  messages.push(
                    `${i}番目のURLのパラメータ「mtid」を削除してください`
                  )
                }
                if (url.searchParams.has('mt')) {
                  messages.push(
                    `${i}番目のURLのパラメータ「mt」を削除してください`
                  )
                }
              } catch (err) {
                messages.push(`${i}番目のURLの形式が不正です`)
              }
            }
          }
        }
        // 「メッセージ応答」のバリデーション
        else if (action[0].match(/message_response/)) {
          const urlRegex = /(https?:\/\/[^\s]+)/g
          if (action[1].length === 0) {
            messages.push(`${i}番目の応答メッセージを入力してください`)
          }
          if (action[1].match(urlRegex)) {
            const extractedUrl = new URL(action[1].match(urlRegex))
            const searchParams = extractedUrl.searchParams
            if (searchParams.has('lid')) {
              messages.push(
                `${i}番目のURLのパラメータ「lid」を削除してください`
              )
            }
            if (searchParams.has('mtid')) {
              messages.push(
                `${i}番目のURLのパラメータ「mtid」を削除してください`
              )
            }
            if (searchParams.has('mt')) {
              messages.push(`${i}番目のURLのパラメータ「mt」を削除してください`)
            }
          }
          if (action[1].length > 250) {
            messages.push(`${i}番目の応答メッセージの文字数が超過しています`)
          }
        }
        // アクション未選択時のバリデーション
        else {
          if (action[1] === '') {
            messages.push(`${i}番目のアクションを選択してください`)
          }
        }
        // 「お問い合わせ」のバリデーション
        if (action[1] === 'reply_inquiry_response') {
          array.push(true)
        } else {
          array.push(false)
        }
      }
    }

    if (messages.length > 0) {
      setValidateMessages(messages)
    } else {
      setValidateMessages([])
    }
    return messages.length === 0
  }

  const deleteButton = () => {
    if (editData) {
      if (Object.keys(editData).length > 0) {
        if (editData.settingStatus === 'inactive') {
          return (
            <input
              type="button"
              value="削除する"
              className="uk-button uk-button uk-button-danger uk-margin-right"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleSubmit(e, 'delete')
              }
            />
          )
        }
      }
    }
  }

  return (
    <div className="uk-section">
      <div className="uk-container uk-container-small">
        <div className="uk-section-xsmall">
          <h3>リッチメニューの{action === 'new' ? '登録' : '編集'}</h3>
          <div className="uk-text-small uk-margin-small-bottom">
            <form id="rich-menu-form">
              <div id="insert_division_type"></div>

              <div>
                <h4>1.レイアウト・アクションを決める</h4>
                <div>
                  まずはどのようなリッチメニューを作成するか考えてみましょう。
                  レイアウト・アクションは自由に組み合わせることが可能です。
                </div>
                <div className="uk-text-bold uk-margin-top">
                  選択できるアクション
                </div>
                <div>・商品を見る： 販売されている商品を表示します。</div>
                <div>
                  ・メッセージ応答：事前に指定したテキストメッセージを発言します（クーポン情報やセール情報等に活用できます）
                </div>
                <div>
                  ・ページリンク： タップすると指定したURLへ遷移します。
                </div>
                <div>
                  ・何もしない：
                  タップしても何もおきません。ロゴなどの配置に使えます。
                </div>
                <div className="uk-text-bold uk-margin-top uk-margin-small-bottom">
                  レイアウト例
                </div>
                <div className="uk-float-right uk-margin-large-bottom">
                  {RichMenuLayoutImages.map(image => (
                    <img
                      src={image.url}
                      key={image.id}
                      width="120"
                      height="120"
                      className="uk-margin-small-right uk-margin-small-bottom"
                    />
                  ))}
                </div>
              </div>

              <div>
                <h4>2.画像を作成する</h4>
                <div>
                  次に画像を作成してみましょう。 <br />
                  リッチメニューの画像は以下の要件を満たす必要があります。
                </div>
                <div className="uk-margin-small-top">
                  ・画像フォーマット：JPEGまたはPNG
                </div>
                <div>・画像の幅サイズ（ピクセル）：800以上、2500以下</div>
                <div>・画像の高さサイズ（ピクセル）：250以上</div>
                <div>・画像のアスペクト比（幅/高さ）：1.45以上</div>
                <div>・最大ファイルサイズ：1MB</div>
              </div>

              <div>
                <UploadRichmenuImages
                  richMenuData={richMenuData}
                  action={action}
                  setSendFile={setSendFile}
                />
              </div>
              <div>
                <TitleMenuBar
                  richMenuData={richMenuData}
                  setIsDisabled={setIsDisabled}
                  setValidateMessages={setValidateMessages}
                />
              </div>
              {validateMessages.length > 0 &&
                validateMessages.map(message => (
                  <div
                    className="uk-margin-small-bottom uk-text-danger"
                    key={message}
                  >
                    {message}
                  </div>
                ))}
              <div className="uk-section-xsmall uk-text-center">
                {deleteButton()}
                <input
                  type="button"
                  value="保存する"
                  className="uk-button uk-button uk-button-primary"
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    handleSubmit(e, 'save')
                  }
                  disabled={isDisabled}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
