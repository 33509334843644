import React, { useState, useEffect, ChangeEvent } from 'react'
import { RichMenuData } from '../../types'
import { runes } from 'runes2'
interface Props {
  richMenuData: RichMenuData | null
  setIsDisabled(bool: boolean): void
  setValidateMessages(messages: string[]): void
}

const TitleMenuBar = ({
  richMenuData,
  setIsDisabled,
  setValidateMessages,
}: Props) => {
  const [title, setTitle] = useState('')
  const [menuBarText, setMenuBarText] = useState('メニュー')
  const [menuBarSelected, setMenuBarSelected] = useState<boolean>(true)

  useEffect(() => {
    if (richMenuData) {
      if (Object.keys(richMenuData).length) {
        setTitle(richMenuData.name)
        setMenuBarSelected(richMenuData.selected)
        setMenuBarText(richMenuData.barText)
      }
    }
  }, [richMenuData])

  useEffect(() => {
    if (menuBarText.length === 0 || title.length === 0) {
      setIsDisabled(true)
    } else if (menuBarText.length > 14) {
      setIsDisabled(true)
      setValidateMessages([
        '「メニューバーに表示されるテキスト」の文字数が超過しています。',
      ])
    } else if (title.length > 30) {
      setIsDisabled(true)
      setValidateMessages([
        '「リッチメニューの名前」の文字数が超過しています。',
      ])
    } else {
      setIsDisabled(false)
      setValidateMessages([])
    }
  }, [menuBarText, title])

  const handleChangeMenuBarText = (e: ChangeEvent<HTMLInputElement>) => {
    setMenuBarText(e.target.value)
  }

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  return (
    <>
      <h4 className="uk-margin-large-top">5.メニューバーの設定</h4>
      <div className="uk-margin-bottom">
        <label>メニューバーに表示されるテキスト</label>
        <span
          uk-icon="question"
          className="hint--top hint--large hint--rounded"
          aria-label="チャットルームの下部にあるメニューバーに表示するテキストです。"
          style={{ width: 16, marginLeft: 5 }}
        />
        <span className="required-icon">必須</span>
        <br />
        <div className="uk-flex uk-flex-middle">
          <input
            type="text"
            name="rich_menu[bar_text]"
            className="uk-input uk-form-small uk-width-medium uk-margin-small-top"
            value={menuBarText}
            onChange={handleChangeMenuBarText}
          />
          <div
            className="uk-text-small uk-text-muted uk-flex-none uk-margin-small-left uk-margin-small-top"
            style={{ width: 76 }}
          >
            <span
              className={`uk-text-bold ${
                runes(menuBarText).length > 14 ? 'uk-text-danger' : ''
              }`}
            >
              {runes(menuBarText).length}
            </span>
            /14文字
          </div>
        </div>
      </div>
      <div className="uk-margin-top">
        <label>リッチメニューを最初から表示</label>
        <span
          uk-icon="question"
          className="hint--top hint--large hint--rounded"
          aria-label="チャットルームを開いたときに、リッチメニューを表示するかしないかを選択します。"
          style={{ width: 16, marginLeft: 5 }}
        />
        <span className="required-icon">必須</span>
        <br />
        <div className="uk-flex uk-flex-column">
          <label className="uk-flex-none uk-float-left uk-margin-small-top">
            <input
              type="radio"
              name="rich_menu[selected]"
              checked={menuBarSelected}
              value={'true'}
              className="uk-margin-small-left uk-radio uk-margin-small-right"
              onChange={() => setMenuBarSelected(true)}
            />
            <span>表示する</span>
          </label>
          <label className="uk-flex-none uk-float-left uk-margin-small-top">
            <input
              type="radio"
              name="rich_menu[selected]"
              checked={!menuBarSelected}
              value={'false'}
              className="uk-margin-small-left uk-radio uk-margin-small-right"
              onChange={() => setMenuBarSelected(false)}
            />
            <span>表示しない</span>
          </label>
        </div>
      </div>
      <h4 className="uk-margin-large-top">6.リッチメニューに名前をつける</h4>
      <div className="uk-margin-large-bottom">
        <label>リッチメニューの名前</label>
        <span className="required-icon">必須</span>
        <br />
        <div className="uk-text-xsmall uk-text-muted">
          ※
          この名前は管理用の名前であり、お客さまから見える場所には表示されません
        </div>
        <div className="uk-flex uk-flex-middle">
          <input
            type="text"
            name="rich_menu[name]"
            className="uk-input uk-form-small uk-width-medium uk-margin-small-top"
            value={title}
            onChange={handleChangeTitle}
          />
          <div
            className="uk-text-small uk-text-muted uk-flex-none uk-margin-small-left uk-margin-small-top"
            style={{ width: 76 }}
          >
            <span
              className={`uk-text-bold ${
                runes(title).length > 30 ? 'uk-text-danger' : ''
              }`}
            >
              {runes(title).length}
            </span>
            /30文字
          </div>
        </div>
      </div>
    </>
  )
}

export default TitleMenuBar
