import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch } from '.'

interface FlexMessageButtonTheme {
  colorCode: string
  style: string
}

interface FlexMessageButtonThemeState {
  list: FlexMessageButtonTheme[]
}

const initialState: FlexMessageButtonThemeState = {
  list: [],
}

const slice = createSlice({
  name: 'flexMessageButtonTheme',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
  },
})

export default slice.reducer

export const { setList } = slice.actions

export function fetchFlexMessageButtonThemes() {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get('/api/flex_message_button_themes')
      dispatch(setList(response.data))
    } catch (error) {
      console.log(error)
    }
  }
}
