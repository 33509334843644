import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useModal } from 'react-hooks-use-modal'
import { useQRCode } from 'next-qrcode'
import { saveAs } from 'file-saver'
import { Company } from 'components/Dashboard/types'

interface Props {
  rootId: string
  addLineFriendTrackingId: number
  liffUrl: string
  title: string
  parameter: string
  company: Company
  children: React.ReactNode
}

const AddLineFriendTrackingDetailModal = ({
  rootId,
  addLineFriendTrackingId,
  liffUrl,
  title,
  parameter,
  company,
  children,
}: Props) => {
  const [Modal, openModal] = useModal(rootId, {
    closeOnOverlayClick: true,
  })
  const { Canvas } = useQRCode()

  const url = parameter ? `${liffUrl}?ocsrc=${parameter}` : liffUrl

  const copy_to_clipboard = () => {
    navigator.clipboard.writeText(url)
  }

  const download_qr_code = async () => {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/add_line_friend_trackings/${addLineFriendTrackingId}/download_qr_code`,
        {
          responseType: 'blob',
        }
      )
      const name = `qr_${parameter}.png`
      const blob = new Blob([response.data], { type: 'image/png' })
      saveAs(blob, name)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <a
        className="uk-text-decoration-none"
        style={{ cursor: 'pointer' }}
        onClick={openModal}
      >
        {children}
      </a>
      <Modal>
        <ModalInner style={{ maxWidth: window.innerWidth - 30 }}>
          <div className="uk-h4">{title}</div>
          <div className="uk-section-xsmall">
            <div className="uk-h4 uk-margin-small-bottom">友だち追加URL</div>
            <div className="uk-flex uk-flex-middle uk-width-1-2@s">
              <pre className="uk-margin-remove">{url}</pre>
              <button
                className="uk-button uk-button-secondary uk-button-small uk-margin-small-left uk-flex-none"
                onClick={copy_to_clipboard}
              >
                コピー
              </button>
            </div>
          </div>
          <div className="uk-section-xsmall">
            <div className="uk-h4 uk-margin-small-bottom">
              友だち追加QRコード
            </div>
            <div className="uk-flex uk-flex-middle">
              <Canvas
                text={url}
                options={{
                  errorCorrectionLevel: 'M',
                  margin: 3,
                  scale: 4,
                  width: 200,
                }}
              />
              <a
                className="uk-margin-left uk-button uk-button-secondary uk-button-small"
                style={{ marginTop: '65px' }}
                onClick={download_qr_code}
              >
                ダウンロード
              </a>
            </div>
          </div>
        </ModalInner>
      </Modal>
    </>
  )
}

export default AddLineFriendTrackingDetailModal

const ModalInner = styled.div`
  background-color: #fff;
  padding: 30px 25px;
  width: 600px;
  box-sizing: border-box;
`
