import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../../hooks'
import { updateListTrigger } from '../../stores/pvRetargetingTrigger'
import { PvRetargetingTrigger, PushMessageCardObject } from '../../types'
import ColorSelector from './ColorSelector'
import { ResponsiveBreakpoint } from 'components/Dashboard/const'

interface Props {
  trigger: PvRetargetingTrigger
}

const LimitedStockForm = ({ trigger }: Props) => {
  const dispatch = useAppDispatch()
  const { pushMessageCardObject } = trigger

  const setCardObject = (newCardObject: PushMessageCardObject) => {
    dispatch(
      updateListTrigger({ ...trigger, pushMessageCardObject: newCardObject })
    )
  }

  const handleChangeMax = (e: ChangeEvent<HTMLInputElement>) => {
    const limitedStockMax =
      e.target.value === '' ? null : Number(e.target.value)
    setCardObject({ ...pushMessageCardObject, limitedStockMax })
  }

  const handleChangeLabel = (e: ChangeEvent<HTMLInputElement>) => {
    const limitedStockLabel = e.target.value
    setCardObject({ ...pushMessageCardObject, limitedStockLabel })
  }

  const toggleSettingCheckbox = () => {
    setCardObject({
      ...pushMessageCardObject,
      limitedStockSetting: !pushMessageCardObject.limitedStockSetting,
    })
  }

  return (
    <div>
      <CheckboxLabel>
        <input
          type="checkbox"
          checked={pushMessageCardObject.limitedStockSetting}
          onChange={toggleSettingCheckbox}
          className="uk-checkbox uk-margin-small-right"
        />
        在庫数でラベル切り替え
      </CheckboxLabel>
      {pushMessageCardObject.limitedStockSetting && (
        <FormContent>
          <div className="uk-margin-small-top uk-text-small">
            在庫数が残り
            <input
              type="number"
              min="1"
              value={
                pushMessageCardObject.limitedStockMax === null
                  ? ''
                  : String(pushMessageCardObject.limitedStockMax)
              }
              className="uk-input uk-margin-small-left uk-margin-small-right"
              onChange={handleChangeMax}
              style={{ width: 72 }}
            />
            個以下の場合
          </div>
          <div className="uk-flex uk-margin-small-top">
            <TextLabel>ラベル</TextLabel>
            <input
              type="text"
              value={pushMessageCardObject.limitedStockLabel || ''}
              onChange={handleChangeLabel}
              className="uk-input uk-flex-1"
              placeholder="在庫残りわずかです"
            />
          </div>
          <div className="uk-margin-small-top">
            <ColorSelector trigger={trigger} name="limitedStock" />
          </div>
        </FormContent>
      )}
    </div>
  )
}

export default LimitedStockForm

const CheckboxLabel = styled.label`
  line-height: 40px;
  cursor: pointer;
`
const FormContent = styled.div`
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    padding-left: 0;
  }
`
const TextLabel = styled.div`
  line-height: 40px;
  width: 94px;
  flex: none;
  font-size: 14px;
`
