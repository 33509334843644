import { CardTypeMessage } from 'components/Dashboard/types'
import React from 'react'
import styled from 'styled-components'
import {
  ItemCardContentPreview,
  LastCardPreview,
  ProductCardContentPreview,
} from './CardTypeMessageContentPreview'

interface Props {
  cardTypeMessage: CardTypeMessage
}

const CardTypeMessagePreview = ({ cardTypeMessage }: Props) => {
  if (!cardTypeMessage.contents) return <div />

  return (
    <MessageWrapper>
      <ContentList>
        {cardTypeMessage.contents.map(content => {
          const previewContentProps = {
            ...content,
            imageUrl: content.image?.preview.url || null,
          }
          return (
            <ContentWrapper key={content.id}>
              {cardTypeMessage.layout === 'product' && (
                <ProductCardContentPreview {...previewContentProps} />
              )}
              {cardTypeMessage.layout === 'image' && (
                <ItemCardContentPreview {...previewContentProps} />
              )}
            </ContentWrapper>
          )
        })}
        {cardTypeMessage.lastCardLayout !== 'none' && (
          <ContentWrapper>
            <LastCardPreview
              imageUrl={cardTypeMessage.lastCardImage?.preview.url || null}
              layout={cardTypeMessage.lastCardLayout}
              text={cardTypeMessage.lastCardText}
              url={cardTypeMessage.lastCardUrl}
            />
          </ContentWrapper>
        )}
      </ContentList>
    </MessageWrapper>
  )
}

const MessageWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: inline-block;
`

const ContentList = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &:-webkit-scrollbar {
    display: none;
  }
`

const ContentWrapper = styled.div`
  width: 280px;
  flex: 0 0 280px;
  margin-left: 8px;

  &:first-child {
    margin-left: 12px;
  }

  &:last-child {
    margin-right: 12px;
  }
`

export default CardTypeMessagePreview
