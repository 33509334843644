import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { ResponsiveBreakpoint } from '../../const'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { RootState } from '../../stores'
import { updateTrigger } from 'components/Dashboard/stores/restockTrigger'
import { RestockNotificationMessage } from 'components/Dashboard/types'
import ButtonColorSelector from '../shared/ButtonColorSelector'

interface Props {
  restockMessageType: 'notification' | 'subscribed'
}

const buttonTextOptions = ['商品を見る', '商品の詳細', '商品をチェック']

const MessageForm = ({ restockMessageType }: Props) => {
  const dispatch = useAppDispatch()
  const { trigger } = useAppSelector((state: RootState) => state.restockTrigger)
  if (!trigger) return <div />

  const { restockNotificationMessage } = trigger
  const [title, text, buttonText, buttonColorCode] = {
    notification: [
      restockNotificationMessage.notificationTitle,
      restockNotificationMessage.notificationText,
      restockNotificationMessage.notificationButtonText,
      restockNotificationMessage.notificationButtonColorCode,
    ],
    subscribed: [
      restockNotificationMessage.subscribedTitle,
      restockNotificationMessage.subscribedText,
      restockNotificationMessage.subscribedButtonText,
      restockNotificationMessage.subscribedButtonColorCode,
    ],
  }[restockMessageType]

  const setRestockNotificationMessage = (
    restockNotificationMessage: RestockNotificationMessage
  ) => {
    dispatch(updateTrigger({ ...trigger, restockNotificationMessage }))
  }

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value
    if (restockMessageType === 'notification') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        notificationTitle: title,
      })
    } else if (restockMessageType === 'subscribed') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        subscribedTitle: title,
      })
    }
  }

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value
    if (restockMessageType === 'notification') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        notificationText: text,
      })
    } else if (restockMessageType === 'subscribed') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        subscribedText: text,
      })
    }
  }

  const handleChangeButtonText = (e: ChangeEvent<HTMLSelectElement>) => {
    const text = e.target.value
    if (restockMessageType === 'notification') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        notificationButtonText: text,
      })
    } else if (restockMessageType === 'subscribed') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        subscribedButtonText: text,
      })
    }
  }

  const handleChangeButtonColorCode = (newColorCode: string) => {
    if (restockMessageType === 'notification') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        notificationButtonColorCode: newColorCode,
      })
    } else if (restockMessageType === 'subscribed') {
      setRestockNotificationMessage({
        ...trigger.restockNotificationMessage,
        subscribedButtonColorCode: newColorCode,
      })
    }
  }

  return (
    <div>
      <FormRow className="uk-flex uk-margin-bottom">
        <FormLabel className="uk-width-1-6">タイトル</FormLabel>
        <div className="uk-flex-1">
          <input
            type="text"
            value={title}
            onChange={handleChangeTitle}
            className="uk-input"
          />
        </div>
      </FormRow>
      <FormRow className="uk-flex uk-margin-bottom">
        <FormLabel className="uk-width-1-6">本文</FormLabel>
        <div className="uk-flex-1">
          <textarea
            value={text}
            onChange={handleChangeText}
            className="uk-textarea"
            rows={4}
          />
        </div>
      </FormRow>
      <FormRow className="uk-flex">
        <FormLabel className="uk-width-1-6">ボタン</FormLabel>
        <div>
          <select
            className="uk-select"
            value={buttonText || buttonTextOptions[0]}
            onChange={handleChangeButtonText}
          >
            {buttonTextOptions.map(text => (
              <option key={text} value={text}>
                {text}
              </option>
            ))}
          </select>
          {buttonColorCode && (
            <div className="uk-margin-small-top">
              <ButtonColorSelector
                colorCode={buttonColorCode}
                onChange={handleChangeButtonColorCode}
              />
            </div>
          )}
        </div>
      </FormRow>
    </div>
  )
}

export default MessageForm

const FormRow = styled.div`
  @media screen and (max-width: 1099px) {
    flex-direction: column;
  }
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    flex-direction: row;
  }
`
const FormLabel = styled.div`
  width: 90px;
  flex: none;
  line-height: 40px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  @media screen and (max-width: ${ResponsiveBreakpoint.small - 1}px) {
    width: 76px;
  }
`
