import { RichMenuData, RichmenuAction } from 'components/Dashboard/types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { RichMenuTemplates } from '../../const'
import ActionSelect from './ActionSelect'

interface Props {
  templateId: string | null
  imageUrl: string
  richMenuData: RichMenuData | null
  action: string
}

const RichmenuActionEdit = ({
  templateId,
  imageUrl,
  richMenuData,
  action,
}: Props) => {
  const arr: RichmenuAction[] = []
  const [actions, setActions] = useState(arr)

  useEffect(() => {
    if (!templateId) return
    if (templateId.length > 0) {
      createHiddenForm(templateId)
    }
  }, [templateId])

  useEffect(() => {
    if (action === 'edit' && richMenuData) {
      if (Object.keys(richMenuData).length > 0) {
        const actions = richMenuData.areaActions
        const hash: { [key: string]: string } = {}
        actions.map((h: RichmenuAction, i: number) => {
          if (Object.keys(h.action)[0] === 'uri') {
            hash[`action_${i}`] = 'uri'
          }
          if (Object.keys(h.action)[0] === 'data') {
            hash[`action_${i}`] = h.action['data']
          }
        })
      }
    }
  }, [richMenuData])

  const createHiddenForm = (templateId: string) => {
    const deleteTarget = document.getElementById('division_type')
    if (deleteTarget !== null) {
      deleteTarget.remove()
    }
    const hiddenEl = document.createElement('input')
    hiddenEl.id = 'division_type'
    hiddenEl.type = 'hidden'
    hiddenEl.name = 'rich_menu[template_type]'
    hiddenEl.value = templateId
    const form = document.getElementById('insert_division_type')
    if (!form) return
    form.after(hiddenEl)
  }

  const template = RichMenuTemplates.find(
    template => template.id === templateId
  )

  const createSelectActions = (length: number) => {
    if (action === 'edit' && richMenuData) {
      richMenuData.areaActions.map((v: RichmenuAction, n: number) => {
        if (!(actions.length === length)) {
          actions.push({ [`action_${n}`]: v['action'] })
        }
      })
    } else {
      ;[...Array(length)].map((_v, n: number) => {
        if (!(actions.length === length)) {
          actions.push({ [`action_${n}`]: { '': '' } })
        }
      })
    }
  }

  const ActionsHtml = () => {
    const items: JSX.Element[] = []
    if (!templateId) return
    if (templateId.length > 0) {
      if (!template) return
      createSelectActions(template.areas.length)
      template.areas.map((_area, i) => {
        items.push(
          <div
            className="uk-flex uk-flex-middle uk-margin-medium-bottom"
            key={`key_action_${i}`}
          >
            <ImageWrapper className="uk-width-1-3 uk-flex-none">
              <img src={imageUrl} className="uk-width-1-1" />
              <ImageShadowWrapper>
                {template.areas.map(({ x, y }, index) => (
                  <ImageShadow
                    key={index}
                    style={{
                      width: `${(100 * x.length) / template.columns}%`,
                      height: `${(100 * y.length) / template.rows}%`,
                      backgroundColor:
                        index === i ? 'none' : 'rgba(0, 0, 0, .7)',
                      border: index === i ? '2px solid #00B900' : 'none',
                    }}
                  />
                ))}
              </ImageShadowWrapper>
            </ImageWrapper>
            <div className="uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-left uk-margin-small-top uk-flex-1">
              <div className="uk-flex uk-flex-middle">
                <div className="uk-flex uk-flex-column">
                  <div className="uk-text-bold uk-margin-small-bottom">
                    アクション
                    <span className="required-icon uk-text-normal">必須</span>
                  </div>
                  <ActionSelect
                    actions={actions}
                    index={i}
                    setActions={setActions}
                    templateId={templateId}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
    return items
  }

  return (
    <>
      <h4 className="uk-margin-large-top">4.項目の編集</h4>
      {imageUrl ? (
        <div>{ActionsHtml()}</div>
      ) : (
        <div className="uk-text-danger uk-margin-large-top">
          リッチメニュー画像をアップロードしてください
        </div>
      )}
    </>
  )
}

export default RichmenuActionEdit

const ImageWrapper = styled.div`
  position: relative;
`
const ImageShadowWrapper = styled.div`
  position: absolute;
  inset: 0;
`
const ImageShadow = styled.div`
  box-sizing: border-box;
  float: left;
`
