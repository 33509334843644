import React, { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import SendIcon from '@mui/icons-material/Send'
import ImageIcon from '@mui/icons-material/Image'
import TextareaAutosize from 'react-textarea-autosize'
import { useHotkeys } from 'react-hotkeys-hook'

import { useAppSelector, useAppDispatch } from '../hooks'
import { RootState } from '../stores'
import EmojiPicker from './EmojiPicker'
import { fetchSelectedCustomer } from '../stores/customers'
import { sendChatMessage } from '../stores/chat'
import { EmojiData, BaseEmoji } from 'emoji-mart'
import { Company, Customer } from '../types'
import { useBeforeunload } from 'react-beforeunload'
import ImageModal from './ImageModal'

interface Props {
  company: Company
  onChangeText: () => void
  customer: Customer
}

const ChatForm = ({ onChangeText, customer, company }: Props) => {
  const dispatch = useAppDispatch()
  const { isAdmin, messageSendable } = useAppSelector(
    (state: RootState) => state.company
  )
  const [textareaValue, setTextareaValue] = useState('')
  const [sending, setSending] = useState(false)
  const [imageFile, setImageFile] = useState<null | File>(null)

  useEffect(() => {
    setTextareaValue('')
  }, [customer?.id])

  useLayoutEffect(() => {
    onChangeText()
  }, [textareaValue])

  const handleChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value)
  }

  useBeforeunload(event => {
    if (textareaValue) {
      event.preventDefault()
    }
  })

  const handleSelectEmoji = (emoji: EmojiData) => {
    // FIXME: EmojiData = BaseEmoji | CustomEmoji だが、CustomEmojiにはnativeが生えてないのでtypecheckでエラーになる
    if (Object.keys(emoji).includes('native')) {
      const { native } = emoji as BaseEmoji
      if (native) {
        setTextareaValue(textareaValue + native)
      }
    }
  }

  const sendMessage = (messageType: string, value: string | File) => {
    return dispatch(sendChatMessage(company, customer, messageType, value))
  }

  const handleChangeImageInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0])
    }
  }

  const containsOnlySpaces = /^[\s　]*$/.test(textareaValue)

  const isValid = !sending && textareaValue.length > 0 && !containsOnlySpaces

  const submitMessage = () => {
    if (!isValid) return
    setSending(true)

    sendMessage('text', textareaValue)
      .then(() => {
        setSending(false)
        setTextareaValue('')
        dispatch(fetchSelectedCustomer(company, customer))
      })
      .catch(error => {
        window.alert(
          error.response?.data?.message ||
            'メッセージの送信ができませんでした。ページを再読み込みしていただくか、時間をあけてから再度お試しください。'
        )
        setSending(false)
      })
  }

  useHotkeys('command+enter,ctrl+enter', submitMessage, {
    enableOnTags: ['TEXTAREA', 'INPUT'],
  })

  return (
    <Container>
      <ImageButton>
        <FileInput
          type="file"
          accept="image/jpeg, image/png"
          onChange={handleChangeImageInput}
        />
        <ImageIcon style={styles.imageButton} />
      </ImageButton>
      <ImageModal
        rootId="talkroom-root"
        imageFile={imageFile}
        company={company}
        customer={customer}
        setImageFile={setImageFile}
      />
      <EmojiButton>
        <EmojiPicker
          onSelect={handleSelectEmoji}
          iconStyle={styles.emojiPickerIcon}
          modalStyle={styles.emojiPickerModal}
        />
      </EmojiButton>
      <TextareaAutosize
        maxRows={4}
        minRows={1}
        // MEMO: resize propertyが必要なため
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={{ ...styles.textarea, color: sending ? '#aaa' : '#333' }}
        value={textareaValue}
        onChange={handleChangeTextarea}
        disabled={sending}
      />
      <SendButton>
        <SendIcon
          onClick={submitMessage}
          style={{
            ...styles.sendButton,
            color: isValid ? '#1e87f0' : '#f2f3f5',
            cursor: isValid ? 'pointer' : 'default',
          }}
        />
      </SendButton>
      {(!messageSendable || !customer.customerLineAccount) && (
        <BlackCover>
          {!isAdmin && customer.customerLineAccount && (
            <div>
              今月の送信可能数の上限に達しているため、メッセージを送れません。
              <br />
              LINE公式アカウントマネージャーにて送信可能数の設定をご確認ください。
            </div>
          )}
        </BlackCover>
      )}
    </Container>
  )
}

export default ChatForm

const styles = {
  textarea: {
    width: '100%',
    padding: '12px',
    fontSize: 16,
    outline: 'none',
    border: 'none',
    backgroundColor: '#f2f3f5',
    borderRadius: 5,
    resize: 'none',
  },
  emojiPickerIcon: {
    fontSize: 32,
  },
  emojiPickerModal: {
    bottom: 44,
    left: 0,
  },
  sendButton: {
    fontSize: '32px',
  },
  imageButton: {
    color: '#999',
    cursor: 'pointer',
    fontSize: '34px',
  },
}

const Container = styled.div`
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  align-items: flex-end;
`
const EmojiButton = styled.div`
  padding: 6px 12px 6px 0;
`
const SendButton = styled.div`
  padding: 6px 0 6px 12px;
`
const ImageButton = styled.label`
  padding: 6px 6px 6px 0;
`
const FileInput = styled.input`
  display: none;
`
const BlackCover = styled.div`
  position: absolute;
  top: -1px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  a {
    text-decoration: underline !important;
  }
`
