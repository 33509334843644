import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch } from '.'
import { Company, Customer } from '../types'

interface AddedLineRouteState {
  title: string | null
}

const initialState: AddedLineRouteState = {
  title: null,
}

const slice = createSlice({
  name: 'addedLineRoute',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload
    },
  },
})

export default slice.reducer

export const { setTitle } = slice.actions

export function fetchCustomerAddedLineRoute(
  company: Company,
  customer: Customer
) {
  return async function (dispatch: AppDispatch) {
    try {
      const response = await axios.get(
        `/api/companies/${company.slug}/talk_room/customers/${customer.id}/added_line_friend_route`
      )
      const { title } = response.data
      dispatch(setTitle(title))
    } catch (error) {
      console.log(error)
    }
  }
}
