$(function () {
  $('#notification-button.unread').on('click', function () {
    const companySlug = $(this).data('company-slug')
    $.ajax({
      type: 'PUT',
      url: `/api/companies/${companySlug}/user_company_notifications/read_all`,
    }).done(_res => {
      $(this).removeClass('unread')
    })
  })
})
