import { Pagination } from '@mui/material'
import { ShopItemDefaultImage } from 'components/Dashboard/const'
import { CardTypeMessage } from 'components/Dashboard/types'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Company } from 'types.ts/Company'
import { PushMessageFormCardTypeObject } from 'types.ts/PushMessage'

interface Props {
  company: Company
  cardTypeMessages: CardTypeMessage[]
  messageObject: PushMessageFormCardTypeObject
  onChange: (
    cardTypeMessageId: PushMessageFormCardTypeObject['cardTypeMessageId']
  ) => void
}

const paginationCountPerPage = 10

const CardTypeMessageSelect = ({
  company,
  cardTypeMessages,
  messageObject,
  onChange,
}: Props) => {
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1)
  const selectedCardTypeMessage = cardTypeMessages.find(
    cardTypeMessage => cardTypeMessage.id === messageObject.cardTypeMessageId
  )
  const selectableCardTypeMessages = cardTypeMessages.filter(
    ({ contents }) => contents && contents?.length > 0
  )
  const totalSelectableCardTypeMessageCount = selectableCardTypeMessages.length
  const displayPagination =
    totalSelectableCardTypeMessageCount > paginationCountPerPage
  const currentPageStartIndex =
    (paginationCurrentPage - 1) * paginationCountPerPage
  const currentPageEndIndex = currentPageStartIndex + paginationCountPerPage
  const currentPageCardTypeMessages = selectableCardTypeMessages.slice(
    currentPageStartIndex,
    currentPageEndIndex
  )

  return (
    <div className="uk-padding-small uk-padding-remove-bottom">
      {selectedCardTypeMessage ? (
        <div>
          <label className="uk-form-label">
            カードタイプメッセージを選択
            <span className="required-icon">必須</span>
          </label>
          <div className="uk-flex uk-flex-middle uk-margin">
            <img
              src={
                selectedCardTypeMessage.contents?.[0]?.image?.url ||
                ShopItemDefaultImage
              }
              width={50}
              className="uk-margin-small-right"
            />
            <div className="uk-flex-1 uk-text-secondary uk-text-small">
              {selectedCardTypeMessage.title}
            </div>
            <button
              className="uk-button uk-button-muted uk-button-small"
              onClick={() => onChange(null)}
            >
              変更する
            </button>
          </div>
        </div>
      ) : (
        <div>
          <label className="uk-form-label">
            カードタイプメッセージを選択
            <span className="required-icon">必須</span>
          </label>
          {selectableCardTypeMessages.length === 0 ? (
            <div className="uk-margin uk-text-small">
              利用できるカードタイプメッセージがありません。
              <br />
              <a href={`/companies/${company.slug}/card_type_messages/new`}>
                カードタイプメッセージの新規登録
              </a>
              より作成することでご利用いただけます。
            </div>
          ) : (
            <div className="uk-margin-top">
              <ul className="uk-list uk-list-divider">
                {currentPageCardTypeMessages.map(cardTypeMessage => (
                  <li
                    key={cardTypeMessage.id}
                    className="uk-flex uk-flex-between uk-flex-middle"
                  >
                    <div className="uk-flex uk-flex-middle uk-flex-1">
                      <img
                        src={
                          cardTypeMessage.contents?.[0]?.image?.url ||
                          ShopItemDefaultImage
                        }
                        width={50}
                        className="uk-margin-small-right"
                      />
                      <div className="uk-flex-1">
                        <div className="uk-text-small uk-text-secondary">
                          {cardTypeMessage.title}
                        </div>
                        <div className="uk-text-xsmall uk-text-muted">
                          <span>
                            {
                              { image: '画像のみ', product: '画像+商品情報' }[
                                cardTypeMessage.layout
                              ]
                            }
                          </span>
                          <span className="uk-margin-xsmall-left uk-margin-xsmall-right">
                            /
                          </span>
                          <span>
                            {(cardTypeMessage.contents?.length || 0) +
                              (cardTypeMessage.lastCardLayout === 'none'
                                ? 0
                                : 1)}
                            枚
                          </span>
                          <span className="uk-margin-xsmall-left uk-margin-xsmall-right">
                            /
                          </span>
                          <span>
                            {dayjs(cardTypeMessage.updatedAt).format(
                              'YYYY/MM/DD HH:mm'
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      className="uk-button uk-button-primary uk-button-small"
                      onClick={() => onChange(cardTypeMessage.id)}
                    >
                      選択
                    </button>
                  </li>
                ))}
              </ul>
              {displayPagination && (
                <Pagination
                  className="uk-flex uk-flex-center"
                  count={Math.ceil(
                    totalSelectableCardTypeMessageCount / paginationCountPerPage
                  )}
                  page={paginationCurrentPage}
                  onChange={(_, value) => {
                    setPaginationCurrentPage(value)
                  }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CardTypeMessageSelect
