import React, { ChangeEvent } from 'react'
import { PvRetargetingTrigger } from '../../types'
import { useAppDispatch, useResponsiveWindowSize } from '../../hooks'
import { updateListTrigger } from '../../stores/pvRetargetingTrigger'

interface Props {
  trigger: PvRetargetingTrigger
  disableDays: number[]
}

const TriggerSettingForm = ({ trigger, disableDays }: Props) => {
  const dispatch = useAppDispatch()
  const responsiveWindowSize = useResponsiveWindowSize()

  const handleChangeWaitMinutes = (e: ChangeEvent<HTMLSelectElement>) => {
    const waitMinutes = Number(e.target.value) * 24 * 60
    dispatch(updateListTrigger({ ...trigger, waitMinutes }))
  }

  const handleChangeStatus = () => {
    const status = trigger.status === 'active' ? 'inactive' : 'active'
    dispatch(updateListTrigger({ ...trigger, status }))
  }

  const isActive = trigger.status === 'active'

  return (
    <label
      className="uk-flex uk-flex-middle uk-margin-small-bottom"
      style={{ width: 'fit-content', color: isActive ? '#333' : '#aaa' }}
    >
      <input
        type="checkbox"
        checked={isActive}
        onChange={handleChangeStatus}
        className="uk-checkbox"
        style={{ margin: '0 10px 0 0' }}
      />
      <div className="uk-text-small">商品閲覧から</div>
      <label className="uk-margin-small-left uk-margin-small-right">
        <select
          value={String(trigger.waitMinutes / (24 * 60))}
          onChange={handleChangeWaitMinutes}
          className="uk-select uk-text-small"
          style={{ width: 60 }}
        >
          {[...Array(30)].map((_v, n) => {
            n++
            return (
              <option key={n} value={n} disabled={disableDays.includes(n)}>
                {n}
              </option>
            )
          })}
        </select>
      </label>
      <div className="uk-text-small">
        {responsiveWindowSize === 'small'
          ? '日後にメッセージ配信'
          : '日後にメッセージを配信する'}
      </div>
    </label>
  )
}

export default TriggerSettingForm
